import { useIntl } from 'react-intl';
import ValuePercent from 'src/components/basic/ValuePercent';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';
import messages from './messages';
import staticStyles from './style';
interface BorrowAPYCardProps {
  value?: number | string;
  isLoading: boolean;
}

export default function BorrowAPYCard({ value, isLoading }: BorrowAPYCardProps) {
  const intl = useIntl();

  return (
    <>
      <div className="DashboardCard__title">
        <TextWithInfoWrapper text={intl.formatMessage(messages.title)} tooltipName="borrowAPY" />
      </div>
      <div className="DashboardCard__content">
        <div className="BorrowAPYCard__value">
          {isLoading ? (
            <SkeletonWrapper width={100} height={30} />
          ) : (
            <ValuePercent value={value || '0'} />
          )}
        </div>
      </div>
      <style jsx={true}>{staticStyles}</style>
    </>
  );
}
