import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .BorrowMain {
    & > * {
      margin: 24px 0;
    }
  }
`;

export default staticStyles;
