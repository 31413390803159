import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { isAssetStable, TokenIcon } from '@sturdyfi/sturdy-ui-kit';

import DefaultButton from 'src/components/basic/DefaultButton';
import ValuePercent from 'src/components/basic/ValuePercent';
import Value from 'src/components/basic/Value';

import TableColumnWrapper from 'src/components/Wrappers/Table/TableColumnWrapper';
import TableItemWrapper from 'src/components/Wrappers/Table/TableItemWrapper';

import { LendCurrencyProps } from './types';
import messages from './messages';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';

export default function LendCurrencyItem({
  symbol,
  underlyingAsset,
  underlyingBalance,
  depositAPY,
  avgAPY,
  isLoading,
}: LendCurrencyProps) {
  const intl = useIntl();
  return (
    <TableItemWrapper>
      <TableColumnWrapper minWidth={300}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={280} />
        ) : (
          <TokenIcon
            tokenSymbol={symbol}
            height={35}
            width={35}
            tokenFullName={symbol.toUpperCase()}
            className=""
          />
        )}
      </TableColumnWrapper>

      <TableColumnWrapper>
        {isLoading ? (
          <SkeletonWrapper height={50} width={130} />
        ) : (
          <Value
            value={Number(underlyingBalance)}
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
          />
        )}
      </TableColumnWrapper>

      <TableColumnWrapper>
        {isLoading ? (
          <SkeletonWrapper height={50} width={130} />
        ) : (
          <ValuePercent value={depositAPY} />
        )}
      </TableColumnWrapper>

      <TableColumnWrapper>
        {isLoading ? <SkeletonWrapper height={50} width={130} /> : <ValuePercent value={avgAPY} />}
      </TableColumnWrapper>

      <TableColumnWrapper className={'ButtonColumn'} minWidth={400}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={380} />
        ) : (
          <>
            <Link to={`/lend/deposit/${underlyingAsset}`}>
              <DefaultButton
                title={intl.formatMessage(messages.lend)}
                type="secondary"
                color="blue"
              />
            </Link>
            <Link to={`/lend/withdraw/${underlyingAsset}`}>
              <DefaultButton
                title={intl.formatMessage(messages.withdraw)}
                type="secondary"
                color="gray"
              />
            </Link>
          </>
        )}
      </TableColumnWrapper>
    </TableItemWrapper>
  );
}
