import classNames from 'classnames';
import React, { ReactNode } from 'react';

import staticStyles from './style';

interface TableColumnWrapperProps {
  children: ReactNode;
  className?: string;
  maxWidth?: number;
  minWidth?: number;
}

export default function TableColumnWrapper({
  children,
  className,
  maxWidth,
  minWidth,
}: TableColumnWrapperProps) {
  return (
    <div
      className={classNames('TableColumnWrapper', className)}
      style={{ maxWidth, minWidth: minWidth || 150 }}
    >
      {children}

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
