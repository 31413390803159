import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Rewards__section {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .Rewards__value {
    @include body_24_sb;
  }
  .Rewards__button {
    cursor: pointer;
    width: 81px;
    background: #f4f7ff;
    border-radius: 23px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 4px;

    span {
      @include body_14_sb;
      color: #3b62ed;
    }
  }
`;

export default staticStyles;
