import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TextWithInfoWrapper {
    // color: var(--text2-color);
    background-color: transparent;
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 0;
  }
`;

export default staticStyles;
