import { defineMessages } from 'react-intl';

// <> : separator
//  \\B : bold
//  \\G` : green
//  \\Y : yellow

export default defineMessages({
  borrowing: 'Borrowing',
  borrowingCaption: 'How does borrowing work?',
  borrowingDesc:
    'After providing collateral, borrowers are able to take out loans at 0% APY, if the utilization rate of the asset they are borrowing is below 80%',
  depositing: 'Depositing',
  depositingCaption: 'How depositing works?',
  depositingDesc1:
    'Sturdy takes the collateral provided by borrowers and stakes it into DeFi protocols like Yearn, Convex, and Lido.',
  depositingDesc2:
    'Borrowers earn a APY from their deposits, but they are also able to take out loans at 0% APY if the utilization rate of the asset they are borrowing is below 80%',
  lending: 'Lending',
  lendingCaption: 'Where is the yield coming from?',
  lendingDesc1:
    'Sturdy takes the amount that’s being lent and provides it as liquidity to borrowers.',
  lendingDesc2:
    'The staking yields from the borrowers deposits are used to pay interest to lenders.',
});
