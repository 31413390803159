import NoData from '../../basic/NoData';
import SpinLoader from '../../basic/SpinLoader';
import Value from '../../basic/Value';
import InfoPanelWrapper from '../../Wrappers/InfoPanelWrapper';
import TextWithInfoWrapper from '../../Wrappers/TextWithInfoWrapper';

import { OverviewDataType } from '../types';
import staticStyles from './style';
import classNames from 'classnames';

interface RepayOverviewPanelProps
  extends Pick<
    OverviewDataType,
    'assetAmount' | 'assetSymbol' | 'healthFactor' | 'gasEstimation' | 'loadingGasEstimation'
  > {}

export default function RepayOverviewPanel({
  assetAmount,
  assetSymbol,
  healthFactor,
  gasEstimation,
  loadingGasEstimation,
}: RepayOverviewPanelProps) {
  return (
    <InfoPanelWrapper title={<span>Transaction overview</span>} className={'RepayOverviewPanel'}>
      <div className={'RepayOverviewPanel__item'}>
        <div className={'RepayOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Remaining Debt'} tooltipName="remainingBalance" />
          <div className={'RepayOverviewPanel__item__content__value'}>
            {assetAmount ? <Value value={assetAmount} maximumValueDecimals={2} /> : <NoData />}
            <span>&nbsp;{assetSymbol.toUpperCase()}</span>
          </div>
        </div>
      </div>
      <div className={'RepayOverviewPanel__item'}>
        <div className={'RepayOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Health Factor'} tooltipName="healthFactor" />
          <div
            className={classNames(
              {
                DeleverageOverviewPanel__item__content__value__healthFactor:
                  Number(healthFactor) < 2,
              },
              {
                DeleverageOverviewPanel__item__content__value__healthFactorSafe:
                  Number(healthFactor) >= 2,
              }
            )}
          >
            {healthFactor ? <Value value={healthFactor} maximumValueDecimals={2} /> : <NoData />}
          </div>
        </div>
        <div className={'RepayOverviewPanel__item__sub'}>{'Liquidation at < 1.0'}</div>
      </div>

      <div className={'RepayOverviewPanel__item'}>
        <div className={'RepayOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Gas Fee'} tooltipName="gasFee" />
          <div className={'RepayOverviewPanel__item__content__value'}>
            {loadingGasEstimation ? (
              <SpinLoader color="black" />
            ) : gasEstimation ? (
              <Value
                value={gasEstimation}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <style jsx={true}>{staticStyles}</style>
    </InfoPanelWrapper>
  );
}
