import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Provide {
    display: flex;
    flex-direction: row !important;
    justify-content: center;

    @include respond-to(sm) {
      margin-top: 0 !important;
    }

    & > * {
      flex: 1;
      max-width: 488px;
      min-height: 500px;
      margin: 12px;
    }

    @include respond-to(sm) {
      flex-direction: column !important;

      & > * {
        flex: 1;
        width: 100%;
        margin: 12px 0;
        min-height: initial;
      }
    }
  }
`;

export default staticStyles;
