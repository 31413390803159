import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TxConfirmationView {
    display: flex;
    align-items: center;
    // justify-content: center;
    flex-direction: column;
    flex: 1;
    gap: 16px;

    &__actions-inner {
      width: 100%;
    }

    &__actionsInner_hidden {
      visibility: hidden;
    }

    &__error {
      width: 100%;
      max-height: 250px;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;

export default staticStyles;
