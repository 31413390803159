import ScreenWrapper from '../../../components/Wrappers/ScreenWrapper';
import RewardCurrencyWrapper from '../components/RewardCurrencyWrapper';
import staticStyles from './style';

export default function Reward() {
  return (
    <ScreenWrapper>
      <div className="Reward">
        <RewardCurrencyWrapper />
        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </div>
    </ScreenWrapper>
  );
}
