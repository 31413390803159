import { Link } from 'react-router-dom';
import { isAssetStable, TokenIcon, useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import DefaultButton from 'src/components/basic/DefaultButton';
import Value from 'src/components/basic/Value';
import ValuePercent from 'src/components/basic/ValuePercent';

import TableColumnWrapper from 'src/components/Wrappers/Table/TableColumnWrapper';
import TableItemWrapper from 'src/components/Wrappers/Table/TableItemWrapper';

import { BorrowCurrencyProps } from './types';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';
import { useIntl } from 'react-intl';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';
import messages from './messages';

export default function BorrowCurrencyItem({
  symbol,
  borrowAPY,
  debt,
  underlyingAsset,
  isLoading,
}: BorrowCurrencyProps) {
  const intl = useIntl();
  const { sm } = useThemeContext();

  return (
    <TableItemWrapper>
      <TableColumnWrapper minWidth={300}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={280} />
        ) : (
          <TokenIcon
            tokenSymbol={symbol}
            height={35}
            width={35}
            tokenFullName={symbol.toUpperCase()}
            className=""
          />
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.debt)}
          />
        </TableColumnWrapper>
      )}
      <TableColumnWrapper minWidth={300}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={280} />
        ) : (
          <Value
            value={Number(debt)}
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
          />
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.borrowAPY)}
            tooltipName="borrowAPYForAsset"
          />
        </TableColumnWrapper>
      )}
      <TableColumnWrapper>
        {isLoading ? (
          <SkeletonWrapper height={50} width={130} />
        ) : (
          <ValuePercent value={borrowAPY} />
        )}
      </TableColumnWrapper>

      <TableColumnWrapper className={'ButtonColumn'} minWidth={400}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={380} />
        ) : (
          <>
            <Link to={`/borrow/borrow/${underlyingAsset}`}>
              <DefaultButton title={'Borrow'} type="secondary" color="blue" />
            </Link>
            <Link to={`/borrow/repay/${underlyingAsset}`}>
              <DefaultButton title={'Repay'} type="secondary" color="gray" />
            </Link>
          </>
        )}
      </TableColumnWrapper>
    </TableItemWrapper>
  );
}
