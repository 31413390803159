import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import messages from '../messages';
import MoreInformationButton from 'src/components/basic/MoreInformationButton';
import BorrowHelpModal from 'src/components/HelpModal/BorrowHelpModal';
import LendingHelpModal from 'src/components/HelpModal/LendingHelpModal';
import classNames from 'classnames';

export default function LendAndBorrowing() {
  const intl = useIntl();
  const { isCurrentThemeDark } = useThemeContext();
  const [active, setActive] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  const clsName = isCurrentThemeDark ? 'dark' : 'light';

  const TabTitles = [intl.formatMessage(messages.lending), intl.formatMessage(messages.borrowing)];

  const getClsName = (str: string) => {
    if (str === '\\B') {
      return 'Tooltip__Bold';
    }
    if (str === '\\Y') {
      return 'Tooltip__Yellow';
    }
    if (str === '\\G') {
      return 'Tooltip__Green';
    }
    return '';
  };

  const splitStr = (sentence: string) => {
    const words = sentence.split(/<>/);

    return (
      <>
        {words.map((word, id) => {
          const chars = word.slice(0, 2);
          const clsName = getClsName(chars);
          return (
            <span className={clsName} key={id}>
              {clsName ? word.slice(2) : word}
            </span>
          );
        })}
      </>
    );
  };

  const renderTabs = () => {
    return (
      <ul className="Tooltip__tabs">
        {TabTitles.map((tabTitle, index) => {
          return (
            <li onClick={() => setActive(index)} key={index}>
              <div
                className={classNames('Tooltip__tab', {
                  Tooltip__tabActive: index === active,
                })}
              >
                <p>{tabTitle}</p>
                <i />
              </div>
            </li>
          );
        })}
      </ul>
    );
  };

  const renderLendingTab = () => {
    const description = intl.formatMessage(messages.lendingDesc);
    const smallParts = description.split('\n');

    return (
      <>
        {smallParts.map((str, id) => (
          <p key={id}>{str}</p>
        ))}
        <LendingHelpModal isVisible={modalVisible} onBackdropPress={() => setModalVisible(false)} />
        <MoreInformationButton title="Learn more" onClick={() => setModalVisible(true)} />
      </>
    );
  };

  const renderBorrowingTab = () => {
    const description = intl.formatMessage(messages.borrowingDesc);
    const smallParts = description.split('\n');

    return (
      <>
        {smallParts.map((str, id) => (
          <p key={id}>{splitStr(str)}</p>
        ))}
        <div className="Tooltip__additional-description">
          <span className={clsName}>&#9432;</span>
          <span className={clsName}>{intl.formatMessage(messages.borrowingDesc2)}</span>
        </div>
        <BorrowHelpModal isVisible={modalVisible} onBackdropPress={() => setModalVisible(false)} />
        <MoreInformationButton title="Learn more" onClick={() => setModalVisible(true)} />
      </>
    );
  };

  return (
    <>
      {renderTabs()}
      <div className="Tooltip__content Tooltip__contentWithTabs">
        {active === 0 ? renderLendingTab() : renderBorrowingTab()}
      </div>
    </>
  );
}
