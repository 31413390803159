import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { BigNumberValue } from '@sturdyfi/sturdy-js';

import staticStyles from './style';

interface ValuePercentProps {
  value: BigNumberValue;
  percentSymbol?: boolean;
  maximumDecimals?: number;
  minimumDecimals?: number;
  className?: string;
}

export default function ValuePercent({
  value,
  percentSymbol = true,
  maximumDecimals,
  minimumDecimals,
  className,
}: ValuePercentProps) {
  const intl = useIntl();

  return (
    <div className={classNames('ValuePercent', className)}>
      <p className="ValuePercent__value">
        {value === '-'
          ? '-'
          : (Number(value) || 0) === 0
          ? 0
          : intl.formatNumber(percentSymbol ? Number(value) * 100 : Number(value), {
              maximumFractionDigits: maximumDecimals || 2,
              minimumFractionDigits: minimumDecimals ? minimumDecimals : undefined,
            })}

        {percentSymbol && <span>%</span>}
      </p>

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
