import React from 'react';
import BorrowableStatsPanel from './BorrowableStatsPanel';
import CollateralStatsPanel from './CollateralStatsPanel';

import { StatsDataType } from './types';

interface StatsPanelProps {
  statsData: StatsDataType;
}

export default function StatsPanel({ statsData }: StatsPanelProps) {
  const { assetType } = statsData;

  if (assetType === 'collateral') return <CollateralStatsPanel {...statsData} />;

  if (assetType === 'borrowable') return <BorrowableStatsPanel {...statsData} />;

  return <></>;
}
