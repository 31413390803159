import React from 'react';

import staticStyles from './style';
import auditIcon from 'src/images/audit.svg';
import infoIcon from 'src/images/info.svg';
import Tooltip from '../Tooltip';

export default function AuditButton() {
  return (
    <Tooltip
      link={
        <div className={'AuditButton'}>
          <img src={auditIcon} width={32} height={32} alt="" />
          <div className={'AuditButton__content'}>
            <span className={'AuditButton__content__text'}>Audited by:</span>
            <span className={'AuditButton__content__text-sub'}>3 audits</span>
          </div>
          <img src={infoIcon} width={16} height={16} alt="" />
          <style jsx={true}>{staticStyles}</style>
        </div>
      }
      tooltipName={'audit'}
      verticalPosition="top"
      hoverable={false}
    />
  );
}
