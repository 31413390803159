import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Toastify__toast {
    background: var(--toast-background-color);
    border: 1px solid #edeff3;
    /* it's new */

    box-shadow: 0px 0px 22px rgba(203, 207, 211, 0.1);
    backdrop-filter: blur(4px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 16px;
  }

  .TxToastView {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    // padding: 5px 15px;
    position: relative;
    width: 100%;
    min-height: 28px;
    gap: 18px;

    &__icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &Spinner {
        color: $text_color;
      }
    }

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;
      @include body_16;
    }

    &__title {
      color: var(--selected-color);
    }

    &__detail {
      color: var(--text-color);
    }
  }
`;

export default staticStyles;
