import { Network } from '@sturdyfi/sturdy-js';

export type ExplorerLinkBuilderProps = {
  tx?: string;
  address?: string;
};

export type ExplorerLinkBuilderConfig = {
  baseUrl: string;
  addressPrefix?: string;
  txPrefix?: string;
};

export enum REWARD_TYPE {
  STABLE = 'STABLE',
  VARIABLE = 'VARIABLE',
}

export type RewardInfo = {
  token: string;
  symbol: string;
  type: REWARD_TYPE;
};

export type CurvePoolInfo = {
  provider: string;
  pools: ReadonlyArray<{
    symbol: string;
    address: string;
    tokens: ReadonlyArray<string>;
  }>;
};

export type NetworkConfig = {
  privateJsonRPCUrl?: string; // private rpc will be used for rpc queries inside the client. normally has private api key and better rate
  privateJsonRPCWSUrl?: string;
  publicJsonRPCUrl: string; // public rpc used if not private found, and used to add specific network to wallets if user don't have them. Normally with slow rates
  publicJsonRPCWSUrl?: string;
  addresses: {
    walletBalanceProvider: string;
    /**
     * UiPoolDataProvider currently requires a non-master version
     * https://github.com/aave/protocol-v2/blob/feat/split-ui-dataprovider-logic/contracts/misc/UiPoolDataProvider.sol
     * If you deploy a market with the non default oracle or incentive controller you have to redeploy the UiPoolDataProvider as well as currently the addresses are static.
     * In the upcoming version this will no longer be needed.
     */
    uiPoolDataProvider: string;
    sturdyOracle: string;
    extraAssetsRequiredPrice: ReadonlyArray<string>;
    uiIncentiveDataProvider: string;
    chainlinkFeedRegistry?: string;
    curvePoolInfo?: CurvePoolInfo;
  };
  protocolDataUrl: string;
  cachingServerUrl?: string;
  cachingWSServerUrl?: string;
  baseUniswapAdapter?: string;
  baseAsset: string;
  baseAssetWrappedAddress?: string;
  collateralAssets?: { [key: string]: ReadonlyArray<string> };
  collateralAddresses?: { [key: string]: string };
  collateralAssetFromSymbol?: { [key: string]: string };
  collateralRewardAddresses?: ReadonlyArray<RewardInfo>;
  rewardTokenSymbol: string;
  rewardTokenAddress: string;
  rewardTokenDecimals: number;
  incentivePrecision: number;
  usdMarket?: boolean;
  // function returning a link to etherscan et al
  explorerLink: string;
  explorerLinkBuilder: (props: ExplorerLinkBuilderProps) => string;
  rpcOnly: boolean;
  // set this to show faucets and similar
  isTestnet?: boolean;
  bridge?: {
    brandColor: string;
    name: string;
    url: string;
    logo: string;
  };
  chainName?: string;
  currency?: {
    name: string;
    symbol: string;
    decimals: number;
  };
  baseAssetGas?: string;
};

export type MarketDataType = {
  // the network the market operates on
  network: Network;
  // market logo in the topbar
  logo: string;
  // logo for the active market in dropdown
  activeLogo?: string;
  // additional logo on the right hand side
  subLogo?: string;
  // aToken prefix string, which will be cut of in the ui
  aTokenPrefix: string;
  enabledFeatures?: {
    liquiditySwap?: boolean;
    staking?: boolean;
    governance?: boolean;
    faucet?: boolean;
    collateralRepay?: boolean;
    incentives?: boolean;
    permissions?: boolean;
  };
  flashloanFee?: number;
  flashloanSlippage?: number;
  addresses: {
    LENDING_POOL_ADDRESS_PROVIDER: string;
    LENDING_POOL: string;

    // etherem
    LIDO_VAULT?: string;
    CONVEX_FRAX_3CRV_VAULT?: string;
    CONVEX_DAI_USDC_USDT_SUSD_VAULT?: string;
    CONVEX_IRON_BANK_VAULT?: string;
    CONVEX_FRAX_USDC_VAULT?: string;
    CONVEX_MIM_3CRV_VAULT?: string;
    CONVEX_TUSD_FRAXBP_VAULT?: string;
    CONVEX_FRAX_3CRV_LEVSWAPPER?: string;
    CONVEX_DAI_USDC_USDT_SUSD_LEVSWAPPER?: string;
    CONVEX_FRAX_USDC_LEVSWAPPER?: string;
    CONVEX_IRON_BANK_LEVSWAPPER?: string;
    CONVEX_MIM_3CRV_LEVSWAPPER?: string;
    CONVEX_TUSD_FRAXBP_LEVSWAPPER?: string;

    // eth
    WETH_GATEWAY?: string;
    CONVEX_ETH_STETH_VAULT?: string;
    AURA_WSTETH_WETH_VAULT?: string;
    CONVEX_ETH_STETH_LEVSWAPPER?: string;
    AURA_WSTETH_WETH_LEVSWAPPER?: string;

    // fantom
    YEARN_VAULT?: string;
    YEARN_WETH_VAULT?: string;
    YEARN_WBTC_VAULT?: string;
    YEARN_BOO_VAULT?: string;
    YEARN_FBEETS_VAULT?: string;
    YEARN_LINK_VAULT?: string;
    YEARN_SPELL_VAULT?: string;
    YEARN_CRV_VAULT?: string;
    TOMB_FTM_BEEFY_VAULT?: string;
    TOMB_MIMATIC_BEEFY_VAULT?: string;
    BASED_MIMATIC_BEEFY_VAULT?: string;
    // faucet
    FAUCET?: string;
  };
};

export type BaseNetworkConfig = Omit<NetworkConfig, 'explorerLinkBuilder'>;
