import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .HelpModal {
    &.ReactModal__Content {
      width: 690px !important;

      @include respond-to(sm) {
        width: 100% !important;
      }
    }

    .BasicModal__content {
      padding: 0px 28px;
    }
    &__content {
      max-height: 540px;
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      margin-bottom: 32px;

      @include respond-to(sm) {
        max-height: initial;
        img {
          max-width: 100%;
        }
      }
    }

    &__content::-webkit-scrollbar {
      display: none;
    }

    h2 {
      margin-top: 32px;
      @include body_20;
      color: var(--selected-color);
      text-align: left;
    }

    p {
      @include body_18;
      color: var(--text2-color);
      margin: 16px 0;
      text-align: left;
    }

    br {
      content: ' ';
      display: block;
      margin: 20px 0;
    }

    .breakline {
      // width: 626px;
      height: 1px;
      background: var(--border-color);
    }
  }
`;

export default staticStyles;
