import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .ConnectButton__inner {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include body_16;
  }

  .ConnectButton__menu {
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 12px;

    background: var(--dropdown-background-color);
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    opacity: 0.9;

    .ConnectButton__inner {
      padding: 0px;
      gap: 8px;
      color: var(--leverage-color);
    }
  }

  .ConnectButton__button {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 12px;

    background: var(--button1-color);
    box-shadow: 0px 2px 4px rgba(57, 96, 236, 0.18);
    border-radius: 10px;
    &:hover {
      background: var(--button1-hover-color);
    }

    .ConnectButton__inner {
      width: 100%;
      color: white;
    }
  }
`;

export default staticStyles;
