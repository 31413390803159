import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import {
  ComputedReserveData,
  formatReserves,
  formatUserSummaryData,
  normalize,
  normalizeBN,
  UserSummaryData,
} from '@sturdyfi/sturdy-js';

import { useCurrentTimestamp } from '../hooks/use-current-timestamp';
import { useStaticPoolDataContext } from './static-pool-data-provider';
import { useIncentivesDataContext } from '../hooks/use-incentives-data-context';
import Preloader from 'src/components/basic/Preloader';

export interface DynamicPoolDataContextData {
  reserves: ComputedReserveData[];
  user?: UserSummaryData;
  isLoading: boolean;
}

const DynamicPoolDataContext = React.createContext({} as DynamicPoolDataContextData);

export function DynamicPoolDataProvider({ children }: PropsWithChildren<{}>) {
  const {
    rawReserves,
    rawUserReserves,
    networkConfig,
    network,
    usdPriceEth,
    userId,
    extraAssetPrices,
    curvePoolDatas,
  } = useStaticPoolDataContext();
  const { reserveReward } = useIncentivesDataContext();
  const currentTimestamp = useCurrentTimestamp(1);
  const [formattedPoolReserves, setFormattedPoolReserves] = useState<ComputedReserveData[]>([]);
  const [formattingDone, setFormattingDone] = useState(false);

  useEffect(() => {
    if (!reserveReward.length || !rawReserves.length) return;

    (async () => {
      const reserves = await formatReserves(
        network,
        rawReserves,
        reserveReward || [],
        extraAssetPrices,
        networkConfig.usdMarket ? normalize(usdPriceEth, -8) : normalize(usdPriceEth, -18),
        currentTimestamp,
        curvePoolDatas
      );
      setFormattedPoolReserves(reserves);
    })();
  }, [rawReserves, reserveReward]);

  useEffect(() => {
    if (formattedPoolReserves.length) setFormattingDone(true);
    else setFormattingDone(false);
  }, [formattedPoolReserves]);

  rawReserves.forEach((item, _) => {
    item.symbol = networkConfig.collateralAssetFromSymbol?.[item.symbol] || item.symbol;
    item.collateralAsset = networkConfig.collateralAddresses?.[item.symbol] || '';
  });

  rawUserReserves?.forEach(
    (item, _) =>
      (item.reserve.symbol =
        networkConfig.collateralAssetFromSymbol?.[item.reserve.symbol] || item.reserve.symbol)
  );

  const computedUserData =
    userId && rawUserReserves && formattedPoolReserves.length > 0
      ? formatUserSummaryData(
          rawReserves,
          formattedPoolReserves,
          rawUserReserves,
          userId,
          networkConfig.usdMarket ? normalize(usdPriceEth, -8) : normalize(usdPriceEth, -18),
          currentTimestamp
        )
      : undefined;

  // This is a fix continue to use same aave-js. When update to aave-js with correct usd/eth market logic is integrated, remove
  if (networkConfig.usdMarket && computedUserData) {
    computedUserData.totalBorrowsUSD =
      computedUserData.totalBorrowsUSD !== '0'
        ? normalizeBN(computedUserData.totalBorrowsUSD, 8).toString()
        : '0';
    computedUserData.totalCollateralUSD =
      computedUserData.totalCollateralUSD !== '0'
        ? normalizeBN(computedUserData.totalCollateralUSD, 8).toString()
        : '0';
    computedUserData.totalLiquidityUSD =
      computedUserData.totalLiquidityUSD !== '0'
        ? normalizeBN(computedUserData.totalLiquidityUSD, 8).toString()
        : '0';
  }
  // if (!formattingDone) return <Preloader withBackground={true} />;

  return (
    <DynamicPoolDataContext.Provider
      value={{
        user: computedUserData,
        reserves: formattedPoolReserves,
        isLoading: !formattingDone,
      }}
    >
      {children}
    </DynamicPoolDataContext.Provider>
  );
}

export const useDynamicPoolDataContext = () => useContext(DynamicPoolDataContext);
