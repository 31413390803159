import React from 'react';
import { useIntl } from 'react-intl';

import messages from './messages';
import staticStyles from './style';
import classNames from 'classnames';
import { useUserWalletDataContext } from 'src/libs/web3-data-provider';

import connectWalletIcon from 'src/images/connectWallet.svg';

interface ConnectButtonProps {
  type?: 'menu' | 'icon' | 'button';
  className?: string;
  data?: Array<string>;
}

export default function ConnectButton({ type = 'menu', className, data }: ConnectButtonProps) {
  const intl = useIntl();
  const { showSelectWalletModal } = useUserWalletDataContext();

  return (
    <div className={classNames('ConnectButton', `ConnectButton__${type}`)}>
      <div
        className="ConnectButton__inner"
        onClick={() => {
          showSelectWalletModal();
        }}
      >
        {type === 'icon' ? (
          <img src={connectWalletIcon} alt="Sturdy" />
        ) : (
          <span>{intl.formatMessage(messages.connect)}</span>
        )}
      </div>
      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
