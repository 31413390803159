import classNames from 'classnames';
import { useIntl } from 'react-intl';

import messages from './messages';
import staticStyles from './style';

interface MoreInformationButtonProps {
  className?: string;
  title?: string;
  onClick: () => void;
}

export default function MoreInformationButton({
  className,
  title,
  onClick,
}: MoreInformationButtonProps) {
  const intl = useIntl();
  return (
    <div className={classNames('MoreInformationButton', className)} onClick={onClick}>
      <span>{title || intl.formatMessage(messages.title)}</span>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
