import { Network } from '@sturdyfi/sturdy-js';
import { ethers } from 'ethers';

import {
  ExplorerLinkBuilderConfig,
  ExplorerLinkBuilderProps,
  NetworkConfig,
  MarketDataType,
} from './types';

import { networkConfigs } from '../../ui-config/networks';

const linkBuilder =
  ({ baseUrl, addressPrefix = 'address', txPrefix = 'tx' }: ExplorerLinkBuilderConfig) =>
  ({ tx, address }: ExplorerLinkBuilderProps): string => {
    if (tx) {
      return `${baseUrl}/${txPrefix}/${tx}`;
    }
    if (address) {
      return `${baseUrl}/${addressPrefix}/${address}`;
    }
    return baseUrl;
  };

export function getNetworkConfig(network: Network): NetworkConfig {
  const config = networkConfigs[network];
  if (!config) throw new Error(`${network} network was not configured`);
  return { ...config, explorerLinkBuilder: linkBuilder({ baseUrl: config.explorerLink }) };
}

const providers: { [network: string]: ethers.providers.Provider } = {};

const CONNECTION_TIMEOUT = 15 * 1000; // default : 2*60*1000
// const CONNECTION_TIMEOUT = 2 * 60 * 1000;

export const getProvider = (network: Network): ethers.providers.Provider => {
  if (!providers[network]) {
    const config = getNetworkConfig(network);
    const jsonRPCUrl = config.privateJsonRPCUrl || config.publicJsonRPCUrl;
    if (!jsonRPCUrl) {
      throw new Error(`${network} has no jsonRPCUrl configured`);
    }
    const connectionInfo = {
      url: jsonRPCUrl,
      timeout: CONNECTION_TIMEOUT,
    };
    providers[network] = new ethers.providers.StaticJsonRpcProvider(connectionInfo);
  }
  return providers[network];
};
