import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 64px;
    border-bottom: 1px solid var(--border-color);
    min-height: 58px;
    position: relative;
    z-index: 5;

    &__left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 32px;

      .Header__logo {
        width: 115px;
        height: 31px;
      }
    }

    &__center {
      &__navigation {
        ul {
          display: flex;
          align-items: center;

          .Header__center__navigation-item {
            @include body_18_sb;
            margin-left: 30px;
          }
        }
      }
    }

    &__right {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      column-gap: 16px;
    }
    &__burger-inner {
      display: none;
      @include respond-to(sm) {
        display: block;
        margin: auto;
      }
    }

    @include respond-to(sm) {
      padding: 37px 24px 17px 24px;

      &__left {
        align-items: flex-start;

        .Header__logo {
          width: 100px;
          height: 26px;
        }
      }
    }
  }
`;

export default staticStyles;
