import classNames from 'classnames';

import staticStyles from './style';

interface DefaultButtonProps {
  title: string;
  color?: 'blue' | 'gray';
  type?: 'primary' | 'secondary';
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export default function DefaultButton({
  title,
  color,
  type,
  disabled,
  onClick,
  className,
}: DefaultButtonProps) {
  return (
    <div
      className={classNames(
        'DefaultButton',
        `DefaultButton__${type}`,
        `DefaultButton__${color}`,
        { DefaultButton__disabled: disabled },
        { DefaultButton__active: disabled !== true },
        className
      )}
      onClick={disabled ? () => {} : onClick}
    >
      {/* <button onClick={onClick} disabled={disabled} className="DefaultButton__title">{title}</button> */}
      <span className="DefaultButton__title">{title}</span>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
