import React from 'react';
import { useIntl } from 'react-intl';

import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import reloadIcon from 'src/images/reload.svg';
import reloadIconDark from 'src/images/reloadDark.svg';
import messages from '../../messages';

export default function ReloadButton() {
  const intl = useIntl();
  const { isCurrentThemeDark } = useThemeContext();

  return (
    <button
      className="ErrorBoundary__reload-button"
      type="button"
      onClick={() => window.location.reload()}
    >
      <img src={isCurrentThemeDark ? reloadIconDark : reloadIcon} alt="Reload" />
      <span>{intl.formatMessage(messages.reload)}</span>
    </button>
  );
}
