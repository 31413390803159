import { useIntl } from 'react-intl';
import Link from 'src/components/basic/Link';
import SpinLoader from 'src/components/basic/SpinLoader';

import { useProtocolDataContext } from 'src/libs/protocol-data-provider';

import messages from './messages';
import staticStyles from './style';

interface TxToastViewProps {
  type: 'pending' | 'success' | 'error';
  txHash?: string;
}

export default function TxToastView({ type, txHash }: TxToastViewProps) {
  const intl = useIntl();
  const { networkConfig } = useProtocolDataContext();

  const txReceiptUrl = txHash && networkConfig.explorerLinkBuilder({ tx: txHash });

  const titles = {
    pending: 'Transaction is processing...',
    success: 'Transaction completed',
    error: 'There was a problem with the transaction...',
  };

  return (
    <div className="TxToastView">
      <div className="TxToastView__icon">
        {type === 'pending' && <SpinLoader color="#3a61ed" />}
        {type === 'error' && <SpinLoader color="#3a61ed" />}
      </div>
      <div className="TxToastView__content">
        <div className="TxToastView__title">{titles[type]}</div>
        <div className="TxToastView__detail">
          {!!txReceiptUrl && (
            <Link
              className="TxToastView____link"
              to={txReceiptUrl}
              absolute={true}
              inNewWindow={true}
              color="dark"
            >
              <span>{intl.formatMessage(messages.explorer)}</span>
            </Link>
          )}
        </div>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
