import { valueToBigNumber, normalize } from '@sturdyfi/sturdy-js';
import React, { useEffect, useState } from 'react';
import { useStaticPoolDataContext } from 'src/libs/pool-data-provider';
import BorrowableDepositOverviewPanel from './BorrowableDepositOverviewPanel';
import BorrowableWithdrawOverviewPanel from './BorrowableWithdrawOverviewPanel';
import BorrowOverviewPanel from './BorrowOverviewPanel';
import CollateralDepositOverviewPanel from './CollateralDepositOverviewPanel';
import CollateralWithdrawOverviewPanel from './CollateralWithdrawOverviewPanel';
import DeleverageOverviewPanel from './DeleverageOverviewPanel';
import LeverageOverviewPanel from './LeverageOverviewPanel';
import RepayOverviewPanel from './RepayOverviewPanel';

import { OverviewDataType } from './types';

interface TransactionOverviewPanelProps {
  overviewData: OverviewDataType;
}

export default function TransactionOverviewPanel({ overviewData }: TransactionOverviewPanelProps) {
  const { transactionType, assetType, fetchGasData } = overviewData;
  const { marketRefPriceInUsd, baseAssetPrice } = useStaticPoolDataContext();
  const [loadingGasEstimation, setLoadingGasEstimation] = useState(false);
  const [gasEstimation, setGasEstimation] = useState('');

  useEffect(() => {
    let mounted = true;
    if (fetchGasData) {
      const getGasCost = async () => {
        setLoadingGasEstimation(true);
        const gasResponse = await fetchGasData();
        if (mounted) {
          let totalGas = gasResponse
            ? valueToBigNumber(gasResponse.gasLimit || '1')
                .multipliedBy(valueToBigNumber(gasResponse.gasPrice))
                .toString()
            : '0';
          totalGas = normalize(valueToBigNumber(totalGas), 18);
          const gasPriceInUSD = normalize(
            valueToBigNumber(baseAssetPrice).div(marketRefPriceInUsd),
            18
          );
          const cost = valueToBigNumber(totalGas).multipliedBy(gasPriceInUSD).toFixed(2);
          setGasEstimation(cost);
          setLoadingGasEstimation(false);
        }
      };

      getGasCost();
    }

    return () => {
      mounted = false;
    };
  }, [fetchGasData]);

  const overviewDataIncludeGasCost = {
    ...overviewData,
    gasEstimation,
    loadingGasEstimation,
  };

  return (
    <div className="TransactionOverviewPanel">
      {transactionType === 'deposit' && assetType === 'borrowable' && (
        <BorrowableDepositOverviewPanel {...overviewDataIncludeGasCost} />
      )}
      {transactionType === 'deposit' && assetType === 'collateral' && (
        <CollateralDepositOverviewPanel {...overviewDataIncludeGasCost} />
      )}
      {transactionType === 'withdraw' && assetType === 'borrowable' && (
        <BorrowableWithdrawOverviewPanel {...overviewDataIncludeGasCost} />
      )}
      {transactionType === 'withdraw' && assetType === 'collateral' && (
        <CollateralWithdrawOverviewPanel {...overviewDataIncludeGasCost} />
      )}
      {transactionType === 'borrow' && <BorrowOverviewPanel {...overviewDataIncludeGasCost} />}
      {transactionType === 'repay' && <RepayOverviewPanel {...overviewDataIncludeGasCost} />}
      {transactionType === 'leverage' && <LeverageOverviewPanel {...overviewDataIncludeGasCost} />}
      {transactionType === 'deleverage' && (
        <DeleverageOverviewPanel {...overviewDataIncludeGasCost} />
      )}
    </div>
  );
}
