import classNames from 'classnames';
import PanelWrapper from 'src/components/Wrappers/PanelWrapper';
import AverageAPYCard from './AverageAPYCard';
import BorrowAPYCard from './BorrowAPYCard';
import HealthFactorCard from './HealthFactorCard';
import RewardsCard from './RewardsCard';

import staticStyles from './style';
import SummaryCard from './SummaryCard';
import { DashboardSummaryDataType } from './type';

interface DashboardCardProps {
  type: 'summary' | 'avgAPY' | 'borrowAPY' | 'healthFactor' | 'rewards';
  summaryData?: DashboardSummaryDataType;
  value?: number | string;
  isLoading: boolean;
}

export default function DashboardCard({ type, summaryData, value, isLoading }: DashboardCardProps) {
  return (
    <PanelWrapper className={classNames('DashboardCard', `DashboardCard__${type}`)}>
      {type === 'summary' && <SummaryCard summaryData={summaryData} isLoading={isLoading} />}
      {type === 'avgAPY' && <AverageAPYCard value={value} isLoading={isLoading} />}
      {type === 'borrowAPY' && <BorrowAPYCard value={value} isLoading={isLoading} />}
      {type === 'healthFactor' && <HealthFactorCard value={value} isLoading={isLoading} />}
      {type === 'rewards' && <RewardsCard value={value} isLoading={isLoading} />}
      <style jsx={true}>{staticStyles}</style>
    </PanelWrapper>
  );
}
