import React, { ReactNode } from 'react';
import classNames from 'classnames';

import staticStyles from './style';

interface CaptionProps {
  title: string;
  description?: string | ReactNode;
  color?: 'primary' | 'secondary' | 'dark';
  className?: string;
  marginBottom?: number;
}

export default function Caption({
  title,
  description,
  color = 'primary',
  className,
  marginBottom,
}: CaptionProps) {
  return (
    <div
      className={classNames('Caption', `Caption__${color}`, className)}
      style={{ marginBottom: `${marginBottom}px` }}
    >
      <h2>{title}</h2>
      {description && <div className="Caption__description">{description}</div>}

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
