import { defineMessages } from 'react-intl';

// <> : separator
//  \\B : bold
//  \\G` : green
//  \\Y : yellow

export default defineMessages({
  aboveUtilizationRate: 'What happens if the utilization rate is above <>\\Y80%<>?',
  aboveUtilizationRateDesc:
    'Sturdy increases the deposit APY, as well as the borrow APY. \nWe do this for incentivizing deposits and balancing the pool. \nIn case you are a depositor you will be earning a higher yield, and in case you are a borrower, you APY will increase until the pool rebalances.',
  aboveZeroBorrowAPY: 'Why is the borrow APY above <>\\G0%<>?',
  aboveZeroBorrowAPYDesc:
    'In case the utilization rate of the asset goes above 80% Sturdy increases the borrow APY in order to incentivize deposits and rebalance the pool. \nIf you are a borrower you will be paying a higher APY, however this is just for the short time until the pool is balanced again.',
  apyOverTime: 'APY over time',
  apyOverTimeDesc: 'This is the APY change over time',
  availableLiquidity: 'Available liquidity',
  availableLiquidityDesc: 'The maximum available to borrow from this asset.',
  averageAPY: 'Average APY',
  averageAPYDesc: 'This is the average APY for the past 30 days.',
  balance: 'Balance',
  balanceDesc: 'This is the balance of this asset in Sturdy',
  borrowAPY: 'Borrow APY',
  borrowAPYDesc:
    'This is the current interest rate on your borrows. Note that borrow APY is subject to change depending on utilization rate.',
  borrowAPYForAsset: 'Borrow APY',
  borrowAPYForAssetDesc:
    'This is the current annual percentage yield (APY) if you decide to borrow this asset. Note that the borrow APY is subject to change depending on the utilization rate.',
  borrowing: 'Borrowing',
  borrowingDesc:
    'After providing collateral, borrowers are able to take out loans at <>\\G0%<> APY, if the utilization rate of the asset they are borrowing is below <>\\B80%',
  borrowingDesc2: 'Only assets from the deposit section count as collateral',
  borrowPosition: 'Borrow Position',
  borrowPositionDesc: 'Borrowing Asset',
  borrowPowerUsed: 'Borrow Power Used',
  borrowPowerUsedDesc: 'This is the percentage of the borrow power you have used.',
  crvRewards: 'CRV rewards',
  crvRewardsDesc:
    'These are your CRV rewards based on your Curve LP token deposits. Your rewards are harvested every 24-48 hours.',
  currentLTV: 'Current LTV',
  currentLTVDesc: 'This is your current loan to value percentage across all your borrows.',
  currentPrice: 'Current Price',
  currentPriceDesc: 'This is the current price of the asset you are depositing',
  depositAPY: 'Deposit APY',
  depositAPYDesc:
    'This is the current annual percentage yield (APY) you will receive if you deposit this asset.\nThe yields are paid out in-kind and distributed roughly every 24 hours.',
  depositAPYForCollateral: 'Deposit APY',
  depositAPYDescForCollateral:
    'This is the current annual percentage yield (APY) you will receive if you deposit this asset.\nThe yields are distributed every 24-48 hours. CRV rewards can be claimed on the Dashboard page.',
  depositing: 'Depositing',
  depositingDesc:
    'Sturdy takes the collateral provided by borrowers and stakes it into DeFi protocols like Yearn, Convex, and Lido.\nBorrowers earn a APY from their deposits, but they are also able to take out loans at <>\\G0%<> APY if the utilization rate of the asset they are borrowing is <>\\Bbelow 80%.',
  gasFee: 'Gas fee',
  gasFeeDesc: 'This is the amount you are expected to pay in gas for this transaction.',
  healthFactor: 'Health factor',
  healthFactorDesc1:
    'Your health factor determines how close you are of being liquidated. To increase your health factor and avoid liquidation you can deposit more assets or repay your borrow positions.',
  healthFactorDesc2:
    'If the health factor goes below 1, the liquidation of your collateral will be triggered.',
  highBorrowAPY: 'Why the borrow APY is <>\\Yhigher<> than 0%?',
  highBorrowAPYDesc:
    'When the utilization rate goes above 80% Sturdy increases the Borrow APY and the deposit APY. We do this because we want to incentivize depositors in order to bring the utilization rate down. If you are already a depositor it means that you are earning a higher yield during this time.',
  highDepositRate: 'Why is the deposit rate so <>\\Ghigh? ',
  highDepositRateDesc:
    'In case the utilization rate of the asset goes above 80% Sturdy increases the deposit APY in order to incentivize deposits and rebalance the pool. \nIf you are a depositor you will be earning a higher yield during this time. ',
  howDeleverageWorking: 'How is the deleverage working?',
  howDeleverageWorkingDesc1: 'The protocol will:',
  howDeleverageWorkingDesc2:
    '1. Withdraws Curve LP from Sturdy\n2. Withdraws stablecoin from Curve \n3. Repays Sturdy debt with stablecoin \n4. Withdraws Curve LP from Sturdy \n5. Repeat',
  howDeleverageWorkingDesc3:
    'The deleverage transaction will decrease the risk of your position being liquidated in case your collateral looses its value',

  howLeverageWorking: 'How is the leverage working?',
  howLeverageWorkingDesc1: 'The protocol will:',
  howLeverageWorkingDesc2:
    '1. Deposit Curve LP to Sturdy\n2. Borrow stablecoin from Sturdy \n3. Add stablecoin into Curve and receives Curve LP in return\n4. Deposit Curve LP to Sturdy\n5. Repeat',
  howLeverageWorkingDesc3:
    'The leverage transaction will increase the risk of your position being liquidated, in case your collateral looses its value',
  lending: 'Lending',
  lendingDesc:
    'Borrowers can borrow lent assets using interest-bearing tokens as collateral. The yield from the borrowers’ interest-bearing tokens are harvested and used to pay interest to lenders.',
  liquidationEvent: 'Liquidation event',
  liquidationPenalty: 'Liquidation penalty',
  liquidationPenaltyDesc:
    'When a liquidation occurs, liquidators repay part or all of the outstanding borrowed amount on behalf of the borrower. In return, they can buy the collateral at a discount and keep the difference as a bonus!',
  liquidationPrice: 'Liquidation price',
  liquidationPriceDesc:
    'You will get liquidated if the asset goes beyond this price. The liquidation price is also influenced by the health factor, meaning that depositing or withdrawing collateral will influence your liquidation price.',
  liquidationThreshold: 'Liquidation threshold',
  liquidationThresholdDesc:
    'This represents the threshold at which a borrow position will be considered undercollateralized and subject to liquidation for each collateral. For example, if a collateral has a liquidation threshold of 80%, it means that the loan will be liquidated when the debt value is worth 80% of the collateral value.',
  maxAmount: 'Max amount',
  maxAmountDesc:
    'This is either the maximum you have in your wallet or the maximum you can add at the moment.',
  maxAPY: 'Max. APY',
  maxAPYDesc:
    'This is the maximum APY you can receive if you opt for the maximum leverage on this asset.',
  maxLeverage: 'Max. Leverage',
  maxLeverageDesc: 'This is the maximum leverage you can opt for on this asset.',
  maxLTV: 'Max. LTV',
  maxLTVDesc:
    'The Maximum Loan-to-Value ratio represents the maximum borrowing power of a specific collateral. For example, if a collateral has a LTV of 75%, the user can borrow up to 0.75 worth of ETH in the principal currency for every 1 ETH worth of collateral.',
  overallLeverage: 'Overall leverage',
  overallLeverageDesc: 'This is your current overall leverage across all your deposits.',
  payingWith: 'Paying with',
  payingWithDesc:
    'This is the token you are paying with in order to deposit collateral.\nWe will be performing a token swap in the background in order to facilitate this. ',
  priceSlippage: 'Price slippage',
  priceSlippageDesc:
    'Slippage is the price difference that occurs between an assets quote price and paid cost. The percetage is the amount you allow for this transaction.',
  remainingBalance: 'Remaining Balance',
  remainingBalanceDesc: 'This is your remaining balance of this asset in Sturdy.',
  reserveSize: 'Reserve size',
  reserveSizeDesc:
    'This is the total market size of this asset, both borrowed and available liquidity.',
  totalAvailable: 'Total available',
  totalAvailableDesc: 'The total amount of assets available to be borrowed in this market.',
  totalBorrowed: 'Total borrowed',
  totalBorrowedDesc: 'The total amount of borrowed assets in this market.',
  totalBorrowedForAsset: 'Total borrowed',
  totalBorrowedForAssetDesc: 'The total amount borrowed for this asset.',
  totalCollateral: 'Total collateral',
  totalCollateralDesc:
    'This is the total amount you have deposited that contributes as collateral towards borrowing.',
  totalDepositAndBorrowed: 'Total deposited / Total borrowed',
  totalDepositAndBorrowedDesc:
    'This is the current total amount of deposits and borrows of the given asset.',
  totalLent: 'Total lent',
  totalLentDesc:
    'This is the total amount you have lent, this amount will not contribute towards baing able to borrow.',

  totalMarketSize: 'Total market size',
  totalMarketSizeDesc: 'The current total amount of deposits in this market.',
  useFlashloan: 'Use Flashloan',
  useFlashloanDesc: 'Use Flashloan',
  utilizationRate: 'Utilization rate',
  utilizationRateDesc:
    'The utilization rate signifies how much of the given asset is being borrowed.\nIf the utilization rate goes above 80%, the deposit APY will increase, as will the borrow APY. This is done to incentivize deposits and balance the pool.\nIf you are a lender, you will be earning a higher yield; if you are a borrower, your APY will increase until the pool rebalances and utilization drops below 80%.',
  walletBalance: 'Wallet balance',
  walletBalanceDesc: 'This is the current balance you have in your wallet.',
  whereIsYieldFrom: 'Where is the yield coming from?',
  whereIsYieldFromDesc:
    'Sturdy takes the collateral provided by borrowers and stakes it into DeFi protocols like Yearn, Convex, and Lido.\nThe staking yields are used to pay interest to lenders. \nBorrowers are able to borrow assets at o% APY under typical utilization rates.',
  whereIsYieldFromDesc2: 'The assets you lend out don’t count as collateral',
  yourPosition: 'Your Position',
  yourPositionDesc: 'This is your current position in this asset. ',
  zeroBorrowAPY: 'How do we achieve <>\\G0%<> borrow APY?',
  zeroBorrowAPYDesc:
    'Sturdy takes the collateral provided by borrowers and stakes it into DeFi protocols like Yearn, Convex, and Lido. \nThe staking yields are used to pay interest to lenders. Borrowers are able to borrow assets at <>\\G0%<> APY if the utilization rate of the asset is <>\\Bbelow 80%.',
});
