import React, { ReactElement, ReactNode, useContext } from 'react';
import {
  CurvePoolData,
  ExtraAssetPrice,
  Network,
  normalize,
  ReserveData,
  UserReserveData,
} from '@sturdyfi/sturdy-js';
import { useProtocolDataContext } from '../../protocol-data-provider';
import { useProtocolDataWithRpc } from '../hooks/use-v2-protocol-data-with-rpc';
import { useUserWalletDataContext } from '../../web3-data-provider';
import { NetworkConfig } from '../../../helpers/markets/types';
import { useConnectionStatusContext } from '../../connection-status-provider';
import { assetsOrder } from '../../../ui-config/assets';

/**
 * removes the marketPrefix from a symbol
 * @param symbol
 * @param prefix
 */
export const unPrefixSymbol = (symbol: string, prefix: string) => {
  return symbol.replace(new RegExp(`^(${prefix[0]}?${prefix.slice(1)})`), '');
};

export interface StaticPoolDataContextData {
  userId?: string;
  network: Network;
  networkConfig: NetworkConfig;
  rawReserves: ReserveData[];
  isUserHasDeposits: boolean;
  rawUserReserves?: UserReserveData[];
  extraAssetPrices: ExtraAssetPrice[];
  curvePoolDatas?: { [key: string]: CurvePoolData };
  userUnclaimedRewards: string;
  rewardsEmissionEndTimestamp: number;
  marketRefPriceInUsd: string;
  usdPriceEth: string;
  baseAssetPrice: string;
  WrappedBaseNetworkAssetAddress: string;
  refresh: () => Promise<void>;
}

const StaticPoolDataContext = React.createContext({} as StaticPoolDataContextData);

interface StaticPoolDataProviderProps {
  children: ReactNode;
  loader: ReactElement;
  errorPage: ReactElement;
}

export function StaticPoolDataProvider({
  children,
  loader,
  errorPage,
}: StaticPoolDataProviderProps) {
  const { currentAccount, isLoading } = useUserWalletDataContext();
  const { currentMarketData, network, networkConfig } = useProtocolDataContext();
  const { isRPCActive } = useConnectionStatusContext();
  const RPC_ONLY_MODE = networkConfig.rpcOnly;

  const {
    error: rpcDataError,
    loading: rpcDataLoading,
    data: rpcData,
    refresh,
  } = useProtocolDataWithRpc(
    currentMarketData.addresses.LENDING_POOL_ADDRESS_PROVIDER,
    currentAccount,
    isLoading,
    network,
    networkConfig.addresses.uiPoolDataProvider,
    networkConfig.addresses.sturdyOracle,
    networkConfig.addresses.extraAssetsRequiredPrice,
    !isRPCActive, // TODO: think one more time
    networkConfig.addresses.curvePoolInfo
  );

  if ((isRPCActive && rpcDataLoading) || !isRPCActive) {
    return loader;
  }

  const activeData = isRPCActive && rpcData ? rpcData : undefined;

  if ((isRPCActive && rpcDataError) || !isRPCActive) {
    return errorPage;
  }

  if (!activeData || rpcDataLoading) return <></>;

  const {
    rewardsData: {
      emissionEndTimestamp: rewardsEmissionEndTimestamp,
      userUnclaimedRewards: userUnclaimedRewardsRaw,
    },
    usdPriceEth,
    userId,
  } = activeData;

  const reserves = activeData.reserves.sort(
    ({ symbol: a }, { symbol: b }) =>
      assetsOrder.indexOf(a.toUpperCase()) - assetsOrder.indexOf(b.toUpperCase())
  );
  const userReserves = activeData.userReserves;

  const isUserHasDeposits = userReserves.some(
    (userReserve) => userReserve.scaledATokenBalance !== '0'
  );

  if (!RPC_ONLY_MODE && isRPCActive && rpcData) {
    console.log('switched to RPC');
  }

  const userUnclaimedRewards = normalize(
    userUnclaimedRewardsRaw,
    networkConfig.rewardTokenDecimals
  );

  let WrappedBaseNetworkAssetAddress = '';
  let baseAssetPrice = '0';
  reserves.forEach((reserve) => {
    if (reserve.symbol.toUpperCase() === `W${networkConfig.baseAsset}`) {
      WrappedBaseNetworkAssetAddress = reserve.underlyingAsset;
    }

    //FTM Price
    if (reserve.symbol === `yvW${networkConfig.baseAsset}`) {
      baseAssetPrice = reserve.price.priceInEth;
    }
  });

  return (
    <StaticPoolDataContext.Provider
      value={{
        userId,
        network,
        networkConfig,
        refresh: isRPCActive ? refresh : async () => {},
        WrappedBaseNetworkAssetAddress,
        rawReserves: reserves,
        rawUserReserves: userReserves,
        extraAssetPrices: activeData.extraAssetPrices,
        curvePoolDatas: activeData.curvePoolDatas,
        isUserHasDeposits,
        marketRefPriceInUsd: networkConfig.usdMarket
          ? normalize(1, 10)
          : normalize(usdPriceEth, 18),
        usdPriceEth: normalize(usdPriceEth, 18),
        baseAssetPrice: networkConfig.usdMarket ? baseAssetPrice : normalize(1, -18),
        rewardsEmissionEndTimestamp,
        userUnclaimedRewards,
      }}
    >
      {children}
    </StaticPoolDataContext.Provider>
  );
}

export const useStaticPoolDataContext = () => useContext(StaticPoolDataContext);
