import messages from './messages';
import staticStyles from './style';
import { useIntl } from 'react-intl';
import NoData from 'src/components/basic/NoData';
import Value from 'src/components/basic/Value';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';
import { useNavigate } from 'react-router-dom';
import { useIncentivesDataContext } from 'src/libs/pool-data-provider/hooks/use-incentives-data-context';
import { useMemo } from 'react';
import { REWARD_TYPE } from '@sturdyfi/sturdy-js';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';

interface RewardsCardProps {
  value?: number | string;
  isLoading: boolean;
}

export default function RewardsCard({ value, isLoading }: RewardsCardProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { userReward } = useIncentivesDataContext();
  const id = useMemo(() => {
    return userReward?.filter(
      (entry) =>
        entry.totalUnclaimedRewards > 0 &&
        entry.rewardUserDatas[0].rewardType === REWARD_TYPE.VARIABLE
    )?.[0]?.id;
  }, []);

  const onClaim = () => {
    navigate(`/rewards/${id}`);
  };

  return (
    <>
      <div className="DashboardCard__title">
        <TextWithInfoWrapper text={intl.formatMessage(messages.title)} tooltipName="crvRewards" />
      </div>
      <div className="DashboardCard__content">
        <div className="Rewards__section">
          {isLoading ? (
            <SkeletonWrapper height={30} />
          ) : (
            <>
              <div className="Rewards__value">{value ? <Value value={value} /> : <NoData />}</div>
              <div className="Rewards__button" onClick={onClaim}>
                <span>{intl.formatMessage(messages.claim)}</span>
              </div>
            </>
          )}
        </div>
      </div>
      <style jsx={true}>{staticStyles}</style>
    </>
  );
}
