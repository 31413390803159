import { Wallet } from '../../index';
import { AvailableWeb3Connectors } from '../../../../libs/web3-data-provider';

import staticStyles from './style';

interface WalletCardProps extends Wallet {
  handleUnlockExternalWallet: (providerName: AvailableWeb3Connectors) => void;
}

export default function WalletCard({
  title,
  icon,
  disabled,
  providerName,
  handleUnlockExternalWallet,
  errorMessage,
}: WalletCardProps) {
  return (
    <button
      className="WalletCard"
      onClick={() => handleUnlockExternalWallet(providerName)}
      disabled={disabled}
      type="button"
    >
      {disabled && errorMessage && <strong className="WalletCard__error">{errorMessage}</strong>}

      <div className="WalletCard__inner">
        <div className="WalletCard__image-inner">
          <img src={icon} alt={title} />
        </div>

        <div className="WalletCard__text-inner">
          <p>{title}</p>
        </div>
      </div>

      <style jsx={true}>{staticStyles}</style>
    </button>
  );
}
