import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--border-color);
    padding: 12px 88px;

    &__right {
      .SocialIcons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 20px;
      }
    }

    @include respond-to(sm) {
      padding: 16px 24px;
    }
  }
`;

export default staticStyles;
