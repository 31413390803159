import classNames from 'classnames';
import { useIntl } from 'react-intl';

import PanelWrapper from '../Wrappers/PanelWrapper';
import ChartWrapper from '../Wrappers/ChartWrapper';
import StatsPanel from './StatsPanel';
import AboutBox from './AboutBox';

import { ReserveOverviewDataType } from './types';
import messages from './messages';
import staticStyles from './style';
import APYChartModal from '../APYChartModal';
import { useState } from 'react';

interface ReserveOverviewPanelProps {
  data: ReserveOverviewDataType;
  className?: string;
}

export default function ReserveOverviewPanel({ data, className }: ReserveOverviewPanelProps) {
  const intl = useIntl();
  const [chartModalVisible, setChartModalVisible] = useState(false);
  const { statsData, chartData, aboutData } = data;

  return (
    <PanelWrapper className={classNames('ReserveOverviewPanel', className)}>
      <APYChartModal
        isVisible={chartModalVisible}
        onBackdropPress={() => {
          setChartModalVisible(false);
        }}
        reserveData={data}
        symbol={data.assetSymbol}
      />
      <div className="ReserveOverviewPanel__title">
        <p>{intl.formatMessage(messages.title)}</p>
      </div>
      <div className="ReserveOverviewPanel__separate-line"></div>
      <div className="ReserveOverviewPanel__content">
        <div className="ReserveOverviewPanel__chart">
          <ChartWrapper
            title={'Deposit APY Over Time'}
            width={408}
            height={166}
            symbol={data.assetSymbol}
            onHandleExpand={() => setChartModalVisible(true)}
            marketData={chartData}
          />
        </div>
        <div className="ReserveOverviewPanel__stats">
          <StatsPanel statsData={statsData} />
        </div>
        {/* <div className="ReserveOverviewPanel__about">
          {aboutData && <AboutBox data={aboutData} />}
        </div> */}
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </PanelWrapper>
  );
}
