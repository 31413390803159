import React from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import MenuLink from '../MenuLink';
import LinkWrapper from '../Wrappers/LinkWrapper';
import logoLight from '../../images/logo_light.svg';
import logoDark from '../../images/logo_dark.svg';
import MarketSwitcher from '../MarketSwitcher';
import DarkModeSwitcher from '../DarkModeSwitcher';
import AddressInfo from '../AddressInfo';
import navigation from './navigation';

import staticStyles from './style';
import MobileContent from './MobileContent';

type HeaderProps = {
  title: string;
};

export default function Header({ title }: HeaderProps) {
  const { isCurrentThemeDark, sm } = useThemeContext();
  const routes = [{ path: '/markets' }, { path: '/dashboard' }];

  const location = useLocation();
  const showMenu = matchRoutes(routes, location) !== null && !sm;

  const intl = useIntl();
  const isActive = (url: string) => {
    return `/${url.split('/')[1]}` === `/${location.pathname.split('/')[1]}`;
  };

  return (
    <header>
      <div className="Header">
        <div className="Header__left">
          <LinkWrapper to="/markets" onClick={() => {}}>
            <img
              className="Header__logo"
              src={isCurrentThemeDark ? logoDark : logoLight}
              alt="Sturdy"
            />
          </LinkWrapper>
          {!sm && <DarkModeSwitcher />}
        </div>

        {showMenu && (
          <div className="Header__center">
            <nav className="Header__center__navigation">
              <ul>
                {navigation.map((link, index) => (
                  <li className="Header__center__navigation-item" key={index}>
                    <MenuLink
                      to={link.link}
                      title={intl.formatMessage(link.title)}
                      isActive={isActive(link.link)}
                    />
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        )}
        <div className="Header__right">
          {!sm && <MarketSwitcher />}
          <AddressInfo />
          <div className="Header__burger-inner">
            <MobileContent isActive={isActive} />
          </div>
        </div>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </header>
  );
}
