import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ReactModal__Overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(25, 31, 54, 0.4) !important;
    position: relative;
    z-index: 99;
    @include respond-to(sm) {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .ReactModal__Content {
    position: relative !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    outline: none !important;

    padding: 0px !important;

    background: var(--dropdown-background-color) !important;

    border: 1px solid #edeff3;
    box-shadow: 0px 0px 22px rgba(203, 207, 211, 0.1);
    backdrop-filter: blur(4px);

    border-radius: 16px !important;
    @include respond-to(sm) {
      border-radius: 0px !important;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      display: block;
    }
  }

  .ReactModal__Content,
  .BasicModal__dark {
    border: none;
    box-shadow: none;
    backdrop-filter: none;
  }

  .BasicModal__title {
    height: 88px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--border-color);
    @include body_20_sb;
    color: var(--selected-color);
  }

  .BasicModal__content {
    padding: 32px 28px;
  }

  .BasicModal__close-wrapper {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 24px;
    top: 32px;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .BasicModal__close {
    border: 0;
    background: transparent;
    position: fixed;
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 0;
    line-height: 0;
    margin: 0;
    img {
      width: 13px;
      height: 13px;
    }
  }

  .BasicModal__close:hover {
    opacity: 0.7;
  }

  .BasicModal__close:active {
    transform: scale(0.8);
  }

  .BasicModal__breakline {
    position: absolute;
    height: 1px;
    left: 1px;
    right: 1px;
    top: 87px;
    background: #edeff3;
  }
  @media only screen and (max-height: 400px) {
    .ReactModal__Content.ReactModal__Content--after-open {
      position: absolute !important;
      top: 5% !important;
      bottom: 5% !important;
      display: block;
      overflow: auto !important;
    }
  }
`;

export default staticStyles;
