import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ScreenWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    max-width: 1263px;
    min-height: 650px;
    margin: 40px auto;
    position: relative;
    z-index: 2;
    padding: 0 20px;

    @include respond-to(sm) {
      padding: 0 24px;
    }
  }
`;

export default staticStyles;
