import { useNavigate } from 'react-router-dom';

import PanelWrapper from '../../../../components/Wrappers/PanelWrapper';

import staticStyles from './style';
import BackButton from '../../../../components/basic/BackButton';
import RewardMain from '../RewardMain';

export default function RewardCurrencyWrapper() {
  const navigate = useNavigate();

  return (
    <PanelWrapper className={'RewardCurrencyWrapper'}>
      <div className="RewardCurrencyWrapper__title">
        <BackButton onClick={() => navigate(-1)} />
        <p className="RewardCurrencyWrapper__title-caption">{'CRV'}</p>
      </div>
      <div className="RewardCurrencyWrapper__separate-line"></div>
      <div className="RewardCurrencyWrapper__content">
        <RewardMain />
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </PanelWrapper>
  );
}
