import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .EtherMarketSwitcher {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin: 0 auto;

    &__market {
      box-sizing: border-box;
      border-radius: 56px;
      background: var(--background-color);
      border: 1px solid var(--tabs-color);
      color: var(--selected-color);

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 8px 16px;
      gap: 8px;

      img {
        width: 22px;
        height: 22px;
      }
      span {
        @include body_16;
      }
    }

    &__marketActivated {
      background: var(--button1-color);
      color: white;
      span {
        @include body_16_sb;
      }
    }
  }
`;

export default staticStyles;
