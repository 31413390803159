import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .DarkModeSwitcher {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 32px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .DarkModeSwitcher__slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $selected_color;
      border-radius: 100px;

      img {
        -webkit-transform: translate(34px, 6px);
        -ms-transform: translate(34px, 6px);
        transform: translate(34px, 6px);

        @include respond-to(sm) {
          -webkit-transform: translate(12px, 6px);
          -ms-transform: translate(12px, 6px);
          transform: translate(12px, 6px);
        }
      }

      span {
        border-radius: 100px;
        background: var(--not-selected-color);
        width: 20px;
        height: 20px;
        display: inline-block;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        position: absolute;
        top: 6px;
        left: 10px;
        border-radius: 50%;
      }
    }

    input:checked + .DarkModeSwitcher__slider {
      img {
        -webkit-transform: translate(10px, 6px);
        -ms-transform: translate(10px, 6px);
        transform: translate(10px, 6px);
        @include respond-to(sm) {
          -webkit-transform: translate(-12px, 6px);
          -ms-transform: translate(-12px, 6px);
          transform: translate(-12px, 6px);
        }

      }

      span {
        -webkit-transform: translateX(24px);
        -ms-transform: translateX(24px);
        transform: translateX(24px);
      }
    }
  }

  .DarkModeSwitcher__active {
    .DarkModeSwitcher__slider {
      background-color: var(--button-background-color);
    }
  }
`;

export default staticStyles;
