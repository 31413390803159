import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Value {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &__line {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__subValue--line {
      margin-top: 2px;
    }

    &__value {
      position: relative;
      // z-index: 2;
      white-space: nowrap;
    }
    &__symbol {
      white-space: nowrap;
    }

    &__token-icon {
      .TokenIcon__dollar {
      }
    }

    &__withSmallDecimals {
      .Value__symbol {
        margin-left: 3px;
      }
    }

    .Value__tooltip {
      white-space: nowrap;
    }
  }
`;

export default staticStyles;
