import { useCallback } from 'react';

import ConnectButton from 'src/components/ConnectButton';
import PoolTxConfirmationView, { EmptyTransaction } from 'src/components/PoolTxConfirmationView';
import staticStyles from './style';
import { useLocation, useNavigate } from 'react-router-dom';
import { useIncentivesDataContext } from 'src/libs/pool-data-provider/hooks/use-incentives-data-context';
import { useDynamicPoolDataContext } from 'src/libs/pool-data-provider';

export default function RewardMain() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useDynamicPoolDataContext();
  const { userReward, variableRewardsTxBuilder } = useIncentivesDataContext();
  const info = location.pathname.split('/')[2];
  const asset = info.split('-')[0];
  const distributorAddress = info.split('-')[1];
  const rewardData = userReward?.find(
    (item) => item.rewardTokenAddress.toLowerCase() === asset.toLocaleLowerCase()
  );

  const handleGetTransactions = useCallback(async () => {
    if (user) {
      return variableRewardsTxBuilder.claimRewards({
        user: user.id,
        assets:
          rewardData?.rewardUserDatas
            .filter((item) => Number(item.userUnclaimedRewards) > 0)
            .map((item) => item.tokenAddress) || [],
        to: user.id,
        distributorAddress,
      });
    }
    return EmptyTransaction;
  }, []);

  const handleMainTxCompleted = () => {
    navigate('/dashboard');
  };

  return (
    <div className="RewardMain">
      {!user && <ConnectButton type="button" />}
      {user && (
        <PoolTxConfirmationView
          mainTxName={'claim'}
          currencySymbol={'CRV'}
          getTransactionsData={handleGetTransactions}
          onMainTxConfirmed={handleMainTxCompleted}
        />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
