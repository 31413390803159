import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .DefaultButton {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 12px;

    &__primary {
      border-radius: 10px;
      @include body_16;
    }
    &__secondary {
      border-radius: 23px;
      @include body_14_sb;
    }
    &__active {
      cursor: pointer;
    }
    &__disabled {
      cursor: default;
      .DefaultButton__title {
        opacity: 0.7;
      }
    }
    &__blue {
      background: var(--button1-color);
      box-shadow: 0px 2px 4px rgba(57, 96, 236, 0.18);
      color: white;
      &:hover {
        background: var(--button1-hover-color);
      }
    }
    &__gray {
      background: var(--button2-color);
      color: var(--selected-color);
      &:hover {
        background: var(--button2-hover-color);
      }
    }
    &__title {
      @include body_16;
    }
  }
  .DefaultButton__blue.DefaultButton__disabled {
    &:hover {
      background: var(--button1-color);
    }
  }
  .DefaultButton__gray.DefaultButton__disabled {
    &:hover {
      background: var(--button2-color);
    }
  }
`;

export default staticStyles;
