import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { Network } from '@sturdyfi/sturdy-js';

import DefaultButton from '../../basic/DefaultButton';

import {
  AvailableWeb3Connectors,
  useUserWalletDataContext,
} from '../../../libs/web3-data-provider';

import messages from './messages';
import staticStyles from './style';

interface NetworkMismatchProps {
  neededNetworkName: Network;
  currentNetworkName: Network;
  currentProviderName: AvailableWeb3Connectors;
}

export default function NetworkMismatch({
  neededNetworkName,
  currentNetworkName,
  currentProviderName,
}: NetworkMismatchProps) {
  const intl = useIntl();
  const { handleNetworkChange } = useUserWalletDataContext();

  const isNeededNetworkNamePolygon = false;
  const isAddableByMetamask = false;

  const isManualNetworkUpdateNeeded = ['torus', 'portis'].includes(currentProviderName);
  const isNeededNetworkNotSupported = false;

  return (
    <div className="NetworkMismatch">
      <div
        className={classNames('NetworkMismatch__top-inner', {
          NetworkMismatch__onlyText: isAddableByMetamask,
        })}
      >
        <h4>
          {isNeededNetworkNotSupported
            ? intl.formatMessage(messages.networkIsNotSupportedCaption)
            : intl.formatMessage(messages.caption, {
                networkName: isNeededNetworkNamePolygon
                  ? 'Polygon POS'
                  : neededNetworkName.toUpperCase(),
              })}
        </h4>

        <div className="NetworkMismatch__textInner">
          <p>
            {isNeededNetworkNotSupported
              ? intl.formatMessage(messages.networkIsNotSupportedDescription, {
                  networkName: neededNetworkName.toUpperCase(),
                  walletName: currentProviderName,
                })
              : intl.formatMessage(messages.description, {
                  networkName: currentNetworkName.toUpperCase(),
                  additional: !isAddableByMetamask
                    ? intl.formatMessage(messages.additionalDescription)
                    : '',
                })}
          </p>

          {isManualNetworkUpdateNeeded && (
            <DefaultButton
              title={intl.formatMessage(messages.changeNetwork)}
              onClick={() => handleNetworkChange(neededNetworkName)}
            />
          )}
        </div>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .NetworkMismatch {
        }
      `}</style>
    </div>
  );
}
