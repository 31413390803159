import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Summary__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .Summary__subtitle {
      @include body_16;
      color: var(--text2-color);
    }
    .Summary__value {
      @include body_16;
      color: var(--selected-color);
    }

    .DropdownWrapper {
      display: flex;
      align-items: center;
      justify-contents: center;
    }
    .DropdownWrapper__content {
      background-color: var(--dropdown-background-color);
    }
    .DropdownWrapper__button {
      box-shadow: none;
      background: none;
    }

    button {
      @include body_16;
      color: var(--text2-color);
      width: 100%;
    }
  }

  .TotalDeposited {
    z-index: 5;
    &__content {
      width: 372px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      row-gap: 8px;
    }
    &__section {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
    &__title {
      @include body_18_sb;
      color: var(--selected-color);
    }
    &__subtitle {
      @include body_16;
      color: var(--text2-color);
    }
    &__value {
      @include body_16;
      color: var(--selected-color);
    }
    &__tooltip {
      position: relative;
      // z-index: 99;
    }
  }
`;

export default staticStyles;
