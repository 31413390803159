import classNames from 'classnames';
import React, { ChangeEvent } from 'react';
import { valueToBigNumber } from '@sturdyfi/sturdy-js';
import { TokenIcon, useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import { TooltipName } from '../../Tooltip';
import Value from '../../basic/Value';
import AssetSwitcherWrapper from './AssetSwitcherWrapper';
import TextWithInfoWrapper from '../TextWithInfoWrapper';
import downArrowLight from 'src/images/downArrow_light.svg';
import downArrowDark from 'src/images/downArrow_dark.svg';
import staticStyles from './style';

interface AmountBoxWrapperProps {
  symbols: string[];
  value: string;
  onChange?: (value: string) => void;
  title?: string;
  titleTooltipName?: TooltipName;
  disabled?: boolean;
  loading?: boolean;
  hasMax?: boolean;
  maxValue?: string;
  maxDecimals?: number;
  className?: string;
  selectedSymbol?: string;
  onChangeSymbol?: (symbol: string) => void;
  onClickAsset?: () => void;
}

export default function AmountBoxWrapper({
  symbols,
  value,
  onChange,
  title,
  titleTooltipName,
  disabled,
  loading,
  maxDecimals = 18,
  hasMax,
  maxValue,
  className,
  selectedSymbol,
  onChangeSymbol,
  onClickAsset,
}: AmountBoxWrapperProps) {
  const { isCurrentThemeDark } = useThemeContext();
  const downArrow = isCurrentThemeDark ? downArrowDark : downArrowLight;
  const blockInvalidChar = (event: React.KeyboardEvent<HTMLInputElement>) =>
    ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();

  // prevent value  from getting larger than maxValue
  if (hasMax && maxValue && valueToBigNumber(value).gt(maxValue) && onChange) {
    onChange(maxValue);
  }
  // remove leading zeroes before meaningful digit
  const integerPart = (value.split('.')[0] || '').replace(/^0[0-9]/, '0');
  // remove decimal places which does not make sense
  const decimalPart = (value.split('.')[1] || '').substring(0, maxDecimals);
  // merge
  const formattedValue = decimalPart ? `${integerPart}.${decimalPart}` : integerPart;

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const newAmountValue = valueToBigNumber(event.target.value);
    let newValue = '';
    if (newAmountValue.isNegative()) {
      newValue = '0';
    } else if (hasMax && maxValue && newAmountValue.gt(maxValue)) {
      newValue = maxValue;
    } else if (newAmountValue.eq(0)) {
      newValue = event.target.value;
    } else {
      newValue = newAmountValue.toString();
    }
    onChange && onChange(newValue);
  };

  const handleMaxButtonClick = () => {
    onChange && onChange(maxValue || '0');
  };

  const renderTitle = () => {
    return (
      <div className={'AmountBoxWrapper__title'}>
        {title && (
          <div>
            {titleTooltipName && (
              <TextWithInfoWrapper text={title} tooltipName={titleTooltipName} />
            )}
            {!titleTooltipName && <p>{title}</p>}
          </div>
        )}
        {hasMax && (
          <div className={'AmountBoxWrapper__title__maxValue'} onClick={handleMaxButtonClick}>
            {/* <TextWithInfoWrapper text="Max:" infoPosition="left" /> */}
            <p>Max:</p>&nbsp;
            <Value value={maxValue?.toString() || '-'} maximumValueDecimals={2} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className={classNames('AmountBoxWrapper', className, {
        AmountBoxWrapper__disabled: disabled,
      })}
    >
      {renderTitle()}
      <div className={'AmountBoxWrapper__content'}>
        <input
          className={'AmountBoxWrapper__content__input'}
          value={formattedValue}
          onChange={handleOnChange}
          type="number"
          placeholder="0"
          step="any"
          min="0"
          disabled={onChange ? disabled || loading : true}
          onKeyDown={blockInvalidChar}
        />
        {symbols.length > 1 ? (
          <AssetSwitcherWrapper
            data={symbols}
            activeAsset={selectedSymbol || ''}
            onChangeSymbol={onChangeSymbol}
          />
        ) : (
          <div
            className={'AmountBoxWrapper__content__token'}
            onClick={() => onClickAsset && onClickAsset()}
          >
            {symbols.length > 0 ? (
              <TokenIcon
                tokenSymbol={symbols[0]}
                height={24}
                width={24}
                withShortName={true}
                className=""
              />
            ) : (
              <></>
            )}
            {onClickAsset && <img src={downArrow} width={16} height={16} alt="" />}
          </div>
        )}
      </div>
      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .AmountBoxWrapper {
          &__content {
            &__token {
              cursor: ${onClickAsset ? 'pointer' : 'initial'};
            }
          }
        }
      `}</style>
    </div>
  );
}
