import classNames from 'classnames';
import React, { ReactNode } from 'react';

import staticStyles from './style';

interface BasicTableWrapperProps {
  children: ReactNode;
  headerColumns?: ReactNode;
  className?: string;
}

export default function BasicTableWrapper({
  children,
  headerColumns,
  className,
}: BasicTableWrapperProps) {
  return (
    <div className={classNames('BasicTableWrapper', className)}>
      <div className="BasicTableWrapper__container">
        {!!headerColumns && <div className="BasicTableWrapper__header">{headerColumns}</div>}

        <div className="BasicTableWrapper__content">
          <div className="BasicTableWrapper__content-inner">{children}</div>
        </div>
      </div>

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
