import { useIntl } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import staticStyles from './style';

import closeIcon from 'src/images/closeIcon.svg';
import BasicModal from '../basic/BasicModal';
import ChartWrapper from '../Wrappers/ChartWrapper';
import { ReserveOverviewDataType } from '../ReserveOverviewPanel/types';
import StatsPanel from '../ReserveOverviewPanel/StatsPanel';

interface APYChartModalProps {
  className?: string;
  symbol: string;
  isVisible: boolean;
  reserveData: ReserveOverviewDataType;
  onBackdropPress: () => void;
}

export default function APYChartModal({
  className,
  symbol,
  isVisible,
  reserveData,
  onBackdropPress,
}: APYChartModalProps) {
  const intl = useIntl();

  return (
    <BasicModal
      onBackdropPress={onBackdropPress}
      isVisible={isVisible}
      title={intl.formatMessage(messages.caption)}
      withCloseButton={true}
      className={classNames('APYChartModal', className)}
      closeIcon={closeIcon}
    >
      <div className="APYChartModal__inner">
        <ChartWrapper
          title={'Deposit APY Over Time'}
          width={900}
          height={280}
          symbol={symbol}
          isExpandView={true}
          marketData={reserveData.chartData}
        />
        <StatsPanel statsData={reserveData.statsData} />
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicModal>
  );
}
