import React, { ReactNode } from 'react';
import classNames from 'classnames';

import staticStyles from './style';

interface ScreenWrapperProps {
  isTitleOnDesktop?: boolean;
  className?: string;
  children: ReactNode;
}

export default function ScreenWrapper({
  isTitleOnDesktop,
  className,
  children,
}: ScreenWrapperProps) {
  return (
    <section
      className={classNames('ScreenWrapper', className, {
        ScreenWrapper__withDesktopTitle: isTitleOnDesktop,
      })}
    >
      {children}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </section>
  );
}
