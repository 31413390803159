import React from 'react';

import staticStyles from './style';

interface NoDataProps {
  className?: string;
}

export default function NoData({ className }: NoDataProps) {
  return (
    <span className="NoData">
      —<style jsx={true}>{staticStyles}</style>
    </span>
  );
}
