import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .BackButton {
    position: absolute;
    left: 24px;
    top: 24px;
    width: 24px;
    height: 24px;

    cursor: pointer;

    @include respond-to(sm) {
      left: 0px;
      top: 4px;
    }
  }
`;

export default staticStyles;
