import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ActionSwitcher {
    @include popular_drop;
    width: 440px;
    height: 55px;
    border-radius: 46px;
    margin-top: 25px;
    padding: 8px;
    background: var(--tab-background-color);
    box-shadow: none !important;

    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to(sm) {
      width: 100%;
      padding: 8px 16px;
    }
  }
`;

export default staticStyles;
