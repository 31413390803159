import React from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { IntlProvider } from 'react-intl';
import { positions, Provider as AlertProvider, transitions } from 'react-alert';

import { ethers } from 'ethers';
import { Web3ReactProvider } from '@web3-react/core';

import { ThemeProvider } from '@sturdyfi/sturdy-ui-kit';

import { LanguageProvider } from './libs/language-provider';
import { Web3Provider } from './libs/web3-data-provider';
import { MenuProvider } from './libs/menu';
import { ProtocolDataProvider } from './libs/protocol-data-provider';
import { TxBuilderProvider } from './libs/tx-provider';

import { WalletBalanceProvider } from './libs/wallet-balance-provider/WalletBalanceProvider';
import { DynamicPoolDataProvider } from './libs/pool-data-provider';
import { ConnectionStatusProvider } from './libs/connection-status-provider';
import { IncentivesDataProvider } from './libs/pool-data-provider/hooks/use-incentives-data-context';

import initSentry from './libs/sentry';

import { getDefaultNetworkName, getSupportedNetworks, IPFS_MODE } from './config';

import AlertTemplate from './components/AlertTemplate';
import StaticPoolDataProviderWrapper from './components/PoolDataProviderWrapper';
import ErrorBoundary from './components/ErrorBoundary';
import { UnlockWalletPreloader } from './components/UnlockWalletPreloader';
import ConnectWalletModal from './components/ConnectWalletModal';

import App from './App';
import * as serviceWorker from './serviceWorker';

import globalStyle from './globalStyle';
import '@sturdyfi/sturdy-ui-kit/dist/sturdy-ui-kit.cjs.development.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

initSentry();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const options = {
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '5px',
  transition: transitions.FADE,
};

const GTM_ID = process.env.REACT_APP_GTM_ID;
if (GTM_ID) {
  TagManager.initialize({ gtmId: GTM_ID });
}

function getWeb3Library(provider: any): ethers.providers.Web3Provider {
  return new ethers.providers.Web3Provider(provider);
}

const Router = ({ children }: React.PropsWithChildren<{}>) =>
  IPFS_MODE ? <HashRouter>{children}</HashRouter> : <BrowserRouter>{children}</BrowserRouter>;

root.render(
  <div className="Main">
    <React.StrictMode>
      <Router>
        <LanguageProvider>
          <IntlProvider locale={'en'} defaultLocale="en">
            <ThemeProvider>
              <ProtocolDataProvider>
                <ConnectionStatusProvider>
                  <MenuProvider>
                    <Web3ReactProvider getLibrary={getWeb3Library}>
                      <ErrorBoundary>
                        <Web3Provider
                          defaultNetwork={getDefaultNetworkName()}
                          supportedNetworks={getSupportedNetworks()}
                          preloader={UnlockWalletPreloader}
                          connectWalletModal={ConnectWalletModal}
                        >
                          <WalletBalanceProvider>
                            <StaticPoolDataProviderWrapper>
                              <TxBuilderProvider>
                                <IncentivesDataProvider>
                                  <DynamicPoolDataProvider>
                                    <AlertProvider template={AlertTemplate} {...options}>
                                      <App />
                                    </AlertProvider>
                                  </DynamicPoolDataProvider>
                                </IncentivesDataProvider>
                              </TxBuilderProvider>
                            </StaticPoolDataProviderWrapper>
                          </WalletBalanceProvider>
                        </Web3Provider>
                      </ErrorBoundary>
                    </Web3ReactProvider>
                  </MenuProvider>
                </ConnectionStatusProvider>
              </ProtocolDataProvider>
            </ThemeProvider>
          </IntlProvider>
        </LanguageProvider>{' '}
      </Router>
    </React.StrictMode>

    <style jsx={true} global={true}>
      {globalStyle}
    </style>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
serviceWorker.unregister();
