import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .HealthFactor__value {
    @include body_24_sb;
    display: flex;
    align-items: flex-start;
  }
`;

export default staticStyles;
