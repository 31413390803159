import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .QuestionLinkWrapper {
    background-color: transparent;
    width: fit-content;
    padding-bottom: 2px;
    border-bottom-width: 2px;
    border-bottom-style: dashed;
    cursor: pointer;
    margin: 0;
    @include body_16;
    color: var(--question-link-color);
    border-bottom-color: var(--question-link-color);
  }
`;

export default staticStyles;
