import classNames from 'classnames';
import React, { ReactNode } from 'react';

import staticStyles from './style';

interface TableItemWrapperProps {
  disabled?: boolean;
  className?: string;
  children: ReactNode;
}

export default function TableItemWrapper({ disabled, className, children }: TableItemWrapperProps) {
  return (
    <div
      className={classNames(
        'TableItemWrapper',
        { TableItemWrapper__disabled: disabled },
        className
      )}
    >
      {children}

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
