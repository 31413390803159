import React from 'react';

import staticStyles from './style';
import lightIcon from '../../images/lightIcon.svg';
import darkIcon from '../../images/darkIcon.svg';
import classNames from 'classnames';
import { ThemeNames, useThemeContext } from '@sturdyfi/sturdy-ui-kit';

export default function DarkModeSwitcher() {
  const { isCurrentThemeDark, changeTheme, currentTheme } = useThemeContext();

  return (
    <label
      className={classNames('DarkModeSwitcher', { DarkModeSwitcher__active: !isCurrentThemeDark })}
    >
      <input
        type="checkbox"
        checked={isCurrentThemeDark}
        onChange={() =>
          changeTheme(currentTheme === ThemeNames.dark ? ThemeNames.default : ThemeNames.dark)
        }
      />
      <span className="DarkModeSwitcher__slider">
        <span />
        <img src={!isCurrentThemeDark ? lightIcon : darkIcon} alt="" />
      </span>
      <style jsx={true}>{staticStyles}</style>
    </label>
  );
}
