import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .BasicTableWrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    // overflow-x: auto;
    // overflow-y: hidden;
    // max-width: fit-content;
    margin: 0;
    position: relative;
    background: var(--table-background-color);

    &__container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: auto;
      // min-height: 250px;
    }

    &__content-inner {
      display: block;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    @include respond-to(sm) {
      & {
        background: none;
      }
      &__header {
        display: none;
      }
    }
  }
`;

export default staticStyles;
