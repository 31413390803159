import { createContext, ReactNode, useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import Footer from '../../Footer';
import Header from '../../Header';
import messages from './messages';

import staticStyles from './style';

export interface ScreensWrapperProps {
  children: ReactNode;
}
export const TitleContext = createContext({
  title: '',
  setTitle: (title: string) => {},
});

export function useHeaderTitle() {
  const { title, setTitle } = useContext(TitleContext);
  return { title, setTitle };
}

export const TopPanelSmallContext = createContext({
  isTopPanelSmall: false,
  setTopPanelSmall: (isSmallTopLine: boolean) => {},
});

export function useWithDesktopTitle() {
  const { isTopPanelSmall, setTopPanelSmall } = useContext(TopPanelSmallContext);
  return { isTopPanelSmall, setTopPanelSmall };
}

export const ProvideCollateralContext = createContext({
  tokenSymbol: 'ETH',
  setTokenSymbol: (token: string) => {},
});

export function useProvideCollateralContext() {
  const { tokenSymbol, setTokenSymbol } = useContext(ProvideCollateralContext);
  return { tokenSymbol, setTokenSymbol };
}

export default function ScreensWrapper({ children }: ScreensWrapperProps) {
  const intl = useIntl();
  const [title, setTitle] = useState(intl.formatMessage(messages.pageTitle));
  const [isTopPanelSmall, setTopPanelSmall] = useState(
    localStorage.getItem('isTopPanelSmall') === 'true' || false
  );
  const { networkConfig } = useProtocolDataContext();
  const [tokenSymbol, setTokenSymbol] = useState(
    localStorage.getItem('SelectedTokenSymbol') || networkConfig.baseAsset
  );

  const cacheTokenSymbol = (token: string) => {
    localStorage.setItem('SelectedTokenSymbol', token);
    setTokenSymbol(token);
  };

  return (
    <div className="ScreensWrapper">
      <Header title={title} />
      <main className="ScreensWrapper__content">
        <TitleContext.Provider value={{ title, setTitle }}>
          <TopPanelSmallContext.Provider value={{ isTopPanelSmall, setTopPanelSmall }}>
            <ProvideCollateralContext.Provider
              value={{ tokenSymbol, setTokenSymbol: cacheTokenSymbol }}
            >
              {children}
            </ProvideCollateralContext.Provider>
          </TopPanelSmallContext.Provider>
        </TitleContext.Provider>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="light"
        />
      </main>
      <Footer />
      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
