import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Your Deposits & Borrows',
  totalDeposit: 'Total deposited',
  totalLent: 'Total Lent',
  lentDescription:
    'This is the total amount you have lent, this amount will not contribute towards baing able to borrow.',
  totalCollateral: 'Total Collateral',
  collateralDescription:
    'This is the total amount you have deposited that contributes as collateral towards borrowing.',
  totalBorrowed: 'Total borrowed',
  borrowDescription: 'This is the total amount you have borrowed so far.',
  borrowPowerUsed: 'Borrow Power Used',
  powerDescription: 'This is the percentage of the borrow power you have used.',
  currentLTV: 'Current LTV',
  ltvDescription: 'This is your current loan to value percentage across all your borrows.',
});
