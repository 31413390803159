import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .MoreInformationButton {
    cursor: pointer;

    span {
      @include body_14;
      text-decoration: underline;
      color: #5677ed;
    }
  }
`;

export default staticStyles;
