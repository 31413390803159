import React from 'react';
import { useIntl } from 'react-intl';
import Value from 'src/components/basic/Value';
import ValuePercent from 'src/components/basic/ValuePercent';
import NoData from 'src/components/basic/NoData';
import InfoPanelWrapper from '../../../Wrappers/InfoPanelWrapper';

import messages from '../messages';
import staticStyles from './style';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';

interface BorrowableStatsPanelProps {
  transactionType: string;
  utilizationRate?: number | string;
  totalBorrowed?: number | string;
  availableLiquidity?: number | string;
  borrowAPY?: number | string;
  APY?: number | string;
}

export default function BorrowableStatsPanel({
  transactionType,
  utilizationRate,
  totalBorrowed,
  availableLiquidity,
  borrowAPY,
  APY,
}: BorrowableStatsPanelProps) {
  const intl = useIntl();
  const TVL = Number(totalBorrowed) + Number(availableLiquidity);

  return (
    <InfoPanelWrapper title={<span>Stats</span>} className={'BorrowableStatsPanel'}>
      <div className={'BorrowableStatsPanel__item'}>
        <div className={'BorrowableStatsPanel__item__content'}>
          <div className={'BorrowableStatsPanel__item__content__caption'}>
            <TextWithInfoWrapper
              text={intl.formatMessage(messages.reserveSize)}
              tooltipName="reserveSize"
            />
          </div>
          <div className={'BorrowableStatsPanel__item__content__value'}>
            <Value
              value={TVL.toString()}
              symbol="USD"
              withoutSymbol={true}
              tokenIcon={true}
              minimumValueDecimals={2}
              maximumValueDecimals={2}
            />
          </div>
        </div>
      </div>

      <div className={'BorrowableStatsPanel__item'}>
        <div className={'BorrowableStatsPanel__item__content'}>
          <div className={'BorrowableStatsPanel__item__content__caption'}>
            <TextWithInfoWrapper
              text={intl.formatMessage(messages.utilizationRate)}
              tooltipName="utilizationRate"
            />
          </div>
          <div className={'BorrowableStatsPanel__item__content__value'}>
            <ValuePercent value={utilizationRate?.toString() || '-'} maximumDecimals={2} />
          </div>
        </div>
      </div>

      <div className={'BorrowableStatsPanel__item'}>
        <div className={'BorrowableStatsPanel__item__content'}>
          <div className={'BorrowableStatsPanel__item__content__caption'}>
            <TextWithInfoWrapper
              text={intl.formatMessage(messages.totalBorrowed)}
              tooltipName="totalBorrowedForAsset"
            />
          </div>
          <div className={'BorrowableStatsPanel__item__content__value'}>
            {totalBorrowed ? (
              <Value
                value={totalBorrowed.toString()}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'BorrowableStatsPanel__item'}>
        <div className={'BorrowableStatsPanel__item__content'}>
          <div className={'BorrowableStatsPanel__item__content__caption'}>
            <TextWithInfoWrapper
              text={intl.formatMessage(messages.availableLiquidity)}
              tooltipName="availableLiquidity"
            />
          </div>
          <div className={'BorrowableStatsPanel__item__content__value'}>
            {availableLiquidity ? (
              <Value
                value={availableLiquidity.toString()}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      {['borrow', 'repay'].includes(transactionType) && (
        <div className={'BorrowableStatsPanel__item'}>
          <div className={'BorrowableStatsPanel__item__content'}>
            <div className={'BorrowableStatsPanel__item__content__caption'}>
              <TextWithInfoWrapper
                text={intl.formatMessage(messages.borrowAPY)}
                tooltipName="borrowAPYForAsset"
              />
            </div>
            <div className={'BorrowableStatsPanel__item__content__value'}>
              <ValuePercent value={borrowAPY?.toString() || '-'} maximumDecimals={2} />
            </div>
          </div>
        </div>
      )}

      {transactionType === 'withdraw' && (
        <div className={'BorrowableStatsPanel__item'}>
          <div className={'BorrowableStatsPanel__item__content'}>
            <div className={'BorrowableStatsPanel__item__content__caption'}>
              <TextWithInfoWrapper
                text={intl.formatMessage(messages.depositAPY)}
                tooltipName="depositAPY"
              />
            </div>
            <div className={'BorrowableStatsPanel__item__content__value'}>
              <ValuePercent value={APY?.toString() || '-'} maximumDecimals={2} />
            </div>
          </div>
        </div>
      )}

      <style jsx={true}>{staticStyles}</style>
    </InfoPanelWrapper>
  );
}
