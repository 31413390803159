import { getAssetInfo } from '@sturdyfi/sturdy-ui-kit';
import DefaultButton from 'src/components/basic/DefaultButton';
import { TxName, TxStatusType } from 'src/helpers/send-ethereum-tx';

interface TxButtonProps {
  mainTxName: TxName;
  currencySymbol: string;
  onSubmitTransaction: () => Promise<void>;
  txStatus?: TxStatusType;
  isApproveTx?: boolean;
  isApproveForDebtTx?: boolean;
  debtCurrencySymbol?: string;
  loading?: boolean;
}

export default function TxButton({
  mainTxName,
  currencySymbol,
  onSubmitTransaction,
  isApproveTx = false,
  isApproveForDebtTx = false,
  debtCurrencySymbol,
  loading,
}: TxButtonProps) {
  const generateTitle = () => {
    if (loading) {
      return 'Confirming transaction...';
    }

    const asset = getAssetInfo(currencySymbol);
    const fullName =
      !!asset.symbolsArray && asset.symbolsArray.length > 2 ? asset.name : currencySymbol;
    const approvingAssetMsg = `Allow Sturdy to use your ${fullName}`;

    if (mainTxName === 'deposit') {
      if (isApproveTx) {
        return approvingAssetMsg;
      }
      return 'Deposit';
    }
    if (mainTxName === 'withdraw') {
      if (isApproveTx) {
        return approvingAssetMsg;
      }
      return 'Withdraw';
    }
    if (mainTxName === 'borrow') {
      return 'Borrow';
    }
    if (mainTxName === 'repay') {
      if (isApproveTx) {
        return approvingAssetMsg;
      }
      return 'Repay';
    }
    if (mainTxName === 'leverage') {
      if (isApproveForDebtTx) {
        return `Allow Sturdy to borrow ${fullName} for you`;
      }
      if (isApproveTx) {
        return approvingAssetMsg;
      }
      return 'Leverage';
    }
    if (mainTxName === 'deleverage') {
      if (isApproveTx) {
        return approvingAssetMsg;
      }
      return 'Deleverage';
    }
    if (mainTxName === 'claim') {
      return 'Claim';
    }
    return 'Add title';
  };

  return (
    <DefaultButton
      title={generateTitle()}
      type="primary"
      color="blue"
      onClick={() => onSubmitTransaction()}
      disabled={loading}
    />
  );
}
