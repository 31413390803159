import { useEffect, useState } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useSwipeable } from 'react-swipeable';
import { SocialIcon, SocialIcons, SocialType, useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import { useMenuContext } from '../../../libs/menu';
import goToTop from '../../../helpers/goToTop';
import Link from '../../basic/Link';
import DropdownWrapper from '../../Wrappers/DropdownWrapper';

import BasicModal from '../../basic/BasicModal';
import { hamburgerDark, hamburgerLight, logoDark, logoLight } from 'src/images';
import DarkModeSwitcher from '../../DarkModeSwitcher';
import messages from '../messages';
import staticStyles from './style';

const navigations: { link: string; title: MessageDescriptor }[] = [
  {
    link: '/markets',
    title: messages.markets,
  },
  {
    link: '/dashboard',
    title: messages.dashboard,
  },
];

const socialIcons: {
  title: MessageDescriptor;
  info: SocialIcon;
}[] = [
  {
    title: messages.discord,
    info: {
      url: 'https://twitter.com/SturdyFinance',
      type: SocialType.Twitter,
    },
  },
  {
    title: messages.twitter,
    info: {
      url: 'https://discord.gg/tRVHp6Vx5N',
      type: SocialType.Discord,
    },
  },
  {
    title: messages.github,
    info: {
      url: 'https://github.com/sturdyfi',
      type: SocialType.Github,
    },
  },
];

interface MobileContentProps {
  isActive: (url: string) => boolean;
}

export default function MobileContent({ isActive }: MobileContentProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { md, isCurrentThemeDark } = useThemeContext();
  const { closeMobileMenu, mobileMenuVisible } = useMenuContext();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (mobileMenuVisible && !md) {
      closeMobileMenu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [md]);

  const handleLinkClick = (to: string) => {
    setVisible(false);
    navigate(to);
  };

  const handlers = useSwipeable({
    onSwipedRight: () => closeMobileMenu(),
  });

  return (
    <div className="MobileContent" {...handlers}>
      {/* <div
        className={classNames('MobileContent__overlay', {
          MobileContent__overlayActive: mobileMenuVisible,
        })}
      /> */}
      <div className="MobileContent__menuWrapper">
        <img
          src={isCurrentThemeDark ? hamburgerDark : hamburgerLight}
          alt="Sturdy"
          onClick={() => setVisible(true)}
        />
      </div>
      <BasicModal
        isVisible={visible}
        onBackdropPress={() => setVisible(false)}
        title={
          <div className="MobileContent__logoWrapper">
            <img src={isCurrentThemeDark ? logoDark : logoLight} alt="Sturdy" />
          </div>
        }
        withCloseButton={true}
        className="MobileContent__modal"
      >
        <div className="MobileContent__innerWrapper">
          <div className="MobileContent__innerWrapper-section">
            <div className="MobileContent__innerWrapper-subtitle">Menu</div>
            <div className="MobileContent__innerWrapper-contentWrapper">
              {navigations.map((nav) => (
                <span
                  key={nav.link}
                  className="MobileContent__innerWrapper-link"
                  onClick={() => handleLinkClick(nav.link)}
                >
                  {intl.formatMessage(nav.title)}
                </span>
              ))}
            </div>
          </div>
          <div className="MobileContent__innerWrapper-section">
            <div className="MobileContent__innerWrapper-subtitle">Settings</div>
            <div className="MobileContent__innerWrapper-contentWrapper">
              <div className="MobileContent__innerWrapper-contentSettings">
                <p className="MobileContent__innerWrapper-link">Dark mode</p>
                <DarkModeSwitcher />
              </div>
            </div>
          </div>
          {/* <div className="MobileContent__innerWrapper-section">
            <div className="MobileContent__innerWrapper-subtitle">Links</div>
            <div className="MobileContent__innerWrapper-contentWrapper">
              {socialIcons.map((socialIcon) => (
                <div
                  className="MobileContent__innerWrapper-contentLink"
                  key={intl.formatMessage(socialIcon.title)}
                >
                  <SocialIcons
                    icons={[{ url: socialIcon.info.url, type: socialIcon.info.type }]}
                    iconHeight={20}
                    iconWidth={20}
                  />
                  <p className="MobileContent__innerWrapper-link">
                    {intl.formatMessage(socialIcon.title)}
                  </p>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </BasicModal>

      {/* <DropdownWrapper
        className="MobileContent"
        contentClassName="MobileContent__content-wrapper"
        contentId="MobileMenuContent"
        buttonComponent={
          <span className="MobileContent__button-box">
            <span className="MobileContent__button-inner" />
          </span>
        }
      >
        <div className="MobileContent__content">
          <div className="MobileContent__navigation">
            <ul>
              {mobileNavigation.map((link, index) => (
                <li className={classNames('MobileContent__link-wrapper')} key={index}>
                  {!link.onClick ? (
                    <Link
                      className={classNames('MobileContent__link', {
                        MobileContent__linkActive: isActive(link.link),
                      })}
                      to={link.link}
                      absolute={link.absolute}
                      inNewWindow={link.absolute}
                      onClick={() => !link.absolute && handleLinkClick()}
                      color="white"
                    >
                      <span>{intl.formatMessage(link.title)}</span>
                    </Link>
                  ) : (
                    <div
                      className="MobileContent__link MobileContent__link-chat"
                      onClick={link.onClick}
                    >
                      <span>{intl.formatMessage(link.title)}</span>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </DropdownWrapper> */}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
