import { useWeb3React } from '@web3-react/core';
import { providers } from 'ethers';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import UAuth from '@uauth/js';
import { ChainId } from '@sturdyfi/sturdy-js';
import { textCenterEllipsis, useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import { useUserWalletDataContext } from 'src/libs/web3-data-provider';
import DropdownWrapper from '../Wrappers/DropdownWrapper';

import userIconLight from 'src/images/userIcon_light.svg';
import userIconDark from 'src/images/userIcon_dark.svg';
import staticStyles from './style';
import messages from './messages';
import ConnectButton from '../ConnectButton';

export default function AddressInfo() {
  const intl = useIntl();
  const { isCurrentThemeDark } = useThemeContext();
  const { chainId } = useWeb3React();
  const {
    currentAccount,
    disconnectWallet,
    displaySwitchAccountModal,
    currentProviderName,
    availableAccounts,
  } = useUserWalletDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const [userEns, setUserEns] = useState('');

  useEffect(() => {
    get_ens();
  }, [currentAccount, chainId]);

  const get_ens = async () => {
    if (!currentAccount || !provider) {
      return setUserEns('');
    }

    if (currentProviderName === 'uath-connect') {
      const clientID =
        process.env.REACT_APP_UNSTOPPABLE_DOMAIN_CLIENTID || 'e240de52-3903-4bb9-a839-3dcd6a3c0ff3';
      const redirectUri =
        process.env.REACT_APP_UNSTOPPABLE_DOMAIN_REDIRECT_URI || 'https://app.sturdy.finance/';
      const uauth = new UAuth({
        clientID,
        redirectUri,
      });
      const user = await uauth.user();
      setUserEns(user.sub);
    } else if (chainId === ChainId.mainnet) {
      var ens = (await provider.lookupAddress(currentAccount)) || '';
      setUserEns(ens);
    }
  };

  return (
    <div className="AddressInfo">
      {currentAccount ? (
        <DropdownWrapper
          horizontalPosition="right"
          verticalPosition="bottom"
          buttonComponent={
            <div className="AddressInfo__button-content">
              <div className="AddressInfo__button-text">
                <div className={'AddressInfo__buttonLogo-inner'}>
                  <img src={isCurrentThemeDark ? userIconDark : userIconLight} alt="" />
                  <span>{userEns || textCenterEllipsis(currentAccount, 4, 4)}</span>
                </div>
              </div>
            </div>
          }
        >
          <div className="AddressInfo__content">
            {(currentProviderName?.includes('ledger') || availableAccounts.length > 1) && (
              <button
                className="AddressInfo__contentButton"
                type="button"
                onClick={() => displaySwitchAccountModal(true)}
              >
                <span>{intl.formatMessage(messages.changeAddress)}</span>
              </button>
            )}

            <button
              className="AddressInfo__contentButton"
              type="button"
              onClick={() => {
                disconnectWallet();
              }}
            >
              <span>{intl.formatMessage(messages.disconnect)}</span>
            </button>
          </div>
        </DropdownWrapper>
      ) : (
        <ConnectButton />
      )}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
