import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .LeveragePanel {
    background: var(--dropdown-background-color);
    border: 1px solid var(--tabs-color);
    border-radius: 16px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 16px;
    width: inherit;
    height: fit-content;

    & > * {
      width: 100%;
    }

    &__header {
      @include body_16;
      color: var(--text2-color);
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      &__value {
        @include body_16_sb;
        color: var(--selected-color);
        background: var(--leverage-value-background-color);
        border: 1px solid var(--tabs-color);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 6px 12px;
        cursor: pointer;
        min-width: 90px;

        img {
          margin-left: 10px;
        }
      }
    }

    &__content {
      width: 100%;
      height: fit-content;
    }
  }
`;

export default staticStyles;
