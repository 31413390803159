import { useIntl } from 'react-intl';
import { isAssetStable, TokenIcon, useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import DefaultButton from 'src/components/basic/DefaultButton';
import Link from 'src/components/basic/Link';
import NoData from 'src/components/basic/NoData';
import Value from 'src/components/basic/Value';
import ValuePercent from 'src/components/basic/ValuePercent';
import { TooltipName } from 'src/components/Tooltip';

import CompactNumberWrapper from 'src/components/Wrappers/CompactNumberWrapper';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';
import TableColumnWrapper from 'src/components/Wrappers/Table/TableColumnWrapper';
import TableItemWrapper from 'src/components/Wrappers/Table/TableItemWrapper';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';
import messages from './messages';

import { LendBorrowTableItemProps } from './types';

export default function LendBorrowTableItem({
  symbol,
  underlyingAsset,
  totalLiquidityInUSD,
  totalBorrowsInUSD,
  utilizationRatio,
  userId,
  walletBalance,
  depositAPY,
  borrowAPY,
  isLoading,
}: LendBorrowTableItemProps) {
  const intl = useIntl();
  const { sm } = useThemeContext();

  const hasBalance = walletBalance?.gt(0);
  const isHighDepositAPY = Number(depositAPY) >= 0.1;
  const isZeroBorrowAPY = Number(borrowAPY) === 0;

  const renderDepositAPY = () => {
    return isHighDepositAPY ? (
      <TextWithInfoWrapper
        text={<ValuePercent value={depositAPY} />}
        tooltipName="highDepositRate"
        className="ZeroAPY"
      />
    ) : (
      <ValuePercent value={depositAPY} className="ZeroAPY" />
    );
  };

  return (
    <TableItemWrapper>
      <TableColumnWrapper minWidth={135}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={115} />
        ) : (
          <TokenIcon
            tokenSymbol={symbol}
            height={35}
            width={35}
            tokenFullName={symbol.toUpperCase()}
            className=""
          />
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.totalAndBorrowed)}
            tooltipName="totalDepositAndBorrowed"
          />
        </TableColumnWrapper>
      )}

      <TableColumnWrapper minWidth={180}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={160} />
        ) : (
          <>
            $
            <CompactNumberWrapper value={Number(totalLiquidityInUSD)} maximumFractionDigits={2} />
            &nbsp;/ $
            <CompactNumberWrapper value={Number(totalBorrowsInUSD)} maximumFractionDigits={2} />
          </>
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.utilRate)}
            tooltipName="utilizationRate"
          />
        </TableColumnWrapper>
      )}

      <TableColumnWrapper>
        {isLoading ? (
          <SkeletonWrapper height={50} width={130} />
        ) : (
          <ValuePercent value={utilizationRatio} />
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.walletBalance)}
            tooltipName="walletBalance"
          />
        </TableColumnWrapper>
      )}

      <TableColumnWrapper>
        {isLoading ? (
          <SkeletonWrapper height={50} width={130} />
        ) : !userId || !hasBalance ? (
          <NoData />
        ) : (
          <Value
            value={walletBalance.toString()}
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
          />
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.depositAPY)}
            tooltipName="depositAPY"
          />
        </TableColumnWrapper>
      )}

      <TableColumnWrapper>
        {isLoading ? <SkeletonWrapper height={50} width={130} /> : renderDepositAPY()}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.borrowAPY)}
            tooltipName="borrowAPYForAsset"
          />
        </TableColumnWrapper>
      )}
      <TableColumnWrapper>
        {isLoading ? (
          <SkeletonWrapper height={50} width={130} />
        ) : isZeroBorrowAPY ? (
          <TextWithInfoWrapper
            text={<ValuePercent value={borrowAPY} />}
            className={'ZeroAPY'}
            tooltipName={'zeroBorrowAPY'}
          />
        ) : (
          <ValuePercent value={borrowAPY} className="BorrowAPY" />
        )}
      </TableColumnWrapper>

      <TableColumnWrapper className={'ButtonColumn'} minWidth={230}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={210} />
        ) : (
          <>
            <Link to={`/lend/deposit/${underlyingAsset}`}>
              <DefaultButton title={'Lend'} type="secondary" color="blue" />
            </Link>
            <Link to={`/borrow/borrow/${underlyingAsset}`}>
              <DefaultButton title={'Borrow'} type="secondary" color="gray" />
            </Link>
          </>
        )}
      </TableColumnWrapper>
    </TableItemWrapper>
  );
}
