import { useIntl } from 'react-intl';
import messages from '../messages';
import RiskBar from './RiskBar';

export default function HealthFactor() {
  const intl = useIntl();

  const renderContent = () => {
    return (
      <div className="Tooltip__content">
        <p>{intl.formatMessage(messages.healthFactorDesc1)}</p>
        <RiskBar />
        <p>{intl.formatMessage(messages.healthFactorDesc2)}</p>
      </div>
    );
  };

  return (
    <>
      <div className="Tooltip__title">{intl.formatMessage(messages.healthFactor)}</div>
      {renderContent()}
    </>
  );
}
