import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .PanelWrapper {
    border: 1px solid var(--tabs-color);
    border-radius: 16px;
    background: var(--marketcad-background-color);
    box-shadow: var(--table-box-shadow);
    display: flex;
    width: fit-content;
    height: fit-content;
    color: var(--selected-color);

    &__content {
      width: 100%;
      position: relative;
    }

    @include respond-to(sm) {
      border: none;
      background: none;
      box-shadow: none;
    }
  }
`;

export default staticStyles;
