import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .AuditButton {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 12px;
    padding: 6px 16px;
    background: var(--audit-background-color);
    border: 1px solid var(--tabs-color);
    box-shadow: var(--box-shadow);
    backdrop-filter: blur(4px);
    border-radius: 16px;

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__text {
        @include body_16_sb;
        color: var(--selected-color);
      }

      &__text-sub {
        @include body_14;
        color: var(--text2-color);
      }
    }
  }
`;

export default staticStyles;
