import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .CollateralWithdrawOverviewPanel {
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        &__value {
          color: var(--selected-color);
          display: flex;

          &__healthFactor {
            @include body_16_sb;
            color: var(--error2-color);
          }
        }

        @include respond-to(sm) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;
        }
      }

      &__sub {
        @include body_14;
        align-self: flex-end;
        color: var(--text2-color);

        @include respond-to(sm) {
          align-self: flex-start;
        }
      }
    }
  }
`;

export default staticStyles;
