import React from 'react';
import { useIntl } from 'react-intl';

import {
  AvailableWeb3Connectors,
  ConnectWalletModalProps,
  getWeb3ProviderFromBrowser,
} from '../../libs/web3-data-provider';

import WarningArea from '../WarningArea';
import UnlockWalletWrapper from './components/ConnectWalletWrapper';
import WalletCard from './components/WalletCard';
import LedgerChecklist from './components/LedgerChecklist';
import { UnlockWalletExtraText } from '../../ui-config';

import messages from './messages';
import staticStyles from './style';

import * as icons from './images';

export interface Wallet {
  title: string;
  description?: string;
  providerName: AvailableWeb3Connectors;
  icon: string;
  disabled?: boolean;
  notSupported?: boolean;
  errorMessage?: string;
}

export default function ConnectWalletModal({
  preferredNetwork,
  supportedNetworks,
  onUnlockExternalWallet,
  connectorConfig,
  error,
  showLedgerBanner,
  isVisible,
  onBackdropPress,
}: ConnectWalletModalProps) {
  const intl = useIntl();
  const browserWalletProvider = getWeb3ProviderFromBrowser();

  const handleUnlockExternalWallet = (providerName: AvailableWeb3Connectors) =>
    onUnlockExternalWallet(
      providerName,
      preferredNetwork,
      supportedNetworks,
      connectorConfig,
      false
    );

  // @ts-ignore
  const isImToken = !!window.imToken;

  const wallets: Wallet[] = [
    {
      title: intl.formatMessage(messages.titleBrowserWallet, {
        walletName: isImToken ? 'imToken' : 'Browser',
      }),
      description: '(MetaMask, Trustwallet, Enjin)',
      providerName: 'browser',
      icon: isImToken ? icons.imToken : icons.browserWallets,
      disabled: !browserWalletProvider,
      errorMessage: intl.formatMessage(messages.noBrowserBrowserWallet),
    },
    {
      title: 'Trust Wallet',
      providerName: 'wallet-connect',
      icon: icons.trustWalletIcon,
    },
    {
      title: 'Unstoppable Domains',
      providerName: 'uath-connect',
      icon: icons.unstoppableDomainsIcon,
    },
    {
      title: 'Coinbase Wallet',
      providerName: 'wallet-link',
      icon: icons.coinbaseIcon,
    },
    // {
    //   title: 'Authereum',
    //   providerName: 'authereum',
    //   icon: icons.authereumIcon,
    //   notSupported: !AUTHEREUM_API_KEY || preferredNetwork !== Network.mainnet,
    // },
    {
      title: 'Metamask',
      providerName: 'browser',
      icon: icons.metamaskIcon,
      disabled: !browserWalletProvider,
      errorMessage: intl.formatMessage(messages.noBrowserBrowserWallet),
    },
    {
      title: 'Wallet Connect',
      providerName: 'wallet-connect',
      icon: icons.walletConnectIcon,
    },
    // {
    //   title: 'Torus',
    //   providerName: 'torus',
    //   icon: icons.torusIcon,
    //   notSupported: preferredNetwork === Network.avalanche,
    // },
    // {
    //   title: 'Fortmatic',
    //   providerName: 'fortmatic',
    //   icon: icons.formaticIcon,
    //   notSupported:
    //     !getFortmaticKeyByNetwork(preferredNetwork) ||
    //     preferredNetwork === Network.polygon ||
    //     preferredNetwork === Network.avalanche,
    // },
    // {
    //   title: 'imToken',
    //   providerName: 'wallet-connect',
    //   icon: icons.imToken,
    //   notSupported:
    //     isImToken || preferredNetwork === Network.polygon || preferredNetwork === Network.avalanche,
    // },
  ];

  return (
    <UnlockWalletWrapper
      isVisible={isVisible}
      onBackdropPress={onBackdropPress}
      className="ConnectWalletModal"
    >
      {error && (
        <WarningArea
          className="ConnectWalletModal__warningArea ConnectWalletModal__warningArea-mobile"
          title={intl.formatMessage(messages.errorCaption)}
        >
          <p>{error}</p>
        </WarningArea>
      )}

      {(error?.includes('Ledger') || showLedgerBanner) && (
        <LedgerChecklist className="ConnectWalletModal__LedgerChecklist-mobile" />
      )}

      <div className="ConnectWalletModal__content">
        {wallets
          .filter((wallet) => !wallet.notSupported)
          .map((wallet, index) => (
            <WalletCard
              title={wallet.title}
              description={wallet.description}
              errorMessage={
                wallet.providerName === 'browser' && !browserWalletProvider
                  ? wallet.errorMessage
                  : ''
              }
              providerName={wallet.providerName}
              icon={wallet.icon}
              disabled={wallet.disabled}
              handleUnlockExternalWallet={handleUnlockExternalWallet}
              key={index}
            />
          ))}
      </div>

      {error && (
        <WarningArea
          className="ConnectWalletModal__warningArea"
          title={intl.formatMessage(messages.errorCaption)}
        >
          <p>{error}</p>
        </WarningArea>
      )}

      {(error?.includes('Ledger') || showLedgerBanner) && (
        <LedgerChecklist className="ConnectWalletModal__warningArea" />
      )}

      <div className="ConnectWalletModal__privacy-inner">
        <p>
          <UnlockWalletExtraText intl={intl} />
        </p>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </UnlockWalletWrapper>
  );
}
