import { ethers } from 'ethers';
import React, { ReactNode, useContext } from 'react';
import Preloader from '../../../components/basic/Preloader';
import ErrorPage from '../../../components/ErrorPage';
import { useConnectionStatusContext } from '../../connection-status-provider';
import { useProtocolDataContext } from '../../protocol-data-provider';
import { useTxBuilderContext } from '../../tx-provider';
import { useStaticPoolDataContext } from '../providers/static-pool-data-provider';
import {
  IncentiveDataResponse,
  ReserveRewardData,
  useIncentivesData,
  UserReserveRewardData,
} from './use-incentives-data';
import { VariableYieldDistributionInterface } from '@sturdyfi/sturdy-js/dist/tx-builder/services/VariableYieldDistribution';

export interface IncentivesContext {
  reserveReward: ReserveRewardData[];
  userReward?: UserReserveRewardData[];
  variableRewardsTxBuilder: VariableYieldDistributionInterface;
  refresh: () => void;
}

const IncentivesDataContext = React.createContext({} as IncentivesContext);

export function IncentivesDataProvider({ children }: { children: ReactNode }) {
  const { userId } = useStaticPoolDataContext();
  const { network, networkConfig, currentMarketData } = useProtocolDataContext();
  const { isRPCActive } = useConnectionStatusContext();
  const currentAccount = userId ? userId.toLowerCase() : ethers.constants.AddressZero;
  const { variableYieldDistribution: variableRewardsTxBuilder } = useTxBuilderContext();

  const {
    data: rpcData,
    loading: rpcDataLoading,
    error: rpcDataError,
    refresh,
  }: IncentiveDataResponse = useIncentivesData(
    currentMarketData.addresses.LENDING_POOL_ADDRESS_PROVIDER,
    network,
    networkConfig.addresses.uiIncentiveDataProvider,
    !isRPCActive,
    currentAccount
  );

  const activeData = isRPCActive && rpcData ? rpcData : undefined;

  // if ((isRPCActive && rpcDataLoading) || !isRPCActive) {
  //   return <Preloader withBackground={true} />;
  // }

  if (!activeData || (isRPCActive && rpcDataError) || !isRPCActive) {
    return <ErrorPage />;
  }

  return (
    <IncentivesDataContext.Provider
      value={{
        reserveReward: activeData?.reserveRewardData || [],
        userReward: activeData?.userRewardData || [],
        variableRewardsTxBuilder,
        refresh: isRPCActive ? refresh : async () => {},
      }}
    >
      {children}
    </IncentivesDataContext.Provider>
  );
}

export const useIncentivesDataContext = () => useContext(IncentivesDataContext);
