import { useState } from 'react';
import { useIntl } from 'react-intl';
import DepositingHelpModal from 'src/components/HelpModal/DepositingHelpModal';

import messages from '../messages';
import MoreInformationButton from 'src/components/basic/MoreInformationButton';

export default function Depositing() {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);

  const getClsName = (str: string) => {
    if (str === '\\B') {
      return 'Tooltip__Bold';
    }
    if (str === '\\Y') {
      return 'Tooltip__Yellow';
    }
    if (str === '\\G') {
      return 'Tooltip__Green';
    }
    return '';
  };

  const splitStr = (sentence: string) => {
    const words = sentence.split(/<>/);

    return (
      <>
        {words.map((word, id) => {
          const chars = word.slice(0, 2);
          const clsName = getClsName(chars);
          return (
            <span className={clsName} key={id}>
              {clsName ? word.slice(2) : word}
            </span>
          );
        })}
      </>
    );
  };

  const renderContent = () => {
    const description = intl.formatMessage(messages.depositingDesc);
    const smallParts = description.split('\n');

    return (
      <div className="Tooltip__content">
        {smallParts.map((str, id) => (
          <p key={id}>{splitStr(str)}</p>
        ))}
        <MoreInformationButton title="Learn more" onClick={() => setVisible(true)} />
        <DepositingHelpModal isVisible={visible} onBackdropPress={() => setVisible(false)} />
      </div>
    );
  };

  return (
    <>
      <div className="Tooltip__title">{intl.formatMessage(messages.depositing)}</div>
      {renderContent()}
    </>
  );
}
