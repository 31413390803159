import classNames from 'classnames';

import staticStyles from './style';
import icon from 'src/images/icon.svg';

interface BackButtonProps {
  onClick?: () => void;
  className?: string;
}

export default function BackButton({ onClick, className }: BackButtonProps) {
  return (
    <div className={classNames('BackButton', className)} onClick={onClick}>
      <img src={icon} alt="Back Button" />
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
