import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ActionSwitcher from '../../../../components/ActionSwitcher';
import PanelWrapper from '../../../../components/Wrappers/PanelWrapper';

import staticStyles from './style';
import { ActionButtonDataType } from '../../../../components/ActionSwitcher/types';
import BackButton from '../../../../components/basic/BackButton';
import LendMain from '../LendMain';
import WithdrawMain from '../WithdrawMain';
import { ValidationWrapperComponentProps } from 'src/components/RouteParamsValidationWrapper';

const actionButtons: ActionButtonDataType[] = [
  { action: 'deposit', title: 'Deposit' },
  { action: 'withdraw', title: 'Withdraw' },
];

interface LendCurrencyWrapperProps extends ValidationWrapperComponentProps {
  className?: string;
}

export default function LendCurrencyWrapper(props: LendCurrencyWrapperProps) {
  const { action, currencySymbol, className } = props;
  const [selectedAction, setSelectedAction] = useState('deposit');
  const navigate = useNavigate();

  useEffect(() => {
    let newAction = action;
    if (!newAction) {
      newAction = 'deposit';
    }
    setSelectedAction(newAction);
  }, [action]);

  const handleActionChange = (newAction: string) => {
    setSelectedAction(newAction);
  };

  const isDeposit = selectedAction === 'deposit';
  const isWithdraw = selectedAction === 'withdraw';

  return (
    <PanelWrapper className={classNames('LendCurrencyWrapper', className)}>
      <div className="LendCurrencyWrapper__title">
        <BackButton onClick={() => navigate(-1)} />
        <p className="LendCurrencyWrapper__title-caption">{currencySymbol.toUpperCase()}</p>
      </div>
      <div className="LendCurrencyWrapper__separate-line"></div>
      <div className="LendCurrencyWrapper__content">
        <ActionSwitcher
          buttons={actionButtons}
          selectedAction={selectedAction}
          onActionChange={handleActionChange}
        />

        {isDeposit && <LendMain {...props} />}
        {isWithdraw && <WithdrawMain {...props} />}
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </PanelWrapper>
  );
}
