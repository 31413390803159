import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .MobileContent {
    &__menuWrapper {
      display: flex;
      align-items: center;
    }
    &__modal {
      width: 100% !important;

      .BasicModal__title {
        justify-content: flex-start;
      }
      .BasicModal__content {
        padding: 0px 24px;
      }
    }
    &__logoWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 24px;
      width: 100%;
      height: 100%;
      background: var(--panel-background-color);

      img {
        width: 100px;
        height: 26px;
      }
    }

    &__innerWrapper {
      color: var(--mobile-menu-color);
      &-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
        padding: 24px 0px;

        border-bottom: 1px solid #edeff3;
        :last-child {
          border-bottom: none;
        }
      }
      &-contentWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 24px;
      }
      &-contentSettings {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      &-contentLink {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 8px;

        a {
          height: 20px;
        }
      }
      &-subtitle {
        @include body_16;
      }
      &-link {
        @include body_18_sb;
      }
    }
  }
`;

export default staticStyles;
