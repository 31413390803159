import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Reward {
    display: flex;
    flex-direction: row !important;
    justify-content: center;

    @include respond-to(sm) {
      margin-top: 0 !important;
    }

    & > * {
      flex: 1;
      max-width: 488px;
      margin: 12px;
    }
  }
`;

export default staticStyles;
