import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ZapAssetTable {
    box-sizing: border-box;
    border: 1px solid var(--tabs-color);
    // backdrop-filter: blur(4px);
    border-radius: 16px;
    padding: 24px;

    .TableHeaderWrapper {
      width: auto;

      &__title {
        margin: 7px 0 0 0;
      }

      .TableColumnWrapper {
        @include body_14;
        color: var(--text2-color);
        & > * {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
      }
    }

    .TableItemWrapper {
      @include body_14;
      color: var(--selected-color);
      width: auto;
      background: var(--dropdown-background-color);
      border: 1px solid var(--tabs-color);
      box-shadow: var(--box-shadow);
      // backdrop-filter: blur(4px);
      border-radius: 16px;
      margin-top: 24px;
      padding: 24px 16px 24px 16px;

      .TokenIcon__name {
        margin: 0;

        b {
          @include body_18_sb;
          color: var(--selected-color);
        }
      }

      .TableColumnWrapper {
        p {
          margin: 0;
        }
      }

      .ButtonColumn {
        flex-direction: row;
        justify-content: center;
        gap: 15px;

        & > * {
          flex: 1;
        }
      }
    }
  }
`;

export default staticStyles;
