import React from 'react';
import classNames from 'classnames';

import staticStyles from './style';
import LinkWrapper from '../Wrappers/LinkWrapper';

interface MenuLinkProps {
  to: string;
  title: string;
  isActive: boolean;
  hidden?: boolean;
}

export default function MenuLink({ to, title, isActive, hidden }: MenuLinkProps) {
  return (
    <LinkWrapper
      to={to}
      className={classNames('MenuLink', {
        MenuLink__active: isActive,
        MenuLink__hidden: hidden,
      })}
      onClick={() => {}}
    >
      <div className="MenuLink__title">
        <p>
          <b>{title}</b> <strong>{title}</strong>
        </p>{' '}
        <i />
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </LinkWrapper>
  );
}
