import classNames from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAssetInfo } from '@sturdyfi/sturdy-ui-kit';

import ActionSwitcher from 'src/components/ActionSwitcher';
import { ActionButtonDataType } from 'src/components/ActionSwitcher/types';
import { ValidationWrapperComponentProps } from 'src/components/RouteParamsValidationWrapper';
import PanelWrapper from 'src/components/Wrappers/PanelWrapper';
import BackButton from 'src/components/basic/BackButton';

import WithdrawMain from '../WithdrawMain';

import staticStyles from './style';
import { CollateralReserveMarketData } from '@sturdyfi/sturdy-js';
import DeleverageMain from '../DeleverageMain';

interface WithdrawCollateralWrapperProps extends ValidationWrapperComponentProps {
  className?: string;
}
export default function WithdrawCollateralWrapper(props: WithdrawCollateralWrapperProps) {
  const { action, currencySymbol, poolReserve, className } = props;

  const actionButtons: ActionButtonDataType[] = useMemo(() => {
    const reserve = poolReserve as CollateralReserveMarketData;
    return [
      { action: 'withdraw', title: 'Withdraw' },
      { action: 'deleverage', title: 'Deleverage', disabled: reserve.leverageEnabled !== true },
    ];
  }, [currencySymbol]);

  const [selectedAction, setSelectedAction] = useState('withdraw');
  const navigate = useNavigate();

  useEffect(() => {
    let newAction = action;
    if (!newAction) {
      newAction = 'withdraw';
    }
    setSelectedAction(newAction);
  }, [action]);

  const handleActionChange = (newAction: string) => {
    setSelectedAction(newAction);
  };

  const asset = getAssetInfo(currencySymbol);
  const title = asset.name;

  return (
    <PanelWrapper className={classNames('WithdrawCollateralWrapper', className)}>
      <div className="WithdrawCollateralWrapper__title">
        <BackButton onClick={() => navigate(-1)} />
        <p className="WithdrawCollateralWrapper__title-caption">{title}</p>
      </div>
      <div className="WithdrawCollateralWrapper__separate-line"></div>
      <div className="WithdrawCollateralWrapper__content">
        <ActionSwitcher
          buttons={actionButtons}
          selectedAction={selectedAction}
          onActionChange={handleActionChange}
        />
        {selectedAction === 'withdraw' && <WithdrawMain {...props} />}
        {selectedAction === 'deleverage' && <DeleverageMain {...props} />}
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </PanelWrapper>
  );
}
