import ActionButton from './ActionButton';
import staticStyles from './style';
import { ActionButtonDataType } from './types';

interface ActionSwitcherProps {
  buttons: ActionButtonDataType[];
  selectedAction: string;
  onActionChange: (action: string) => void;
}

export default function ActionSwitcher({
  selectedAction,
  buttons,
  onActionChange,
}: ActionSwitcherProps) {
  const handleActionChange = (action: string) => {
    if (selectedAction !== action) {
      onActionChange(action);
    }
  };

  return (
    <div className="ActionSwitcher">
      {buttons.map((button, index) => (
        <ActionButton
          key={index}
          title={button.title}
          active={button.action === selectedAction}
          disabled={button.disabled}
          onClick={() => handleActionChange(button.action)}
        />
      ))}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
