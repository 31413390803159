import React, { ReactElement, ReactNode, useEffect } from 'react';
import Modal from 'react-modal';
import classNames from 'classnames';

import staticStyles from './style';

import closeButton from 'src/images/closeIcon.svg';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

export interface BasicModalProps {
  isVisible: boolean;
  onBackdropPress: () => void;
  title: string | ReactNode;
  children: ReactNode;
  withCloseButton?: boolean;
  className?: string;
  closeIcon?: string;
}

export default function BasicModal({
  isVisible,
  onBackdropPress,
  title,
  children,
  withCloseButton,
  className,
  closeIcon,
}: BasicModalProps) {
  const { isCurrentThemeDark } = useThemeContext();
  useEffect(() => {
    if (isVisible) {
      window.onpopstate = () => {
        onBackdropPress();
      };
    } else {
      window.onpopstate = () => {};
    }

    return () => {
      window.onpopstate = () => {};
    };
  }, [isVisible]);

  return (
    <Modal
      className={classNames({ BasicModal__dark: isCurrentThemeDark }, className)}
      isOpen={isVisible}
      onRequestClose={(e: React.MouseEvent) => {
        e.stopPropagation();
        onBackdropPress();
      }}
      ariaHideApp={false}
    >
      <div className="BasicModal__title">
        {typeof title === 'string' ? <span>{title}</span> : title}
      </div>
      {withCloseButton && (
        <div className="BasicModal__close-wrapper">
          <button
            className="BasicModal__close"
            type="button"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              onBackdropPress();
            }}
          >
            <img src={closeIcon || closeButton} alt="Close modal" />
          </button>
        </div>
      )}
      <div className="BasicModal__content">{children}</div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </Modal>
  );
}
