import classNames from 'classnames';
import React from 'react';
import { FormattedNumber } from 'react-intl';
import ValuePercent from 'src/components/basic/ValuePercent';

import staticStyles from './style';

interface ValueBoxWrapperProps {
  className?: string;
  title: string;
  value?: number | string;
  type: 'price' | 'percent';
}

export default function ValueBoxWrapper({ className, title, value, type }: ValueBoxWrapperProps) {
  return (
    <div className={classNames('ValueBoxWrapper', className)}>
      {`${title}:`}&nbsp;
      {type === 'price' ? (
        <div>
          <span>$&nbsp;</span>
          {value ? <FormattedNumber value={Number(value)} maximumFractionDigits={2} /> : '-'}
        </div>
      ) : (
        <ValuePercent value={value?.toString() || '-'} />
      )}
      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
