import classNames from 'classnames';

import NoData from '../../basic/NoData';
import SpinLoader from '../../basic/SpinLoader';
import Value from '../../basic/Value';
import InfoPanelWrapper from '../../Wrappers/InfoPanelWrapper';
import TextWithInfoWrapper from '../../Wrappers/TextWithInfoWrapper';

import { OverviewDataType } from '../types';
import rightArrow from 'src/images/right_arrow.svg';
import staticStyles from './style';

interface BorrowOverviewPanelProps
  extends Pick<
    OverviewDataType,
    'healthFactor' | 'healthFactorAfter' | 'gasEstimation' | 'loadingGasEstimation'
  > {}

export default function BorrowOverviewPanel({
  healthFactor,
  healthFactorAfter,
  gasEstimation,
  loadingGasEstimation,
}: BorrowOverviewPanelProps) {
  return (
    <InfoPanelWrapper title={<span>Transaction overview</span>} className={'BorrowOverviewPanel'}>
      <div className={'BorrowOverviewPanel__item'}>
        <div className={'BorrowOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Health Factor'} tooltipName="healthFactor" />
          {healthFactorAfter ? (
            <div className={'BorrowOverviewPanel__item__content__value__after'}>
              <div
                className={classNames(
                  {
                    BorrowOverviewPanel__item__content__value__healthFactor:
                      Number(healthFactor) < 2,
                  },
                  {
                    BorrowOverviewPanel__item__content__value__healthFactorSafe:
                      Number(healthFactor) >= 2,
                  }
                )}
              >
                <Value value={healthFactor || '-'} maximumValueDecimals={2} />
              </div>
              <img src={rightArrow} alt="" />
              <div
                className={classNames(
                  {
                    BorrowOverviewPanel__item__content__value__healthFactor:
                      Number(healthFactorAfter) < 2,
                  },
                  {
                    BorrowOverviewPanel__item__content__value__healthFactorSafe:
                      Number(healthFactorAfter) >= 2,
                  }
                )}
              >
                <Value value={healthFactorAfter || '-'} maximumValueDecimals={2} />
              </div>
            </div>
          ) : (
            <div
              className={classNames(
                {
                  BorrowOverviewPanel__item__content__value__healthFactor: Number(healthFactor) < 2,
                },
                {
                  BorrowOverviewPanel__item__content__value__healthFactorSafe:
                    Number(healthFactor) >= 2,
                }
              )}
            >
              <Value value={healthFactor || '-'} maximumValueDecimals={2} />
            </div>
          )}
        </div>
        <div className={'BorrowOverviewPanel__item__sub'}>{'Liquidation at < 1.0'}</div>
      </div>

      <div className={'BorrowOverviewPanel__item'}>
        <div className={'BorrowOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Gas Fee'} tooltipName="gasFee" />
          <div className={'BorrowOverviewPanel__item__content__value'}>
            {loadingGasEstimation ? (
              <SpinLoader color="black" />
            ) : gasEstimation ? (
              <Value
                value={gasEstimation}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <style jsx={true}>{staticStyles}</style>
    </InfoPanelWrapper>
  );
}
