import { TokenIcon } from '@sturdyfi/sturdy-ui-kit';
import classNames from 'classnames';

import NoData from 'src/components/basic/NoData';
import Value from 'src/components/basic/Value';
import DropdownWrapper from 'src/components/Wrappers/DropdownWrapper';
import staticStyles from './style';

export type BorrowPositionType = {
  symbol: string;
  borrowAmount: string;
  underlyingAsset: string;
  debtToken: string;
  availableLiquidity: string;
  priceInEth: string;
  totalLiquidity: string;
};

interface BorrowPositionProps {
  selectedSymbol: string;
  data: BorrowPositionType[];
  onChangeSymbol?: (value: string) => void;
}

export default function BorrowPosition({
  selectedSymbol,
  data,
  onChangeSymbol,
}: BorrowPositionProps) {
  const selectedData = selectedSymbol
    ? data.find((item) => item.symbol === selectedSymbol)
    : data[0];

  const onSelectAsset = (symbol: string) => {
    onChangeSymbol && onChangeSymbol(symbol);
  };

  return (
    <DropdownWrapper
      horizontalPosition="right"
      verticalPosition="bottom"
      className="BorrowPositionWrapper"
      buttonComponent={
        <div className="BorrowPositionWrapper__button-content">
          <TokenIcon
            tokenSymbol={selectedData?.symbol || ''}
            height={24}
            width={24}
            withTokenSymbol={true}
            className=""
          />
          {selectedData?.borrowAmount ? (
            <Value value={selectedData?.borrowAmount} maximumValueDecimals={2} />
          ) : (
            <NoData />
          )}
          <span>&nbsp;{selectedData?.symbol.toUpperCase()}</span>
        </div>
      }
    >
      <div className="BorrowPositionWrapper__content">
        {data?.map((item, id) => {
          return (
            <button
              onClick={(e) => onSelectAsset(item.symbol)}
              className={classNames('BorrowPositionWrapper__item', {
                BorrowPositionWrapper__assetActive: item.symbol === selectedSymbol,
              })}
              type="button"
              key={id}
            >
              <div className="BorrowPositionWrapper__item__content">
                <TokenIcon
                  tokenSymbol={item.symbol}
                  height={24}
                  width={24}
                  withTokenSymbol={true}
                  className=""
                />
                <Value value={item.borrowAmount} maximumValueDecimals={2} />
                <span>&nbsp;{item.symbol.toUpperCase()}</span>
              </div>
            </button>
          );
        })}
      </div>
      <style jsx={true}>{staticStyles}</style>
    </DropdownWrapper>
  );
}
