import { defineMessages } from 'react-intl';

export default defineMessages({
  asset: 'Assets',
  balance: 'Balance',
  apy: 'APY',
  avgAPY: 'Avg. APY',
  lend: 'Lend',
  withdraw: 'Withdraw',
});
