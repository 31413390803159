import classNames from 'classnames';
import React, { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';

import downArrowLight from 'src/images/downArrow_light.svg';
import downArrowDark from 'src/images/downArrow_dark.svg';

import staticStyles from './style';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

interface DropdownWrapperProps {
  className?: string;
  contentClassName?: string;
  buttonComponent: ReactElement;
  children?: ReactNode;
  verticalPosition?: 'bottom' | 'top';
  horizontalPosition?: 'left' | 'right' | 'center';
  contentId?: string;
}

export default function DropdownWrapper({
  className,
  contentClassName,
  buttonComponent,
  children,
  verticalPosition,
  horizontalPosition,
  contentId,
}: DropdownWrapperProps) {
  const { isCurrentThemeDark } = useThemeContext();
  const wrapperRef = useRef(null);

  const downArrow = isCurrentThemeDark ? downArrowDark : downArrowLight;
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    setVisible(!visible);
  };

  const handleClickOutside = (event: MouseEvent) => {
    // @ts-ignore
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={classNames(
        'DropdownWrapper',
        className,
        `DropdownWrapper__${verticalPosition}`,
        `DropdownWrapper__${horizontalPosition}`
      )}
      ref={wrapperRef}
    >
      <button className="DropdownWrapper__button" onClick={toggleVisible} type="button">
        <div className="DropdownWrapper__button-content">{buttonComponent}</div>
        <div className="DropdownWrapper__button-outlinedLogo">
          <img src={downArrow} alt="Sturdy" />
        </div>
      </button>

      <div
        className={classNames(
          'DropdownWrapper__content',
          { DropdownWrapper__contentVisible: visible },
          { contentClassName }
        )}
        id={contentId}
      >
        {children}
      </div>
      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
