import { useIntl } from 'react-intl';

import Value from 'src/components/basic/Value';
import ValuePercent from 'src/components/basic/ValuePercent';
import DropdownWrapper from 'src/components/Wrappers/DropdownWrapper';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';

import { DashboardSummaryDataType } from '../type';
import messages from './messages';
import staticStyles from './style';

interface SummaryCardProps {
  summaryData: DashboardSummaryDataType | undefined;
  isLoading: boolean;
}

export default function SummaryCard({ summaryData, isLoading }: SummaryCardProps) {
  const intl = useIntl();

  if (!summaryData) {
    return <></>;
  }

  const totalDeposit = summaryData.totalLent.plus(summaryData.totalCollateral);

  return (
    <>
      <div className="DashboardCard__title">
        <span>{intl.formatMessage(messages.title)}</span>
      </div>
      <div className="DashboardCard__content">
        <div className="Summary__section">
          <div className="Summary__subtitle">
            <DropdownWrapper
              className="TotalDeposited"
              verticalPosition="bottom"
              horizontalPosition="left"
              buttonComponent={
                <div className="TotalDeposited__button-text">
                  <div className={'TotalDeposited__buttonLogo-inner'}>
                    {intl.formatMessage(messages.totalDeposit)}
                  </div>
                </div>
              }
            >
              <div className="TotalDeposited__content">
                <div className="TotalDeposited__section">
                  <p className="TotalDeposited__title">
                    {intl.formatMessage(messages.totalDeposit)}
                  </p>
                </div>
                <div className="TotalDeposited__section">
                  <div className="TotalDeposited__subtitle">
                    <TextWithInfoWrapper
                      text={intl.formatMessage(messages.totalLent)}
                      tooltipName="totalLent"
                    />
                  </div>
                  <div className="TotalDeposited__value">
                    {isLoading ? (
                      <SkeletonWrapper width={80} height={20} />
                    ) : (
                      <Value
                        value={summaryData.totalLent.toString()}
                        symbol="USD"
                        withoutSymbol={true}
                        tokenIcon={true}
                        minimumValueDecimals={2}
                        maximumValueDecimals={2}
                      />
                    )}
                  </div>
                </div>
                <div className="TotalDeposited__section">
                  <div className="TotalDeposited__subtitle">
                    <TextWithInfoWrapper
                      text={intl.formatMessage(messages.totalCollateral)}
                      tooltipName="totalCollateral"
                    />
                  </div>
                  <div className="TotalDeposited__value">
                    {isLoading ? (
                      <SkeletonWrapper width={80} height={20} />
                    ) : (
                      <Value
                        value={summaryData.totalCollateral.toString()}
                        symbol="USD"
                        withoutSymbol={true}
                        tokenIcon={true}
                        minimumValueDecimals={2}
                        maximumValueDecimals={2}
                      />
                    )}
                  </div>
                </div>
              </div>
            </DropdownWrapper>
          </div>
          <div className="Summary__value">
            {isLoading ? (
              <SkeletonWrapper width={80} height={20} />
            ) : (
              <Value
                value={totalDeposit.toString()}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            )}
          </div>
        </div>
        {/* <div className="Summary__section"> */}

        {/* </div> */}
        <div className="Summary__section">
          <div className="Summary__subtitle">
            <TextWithInfoWrapper
              text={intl.formatMessage(messages.totalBorrowed)}
              tooltipName="totalBorrowed"
            />
          </div>
          <div className="Summary__value">
            {isLoading ? (
              <SkeletonWrapper width={80} height={20} />
            ) : (
              <Value
                value={summaryData.totalBorrowed.toString()}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            )}
          </div>
        </div>
        <div className="Summary__section">
          <div className="Summary__subtitle">
            <TextWithInfoWrapper
              text={intl.formatMessage(messages.borrowPowerUsed)}
              tooltipName="borrowPowerUsed"
            />
          </div>
          <div className="Summary__value">
            {isLoading ? (
              <SkeletonWrapper width={80} height={20} />
            ) : (
              <ValuePercent value={summaryData.borrowPowerUsed} />
            )}
          </div>
        </div>
        <div className="Summary__section">
          <div className="Summary__subtitle">
            <TextWithInfoWrapper
              text={intl.formatMessage(messages.currentLTV)}
              tooltipName="currentLTV"
            />
          </div>
          <div className="Summary__value">
            {isLoading ? (
              <SkeletonWrapper width={80} height={20} />
            ) : (
              <ValuePercent value={summaryData.currentLTV} />
            )}
          </div>
        </div>
      </div>
      <style jsx={true}>{staticStyles}</style>
    </>
  );
}
