import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ValueBoxWrapper {
    @include body_14;
    color: var(--selected-color);
    display: flex;
    padding: 6px 12px;
    background: var(--marketcad-background-color);
    border: 1px solid var(--tabs-color);
    box-shadow: var(--box-shadow);
    backdrop-filter: blur(4px);
    border-radius: 10px;
    width: fit-content;
    height: fit-content;

    &__value {
    }
  }
`;

export default staticStyles;
