import { isAssetStable, TokenIcon } from '@sturdyfi/sturdy-ui-kit';

import DefaultButton from 'src/components/basic/DefaultButton';
import Value from 'src/components/basic/Value';

import TableColumnWrapper from 'src/components/Wrappers/Table/TableColumnWrapper';
import TableItemWrapper from 'src/components/Wrappers/Table/TableItemWrapper';

import { ZapAssetProps } from './types';

interface ZapAssetItemProps extends ZapAssetProps {
  onSelectZapAsset: (symbol: string) => void;
}

export default function ZapAssetItem({
  symbol,
  walletBalance,
  underlyingAsset,
  onSelectZapAsset,
}: ZapAssetItemProps) {
  return (
    <TableItemWrapper>
      <TableColumnWrapper minWidth={200}>
        <TokenIcon
          tokenSymbol={symbol}
          height={35}
          width={35}
          tokenFullName={symbol.toUpperCase()}
          className=""
        />
      </TableColumnWrapper>

      <TableColumnWrapper>
        <Value
          value={Number(walletBalance)}
          maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
          minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
        />
      </TableColumnWrapper>

      <TableColumnWrapper className={'ButtonColumn'}>
        <DefaultButton
          title={'Choose'}
          type="secondary"
          color="blue"
          onClick={() => onSelectZapAsset(symbol)}
        />
      </TableColumnWrapper>
    </TableItemWrapper>
  );
}
