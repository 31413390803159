import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BorrowableReserveMarketData,
  calculateHealthFactorFromBalancesBigUnits,
  CollateralReserveMarketData,
  GasResponse,
  Network,
  normalize,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';
import GeneralLevSwapInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v1/GeneralLevSwap';

import DefaultButton from 'src/components/basic/DefaultButton';
import ConnectButton from 'src/components/ConnectButton';
import PoolTxConfirmationView, { EmptyTransaction } from 'src/components/PoolTxConfirmationView';
import { ValidationWrapperComponentProps } from 'src/components/RouteParamsValidationWrapper';
import TransactionOverviewPanel from 'src/components/TransactionOverviewPanel';
import { OverviewDataType } from 'src/components/TransactionOverviewPanel/types';
import AmountBoxWrapper from 'src/components/Wrappers/AmountBoxWrapper';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { useTxBuilderContext } from 'src/libs/tx-provider';
import staticStyles from './style';
import LeveragePanel from 'src/components/LeveragePanel';
import { useDynamicPoolDataContext, useStaticPoolDataContext } from 'src/libs/pool-data-provider';
import BorrowPosition, { BorrowPositionType } from './BorrowPosition';
import useDebounce from 'src/libs/hooks/use-debounce';
import { useWalletBalanceProviderContext } from 'src/libs/wallet-balance-provider/WalletBalanceProvider';
import ZapModal from 'src/components/ZapModal';
import ErrorMsg from 'src/components/ErrorMsg';

type LeverageMode = 'normal' | 'zap';

interface LeverageMainProps
  extends Pick<
    ValidationWrapperComponentProps,
    'currencySymbol' | 'poolReserve' | 'walletBalance' | 'user' | 'userReserve'
  > {}

const MIN_LEVERAGE = 1.9;
const OPTIMAL_UTILIZATION_RATE = 0.8;
const EXCESS_UTILIZATION_RATE = 1 - OPTIMAL_UTILIZATION_RATE;

export default function LeverageMain({
  currencySymbol,
  walletBalance,
  user,
  poolReserve,
  userReserve,
}: LeverageMainProps) {
  const navigate = useNavigate();
  const { marketRefPriceInUsd } = useStaticPoolDataContext();
  const { reserves } = useDynamicPoolDataContext();
  const { walletData } = useWalletBalanceProviderContext();
  const [currentMode, setCurrentMode] = useState<LeverageMode>('normal');
  const [zapModalVisible, setZapModalVisible] = useState(false);
  const borrowableReserves = reserves.filter(
    (res) => res.isActive && res.isBorrowingEnabled
  ) as BorrowableReserveMarketData[];
  const borrowableSymbols = borrowableReserves.map((item) => item.symbol);
  const zapAssetsData = borrowableReserves.map((item) => {
    return {
      symbol: item.symbol,
      underylingAsset: item.underlyingAsset,
      walletBalance: normalize(walletData[item.underlyingAsset], item.decimals),
    };
  });
  const [currentZapSymbol, setCurrentZapSymbol] = useState(borrowableSymbols[0]);
  const [fetchGasData, setFetchGasData] = useState<GasResponse>();
  const { networkConfig, currentMarketData } = useProtocolDataContext();
  const [amount, setAmount] = useState('0');
  const [zapAmount, setZapAmount] = useState('0');
  const debouncedAmount = useDebounce<string>(amount || '0', 500);
  const debouncedZapAmount = useDebounce<string>(zapAmount || '0', 500);
  const [leverage, setLeverage] = useState(MIN_LEVERAGE);
  const debouncedLeverage = useDebounce<number>(leverage || MIN_LEVERAGE, 500);
  const [borrowingAssetSymbol, setBorrowAssetSymbol] = useState('');

  const {
    convexFRAX3CRVLevSwap,
    convexDAIUSDCUSDTSUSDLevSwap,
    convexFRAXUSDCLevSwap,
    convexIRONBANKLevSwap,
    convexMIM3CRVLevSwap,
    convexTUSDFRAXBPLevSwap,
    convexETHSTETHLevSwap,
    auraWSTETHWETHLevSwap,
  } = useTxBuilderContext();
  const symbolToLevSwap: { [key: string]: { [key: string]: GeneralLevSwapInterface } } = {
    [Network.mainnet]: {
      FRAX_3CRV_LP: convexFRAX3CRVLevSwap,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDLevSwap,
      IRON_BANK_LP: convexIRONBANKLevSwap,
      FRAX_USDC_LP: convexFRAXUSDCLevSwap,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPLevSwap,
    },
    [Network.fork]: {
      FRAX_3CRV_LP: convexFRAX3CRVLevSwap,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDLevSwap,
      IRON_BANK_LP: convexIRONBANKLevSwap,
      FRAX_USDC_LP: convexFRAXUSDCLevSwap,
      MIM_3CRV_LP: convexMIM3CRVLevSwap,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPLevSwap,
    },
    [Network.eth]: {
      ETH_STETH_LP: convexETHSTETHLevSwap,
      BAL_WSTETH_WETH_LP: auraWSTETHWETHLevSwap,
    },
  };

  const currentZapReserve = useMemo(() => {
    return borrowableReserves.find((item) => item.symbol === currentZapSymbol);
  }, [currentZapSymbol]);

  const maxAmountToZap = useMemo(() => {
    if (currentZapReserve)
      return normalize(walletData[currentZapReserve.underlyingAsset], currentZapReserve.decimals);
    return '0';
  }, [currentZapReserve]);

  const maxAmountToLeverage = useMemo(() => {
    let availableAmount = valueToBigNumber(walletBalance);
    return availableAmount;
  }, []);

  const leverageValues = useMemo(() => {
    if (Number((poolReserve as CollateralReserveMarketData).maxLeverage) > MIN_LEVERAGE)
      return [MIN_LEVERAGE, Number((poolReserve as CollateralReserveMarketData).maxLeverage)];
    return [];
  }, []);

  const borrowData: BorrowPositionType[] = useMemo(() => {
    const amountInETH = valueToBigNumber(debouncedAmount).multipliedBy(poolReserve.priceInEth);
    const data = borrowableReserves.map((reserve) => {
      const borrowAmount = amountInETH
        .dividedBy(reserve.priceInEth)
        .multipliedBy(debouncedLeverage - 1);

      return {
        underlyingAsset: reserve.underlyingAsset,
        borrowAmount: borrowAmount.toString(),
        symbol: reserve.symbol.toUpperCase(),
        debtToken: reserve.debtTokenAddress,
        availableLiquidity: reserve.availableLiquidity,
        priceInEth: reserve.priceInEth,
        totalLiquidity: reserve.totalLiquidity,
      };
    });

    return data.filter(
      (item) =>
        valueToBigNumber(item.borrowAmount).gte('0') &&
        valueToBigNumber(item.availableLiquidity)
          .minus(valueToBigNumber(item.borrowAmount))
          .gte(valueToBigNumber(item.totalLiquidity).multipliedBy(EXCESS_UTILIZATION_RATE))
    );
  }, [debouncedAmount, debouncedLeverage]);

  const borrowPositions = () => {
    if (valueToBigNumber(amount).eq(0) || borrowData.length === 0) {
      return null;
    }
    return (
      <BorrowPosition
        data={borrowData}
        selectedSymbol={borrowingAssetSymbol}
        onChangeSymbol={setBorrowAssetSymbol}
      />
    );
  };

  const txOverviewData: OverviewDataType = useMemo(() => {
    let healthFactorAfter = user?.healthFactor;
    let borrowAmountInETH = '0';
    let liquidationPrice = '0';
    let depositAPY = (poolReserve as CollateralReserveMarketData).depositAPY;

    const price = normalize(valueToBigNumber(poolReserve.priceInEth).div(marketRefPriceInUsd), 18);
    const amountInETH = valueToBigNumber(debouncedAmount).multipliedBy(poolReserve.priceInEth);
    const borrowingData = borrowingAssetSymbol
      ? borrowData.find((res) => res.symbol.toUpperCase() === borrowingAssetSymbol)
      : borrowData[0];

    if (user && borrowingData) {
      const totalCollateralETHAfter = valueToBigNumber(user.totalCollateralETH).plus(
        normalize(amountInETH.multipliedBy(debouncedLeverage), 18)
      );
      const liquidationThresholdAfter = valueToBigNumber(user.totalCollateralETH)
        .multipliedBy(user.currentLiquidationThreshold)
        .plus(
          normalize(
            amountInETH
              .multipliedBy(debouncedLeverage)
              .multipliedBy((poolReserve as CollateralReserveMarketData).liquidationThreshold),
            18
          )
        )
        .dividedBy(totalCollateralETHAfter);

      borrowAmountInETH = valueToBigNumber(borrowingData.borrowAmount)
        .multipliedBy(borrowingData.priceInEth)
        .toString();
      healthFactorAfter = calculateHealthFactorFromBalancesBigUnits(
        totalCollateralETHAfter,
        valueToBigNumber(user.totalBorrowsETH).plus(normalize(borrowAmountInETH, 18)),
        liquidationThresholdAfter
      ).toString();

      if (amountInETH.gt(0))
        depositAPY = valueToBigNumber((poolReserve as CollateralReserveMarketData).depositAPY)
          .multipliedBy(debouncedLeverage)
          .toString();

      liquidationPrice = valueToBigNumber(user.totalBorrowsETH)
        .plus(normalize(borrowAmountInETH, 18))
        .dividedBy(liquidationThresholdAfter.toString())
        .dividedBy(
          valueToBigNumber(user.totalCollateralETH).plus(
            normalize(amountInETH.multipliedBy(debouncedLeverage), 18)
          )
        )
        .multipliedBy(price)
        .toString();
    }

    return {
      transactionType: 'leverage',
      assetType: 'collateral',
      assetSymbol: currencySymbol,
      healthFactor: healthFactorAfter,
      APY: depositAPY,
      avgAPY: userReserve?.avgAPY,
      price: price.toString(),
      liquidationPrice,
      useFlashloan: true,
      slippage: 0.001,
      fetchGasData,
      borrowPositionComponent: borrowPositions(),
    };
  }, [borrowData, borrowingAssetSymbol, fetchGasData]);

  const handleGetTransactions = useCallback(async () => {
    if (user && valueToBigNumber(debouncedAmount).gt(0) && debouncedLeverage > 1) {
      const borrowingData = borrowingAssetSymbol
        ? borrowData.find((res) => res.symbol.toUpperCase() === borrowingAssetSymbol)
        : borrowData[0];
      if (borrowingData) {
        const entry = Object.entries(symbolToLevSwap?.[currentMarketData.network]).find(([key]) =>
          networkConfig.collateralAssets?.[key]?.includes(currencySymbol)
        );
        if (entry) {
          const leverageSwapper = entry[1];

          if (currentMode === 'zap')
            return await leverageSwapper.zapLeverageWithFlashloan({
              _user: user.id,
              _asset: currentZapReserve?.underlyingAsset || '',
              _amount: debouncedZapAmount,
              _leverage: ((debouncedLeverage - 1) * 10000).toFixed(0),
              _slippage: currentMarketData.flashloanSlippage
                ? (currentMarketData.flashloanSlippage * 10000).toFixed(0)
                : '0',
              _borrowAsset: borrowingData.underlyingAsset,
              _debtTokenAddress: borrowingData.debtToken,
              _debtEstimatedAmount: borrowingData.borrowAmount,
            });
          else
            return await leverageSwapper.enterPositionWithFlashloan({
              _user: user.id,
              _asset:
                networkConfig.collateralAddresses?.[currencySymbol] || poolReserve.underlyingAsset,
              _amount: debouncedAmount,
              _leverage: ((debouncedLeverage - 1) * 10000).toFixed(0),
              _slippage: currentMarketData.flashloanSlippage
                ? (currentMarketData.flashloanSlippage * 10000).toString()
                : '0',
              _stableAsset: borrowingData.underlyingAsset,
              _debtTokenAddress: borrowingData.debtToken,
              _debtEstimatedAmount: borrowingData.borrowAmount,
            });
        }
      }
    }
    return EmptyTransaction;
  }, [borrowData, borrowingAssetSymbol]);

  const handleMainTxCompleted = () => {
    navigate('/dashboard');
  };

  const handleGasPriceChanged = (gas: GasResponse) => {
    setFetchGasData(() => gas);
  };

  const onSelectZapAsset = (symbol: string) => {
    setCurrentZapSymbol(symbol);
    setZapModalVisible(false);
    setCurrentMode('zap');
    setAmount('0');
  };

  useEffect(() => {
    if (!currentZapReserve || currentMode === 'normal') return;

    const estimatedReserveAmount = valueToBigNumber(debouncedZapAmount)
      .multipliedBy(currentZapReserve.priceInEth)
      .multipliedBy(0.99) // 1% slippage
      .dividedBy(poolReserve.priceInEth)
      .toFixed(5);
    setAmount(estimatedReserveAmount);
  }, [debouncedZapAmount]);

  const err = borrowData.length === 0 ? 'Insufficient liquidity' : undefined;

  return (
    <div className="LeverageMain">
      <ZapModal
        isVisible={zapModalVisible}
        onBackdropPress={() => {
          setZapModalVisible(false);
        }}
        zapAssets={zapAssetsData}
        onSelectZapAsset={onSelectZapAsset}
      />
      {currentMode === 'zap' ? (
        <AmountBoxWrapper
          symbols={borrowableSymbols}
          value={zapAmount}
          title={'Paying with'}
          titleTooltipName={'payingWith'}
          hasMax={true}
          maxValue={maxAmountToZap}
          onChange={setZapAmount}
          selectedSymbol={currentZapSymbol}
          onChangeSymbol={setCurrentZapSymbol}
        />
      ) : (
        <AmountBoxWrapper
          symbols={[currencySymbol]}
          value={amount}
          title={'Amount'}
          hasMax={true}
          maxValue={maxAmountToLeverage.toString()}
          onChange={setAmount}
          onClickAsset={() => setZapModalVisible(true)}
        />
      )}
      {leverageValues.length && (
        <LeveragePanel values={leverageValues} onChangeValue={setLeverage} />
      )}
      {currentMode === 'zap' && (
        <AmountBoxWrapper
          symbols={[currencySymbol]}
          value={amount}
          title={'Depositing'}
          titleTooltipName={'depositing'}
        />
      )}
      <TransactionOverviewPanel overviewData={txOverviewData} />
      {!user && <ConnectButton type="button" />}
      {user && valueToBigNumber(amount).eq('0') && (
        <DefaultButton title="Enter amount" type="primary" color="blue" disabled={true} />
      )}
      {user && valueToBigNumber(amount).gt('0') && borrowData.length > 0 && (
        <PoolTxConfirmationView
          mainTxName={'leverage'}
          currencySymbol={currencySymbol}
          debtCurrencySymbol={borrowingAssetSymbol}
          getTransactionsData={handleGetTransactions}
          hideActionWrapper={valueToBigNumber(amount).eq('0')}
          onActiveTxChanged={handleGasPriceChanged}
          onMainTxConfirmed={handleMainTxCompleted}
        />
      )}
      <ErrorMsg failed={err} />
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
