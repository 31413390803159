import { getAssetInfo, TokenIcon, useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import DefaultButton from 'src/components/basic/DefaultButton';
import NoData from 'src/components/basic/NoData';
import Value from 'src/components/basic/Value';
import ValuePercent from 'src/components/basic/ValuePercent';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';

import TableColumnWrapper from 'src/components/Wrappers/Table/TableColumnWrapper';
import TableItemWrapper from 'src/components/Wrappers/Table/TableItemWrapper';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';
import messages from './messages';

import { DepositAssetTableItemProps } from './types';

export default function TableItem({
  symbol,
  underlyingAsset,
  userId,
  walletBalance,
  depositAPY,
  maxLeverage,
  maxAPY,
  leverageEnabled,
  aprDetails,
  isLoading,
}: DepositAssetTableItemProps) {
  let asset;
  let fullName;
  let aprData;
  const hasBalance = walletBalance?.gt(0);
  const intl = useIntl();
  const { sm } = useThemeContext();

  if (!isLoading) {
    asset = getAssetInfo(symbol);
    fullName = !!asset.symbolsArray && asset.symbolsArray.length > 2 ? asset.name : symbol;
  }

  if (depositAPY > 0 && aprDetails && aprDetails.convex) {
    aprData = `Curve Base APR ${
      Number(aprDetails.convex.lidoApr) > 0 ? '+ stETH APR' : ''
    }: <>\\B${intl.formatNumber(Number(aprDetails.convex.baseApr) * 100, {
      maximumFractionDigits: 2,
      minimumFractionDigits: undefined,
    })}%`;
    aprData += `
      CRV Rewards: <>\\B${intl.formatNumber(Number(aprDetails.convex.crvApr) * 100, {
        maximumFractionDigits: 2,
        minimumFractionDigits: undefined,
      })}%`;
  }

  return (
    <TableItemWrapper>
      <TableColumnWrapper minWidth={300}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={280} />
        ) : (
          <TokenIcon
            tokenSymbol={symbol}
            height={35}
            width={35}
            tokenFullName={fullName}
            className=""
          />
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.walletBalance)}
            tooltipName="walletBalance"
          />
        </TableColumnWrapper>
      )}
      <TableColumnWrapper minWidth={180}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={160} />
        ) : !userId || !hasBalance ? (
          <NoData />
        ) : (
          <Value
            value={walletBalance.toString()}
            maximumValueDecimals={5}
            minimumValueDecimals={5}
          />
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.depositAPY)}
            tooltipName="depositAPYForCollateral"
          />
        </TableColumnWrapper>
      )}
      <TableColumnWrapper>
        {isLoading ? (
          <SkeletonWrapper height={50} width={130} />
        ) : aprData ? (
          <TextWithInfoWrapper
            text={
              intl.formatNumber(Number(depositAPY) * 100, {
                maximumFractionDigits: 2,
                minimumFractionDigits: undefined,
              }) + '%'
            }
            tooltipName={'data'}
            tooltipData={aprData}
          />
        ) : (
          <ValuePercent value={depositAPY} />
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.maxLeverage)}
            tooltipName="maxLeverage"
          />
        </TableColumnWrapper>
      )}
      <TableColumnWrapper className={'LeverageColumn'}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={130} />
        ) : (
          <>
            <Value value={maxLeverage} maximumValueDecimals={2} />
            <span>x</span>
          </>
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.maxAPY)}
            tooltipName="maxAPY"
          />
        </TableColumnWrapper>
      )}
      <TableColumnWrapper minWidth={130}>
        {isLoading ? <SkeletonWrapper height={50} width={110} /> : <ValuePercent value={maxAPY} />}
      </TableColumnWrapper>

      <TableColumnWrapper className={'ButtonColumn'} minWidth={230}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={210} />
        ) : (
          <>
            <Link to={`/provide/deposit/${underlyingAsset}`}>
              <DefaultButton title={'Deposit'} type="secondary" color="blue" />
            </Link>
            {leverageEnabled ? (
              <Link to={`/provide/leverage/${underlyingAsset}`}>
                <DefaultButton title={'Leverage'} type="secondary" color="gray" />
              </Link>
            ) : (
              <Link to={''}>
                <DefaultButton title={'Leverage'} type="secondary" color="gray" disabled={true} />
              </Link>
            )}
          </>
        )}
      </TableColumnWrapper>
    </TableItemWrapper>
  );
}
