import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .MenuLink {
    &:hover {
      .MenuLink__title {
        i {
          width: 80%;
        }
      }
    }
    .MenuLink__title {
      position: relative;
      padding: 15px 0;
      font-size: 600;
      backface-visibility: hidden;
      transform: translateZ(0);
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;

      strong {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        transition: all 0.2s ease;
        font-weight: 600;
        white-space: nowrap;
        letter-spacing: 0.25px;
      }

      p {
        transition: all 0.2s ease;
        position: relative;
        margin: 0;

        b {
          color: var(--not-selected-color);
          opacity: 1;
          font-weight: 600;
          transition: all 0.2s ease;
        }
      }

      i {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        bottom: -1px;
        height: 3px;
        transition: all 0.4s ease;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: var(--selected-color);
          transition: all 0.4s ease;
        }
      }
    }

    &__active {
      .MenuLink__title {
        p {
          b {
            opacity: 0;
          }
        }
        strong {
          opacity: 1;
        }
        i {
          width: 80% !important;
        }
      }
    }

    &__hidden {
      display: none;
    }
  }
`;

export default staticStyles;
