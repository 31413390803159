import classNames from 'classnames';
import staticStyles from './style';

interface ErrorMsgProps {
  failed?: string;
  className?: string;
}

export default function ErrorMsg({ failed, className }: ErrorMsgProps) {
  return (
    <div
      className={classNames('ErrorMsg__Wrapper', {
        ErrorMsg__WrapperVisible: failed,
      })}
    >
      <p>{failed}</p>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
