import { useIntl } from 'react-intl';

import {
  valueToBigNumber,
  BorrowableReserveMarketData,
  CollateralReserveMarketData,
} from '@sturdyfi/sturdy-js';

import { useDynamicPoolDataContext } from 'src/libs/pool-data-provider';
import { useWalletBalanceProviderContext } from 'src/libs/wallet-balance-provider/WalletBalanceProvider';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';

import ScreenWrapper from 'src/components/Wrappers/ScreenWrapper';
import Caption from 'src/components/basic/Caption';
import Tooltip from 'src/components/Tooltip';
import QuestionLinkWrapper from 'src/components/Wrappers/QuestionLinkWrapper';

import EtherMarketSwitcher from '../components/EtherMarketSwitcher';
import MarketCard from '../components/MarketCard';
import { LendBorrowTableItemProps } from '../components/LendBorrowAssetTable/types';
import LendBorrowAssetTable from '../components/LendBorrowAssetTable';
import DepositAssetTable from '../components/DepositAssetTable';
import { DepositAssetTableItemProps } from '../components/DepositAssetTable/types';

import messages from './messages';
import staticStyles from './style';

export default function Markets() {
  const intl = useIntl();
  const { reserves, user, isLoading } = useDynamicPoolDataContext();
  const { walletData } = useWalletBalanceProviderContext();
  const { networkConfig } = useProtocolDataContext();

  let totalMarketSizeInUsd = valueToBigNumber('0');
  let totalReserveBorrowsInUSD = valueToBigNumber('0');
  let totalAvailableInUsd = valueToBigNumber('0');

  const lendBorrowAssetsData: LendBorrowTableItemProps[] = reserves
    .filter((reserve) => reserve.isActive && reserve.isBorrowingEnabled)
    .map((reserve) => {
      // total deposit amount
      const totalLiquidityInUSD = reserve.totalLiquidityInUSD;

      totalMarketSizeInUsd = totalMarketSizeInUsd.plus(totalLiquidityInUSD);

      const availableLiquidityInUsd = (reserve as BorrowableReserveMarketData)
        .availableLiquidityInUSD;
      // total borrow amount
      const totalBorrowsInUSD = valueToBigNumber(reserve.totalLiquidityInUSD).minus(
        availableLiquidityInUsd
      );

      if (reserve.isBorrowingEnabled) {
        totalReserveBorrowsInUSD = totalReserveBorrowsInUSD.plus(totalBorrowsInUSD);
        totalAvailableInUsd = totalAvailableInUsd.plus(availableLiquidityInUsd);
      }

      const userReserve = user?.reservesUserData.find((res) => res.symbol === reserve.symbol);

      const walletBalance =
        userReserve && walletData
          ? walletData[reserve.underlyingAsset] === '0'
            ? valueToBigNumber('0')
            : valueToBigNumber(walletData[reserve.underlyingAsset] || '0').dividedBy(
                valueToBigNumber('10').pow(reserve.decimals)
              )
          : valueToBigNumber('0');

      const formattedData: LendBorrowTableItemProps = {
        id: reserve.id,
        symbol: reserve.symbol,
        decimals: reserve.decimals,
        underlyingAsset: reserve.underlyingAsset,
        totalLiquidityInUSD: totalLiquidityInUSD,
        totalBorrowsInUSD: totalBorrowsInUSD.toString(),
        utilizationRatio: (reserve as BorrowableReserveMarketData).utilizationRatio,
        walletBalance: walletBalance,
        depositAPY: (reserve as BorrowableReserveMarketData).depositAPY,
        borrowAPY: (reserve as BorrowableReserveMarketData).borrowAPY,
        isFreezed: reserve.isFreezed,
        userId: user?.id,
      };
      return formattedData;
    })
    .sort((a, b) =>
      valueToBigNumber(a.totalLiquidityInUSD).gt(valueToBigNumber(b.totalLiquidityInUSD)) ? -1 : 0
    );

  const depositAssetsData: DepositAssetTableItemProps[] = reserves
    .filter(
      (reserve) =>
        reserve.isActive &&
        !reserve.isFreezed &&
        !reserve.isBorrowingEnabled &&
        reserve.isCollateralEnabled
    )
    .map((reserve) => {
      const totalLiquidityInUSD = reserve.totalLiquidityInUSD;

      totalMarketSizeInUsd = totalMarketSizeInUsd.plus(totalLiquidityInUSD);

      const userReserve = user?.reservesUserData.find((res) => res.symbol === reserve.symbol);

      const underlyingAsset =
        networkConfig.collateralAddresses?.[reserve.symbol] || reserve.underlyingAsset;
      const walletBalance =
        userReserve && walletData
          ? walletData[underlyingAsset] === '0'
            ? valueToBigNumber('0')
            : valueToBigNumber(walletData[underlyingAsset] || '0').dividedBy(
                valueToBigNumber('10').pow(reserve.decimals)
              )
          : valueToBigNumber('0');

      const formattedData: DepositAssetTableItemProps = {
        id: reserve.id,
        symbol: reserve.symbol,
        decimals: reserve.decimals,
        underlyingAsset: reserve.underlyingAsset,
        walletBalance: walletBalance,
        depositAPY: (reserve as CollateralReserveMarketData).depositAPY,
        isFreezed: reserve.isFreezed,
        maxLeverage: (reserve as CollateralReserveMarketData).maxLeverage,
        maxAPY: (reserve as CollateralReserveMarketData).maxAPY,
        leverageEnabled: (reserve as CollateralReserveMarketData).leverageEnabled,
        aprDetails: (reserve as CollateralReserveMarketData).aprDetails,
        userId: user?.id,
      };
      return formattedData;
    });

  return (
    <ScreenWrapper>
      <div className="Markets">
        <div className="Markets__section">
          <EtherMarketSwitcher />
          <div className="Markets__size">
            <MarketCard
              title="Total Market Size"
              tooltipName="totalMarketSize"
              value={totalMarketSizeInUsd.toNumber()}
              primary={true}
              isLoading={isLoading}
            />
            <MarketCard
              title="Total borrowed"
              tooltipName="totalBorrowed"
              value={totalReserveBorrowsInUSD.toNumber()}
              isLoading={isLoading}
            />
            <MarketCard
              title="Total available"
              tooltipName="totalAvailable"
              value={totalAvailableInUsd.toNumber()}
              isLoading={isLoading}
            />
          </div>
        </div>

        <div className="Markets__section">
          <div className="Markets__table-description">
            <Caption title={intl.formatMessage(messages.assetsToLendOrBorrow)} marginBottom={0} />
            <Tooltip
              link={
                <QuestionLinkWrapper
                  text={intl.formatMessage(messages.lendingAndBorrowingQuestion)}
                  className={'HelpLink'}
                />
              }
              tooltipName={'lendAndBorrowing'}
              hoverable={false}
            />
          </div>
          <LendBorrowAssetTable listData={lendBorrowAssetsData} isLoading={isLoading} />
        </div>

        <div className="Markets__section">
          <div className="Markets__table-description">
            <Caption title={intl.formatMessage(messages.assetsToDeposit)} marginBottom={0} />
            <Tooltip
              link={
                <QuestionLinkWrapper
                  text={intl.formatMessage(messages.depositQuestion)}
                  className={'HelpLink'}
                />
              }
              tooltipName={'depositing'}
              hoverable={false}
            />
          </div>
          <DepositAssetTable listData={depositAssetsData} isLoading={isLoading} />
        </div>

        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </div>
    </ScreenWrapper>
  );
}
