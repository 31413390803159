import classNames from 'classnames';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ActionSwitcher from 'src/components/ActionSwitcher';
import { ActionButtonDataType } from 'src/components/ActionSwitcher/types';
import BackButton from 'src/components/basic/BackButton';
import { ValidationWrapperComponentProps } from 'src/components/RouteParamsValidationWrapper';
import PanelWrapper from 'src/components/Wrappers/PanelWrapper';

import staticStyles from './style';
import BorrowMain from '../BorrowMain';
import RepayMain from '../RepayMain';

const actionButtons: ActionButtonDataType[] = [
  { action: 'borrow', title: 'Borrow' },
  { action: 'repay', title: 'Repay' },
];

interface BorrowCurrencyWrapperProps extends ValidationWrapperComponentProps {
  className?: string;
}

export default function BorrowCurrencyWrapper(props: BorrowCurrencyWrapperProps) {
  const { action, currencySymbol, className } = props;
  const [selectedAction, setSelectedAction] = useState('borrow');
  const navigate = useNavigate();

  useEffect(() => {
    let newAction = action;
    if (!newAction) {
      newAction = 'borrow';
    }
    setSelectedAction(newAction);
  }, [action]);

  const handleActionChange = (newAction: string) => {
    setSelectedAction(newAction);
  };

  const isBorrow = selectedAction === 'borrow';
  const isRepay = selectedAction === 'repay';
  return (
    <PanelWrapper className={classNames('BorrowCurrencyWrapper', className)}>
      <div className="BorrowCurrencyWrapper__title">
        <BackButton onClick={() => navigate(-1)} />
        <p className="BorrowCurrencyWrapper__title-caption">{currencySymbol}</p>
      </div>
      <div className="BorrowCurrencyWrapper__separate-line"></div>
      <div className="BorrowCurrencyWrapper__content">
        <ActionSwitcher
          buttons={actionButtons}
          selectedAction={selectedAction}
          onActionChange={handleActionChange}
        />
        {isBorrow && <BorrowMain {...props} />}
        {isRepay && <RepayMain {...props} />}
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </PanelWrapper>
  );
}
