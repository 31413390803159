import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .AmountBoxWrapper {
    background: var(--dropdown-background-color);
    border-radius: 16px;
    box-shadow: var(--table-box-shadow);
    border: 1px solid var(--tabs-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: fit-content;
    padding: 15px;

    &__disabled {
      background: var(--button2-color);
      color: var(--selected-color);
    }

    &__title {
      @include body_16;
      color: var(--text2-color);
      display: flex;
      justify-content: space-between;
      width: 100%;

      &__maxValue {
        display: flex;
        column-gap: 3px;
        cursor: pointer;
      }
    }

    &__content {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
      }
      input[type='number'] {
        -moz-appearance: textfield; /* Firefox */
      }

      &__input {
        @include body_24_sb;
        color: var(--selected-color);
        border: none;
        background: transparent;
        appearance: none;
        box-shadow: none;
        outline: none;
        width: 100%;
        min-width: 200px;
      }

      &__token {
        display: flex;
        align-items: center;
        column-gap: 2px;
      }

      .TokenIcon__image {
        margin-right: 8px;
      }

      p {
        @include body_20;
        color: var(--selected-color);
        margin: 0;
      }
    }

    @include respond-to(sm) {
      width: 100%;
    }
  }
`;

export default staticStyles;
