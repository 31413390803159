import { getAssetInfo, isAssetStable, TokenIcon, useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import DefaultButton from 'src/components/basic/DefaultButton';
import Value from 'src/components/basic/Value';
import ValuePercent from 'src/components/basic/ValuePercent';
import DropdownWrapper from 'src/components/Wrappers/DropdownWrapper';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';

import TableColumnWrapper from 'src/components/Wrappers/Table/TableColumnWrapper';
import TableItemWrapper from 'src/components/Wrappers/Table/TableItemWrapper';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';
import messages from './messages';

import { DepositAssetProps } from './types';

export default function DepositAssetItem({
  symbol,
  underlyingAsset,
  underlyingBalance,
  depositAPY,
  avgAPY,
  isLoading,
}: DepositAssetProps) {
  let asset;
  let fullName;
  const intl = useIntl();
  const { sm } = useThemeContext();

  if (!isLoading) {
    asset = getAssetInfo(symbol);
    fullName = !!asset.symbolsArray && asset.symbolsArray.length > 2 ? asset.name : symbol;
  }

  const renderButtons = () => {
    if (sm) {
      return (
        <>
          <Link to={`/provide/deposit/${underlyingAsset}`}>
            <DefaultButton title={'Deposit'} type="secondary" color="blue" />
          </Link>
          <DropdownWrapper
            horizontalPosition="right"
            verticalPosition="bottom"
            buttonComponent={
              <div className="MoreButton">
                <span>More</span>
              </div>
            }
          >
            <Link to={`/withdraw/withdraw/${underlyingAsset}`}>
              <DefaultButton title={'Withdraw'} type="secondary" color="gray" />
            </Link>
            <Link to={`/withdraw/deleverage/${underlyingAsset}`}>
              <DefaultButton title={'Deleverage'} type="secondary" color="gray" />
            </Link>
          </DropdownWrapper>
        </>
      );
    }
    return (
      <>
        <Link to={`/provide/deposit/${underlyingAsset}`}>
          <DefaultButton title={'Deposit'} type="secondary" color="blue" />
        </Link>
        <Link to={`/withdraw/withdraw/${underlyingAsset}`}>
          <DefaultButton title={'Withdraw'} type="secondary" color="gray" />
        </Link>
        <Link to={`/withdraw/deleverage/${underlyingAsset}`}>
          <DefaultButton title={'Deleverage'} type="secondary" color="gray" />
        </Link>
      </>
    );
  };

  return (
    <TableItemWrapper>
      <TableColumnWrapper minWidth={300}>
        {isLoading ? (
          <SkeletonWrapper height={50} width={280} />
        ) : (
          <TokenIcon
            tokenSymbol={symbol}
            height={35}
            width={35}
            tokenFullName={fullName}
            className=""
          />
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.balance)}
            tooltipName="balance"
          />
        </TableColumnWrapper>
      )}
      <TableColumnWrapper>
        {isLoading ? (
          <SkeletonWrapper height={50} width={130} />
        ) : (
          <Value
            value={Number(underlyingBalance)}
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
          />
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.apy)}
            tooltipName="depositAPYForCollateral"
          />
        </TableColumnWrapper>
      )}
      <TableColumnWrapper>
        {isLoading ? (
          <SkeletonWrapper height={50} width={130} />
        ) : (
          <ValuePercent value={depositAPY} />
        )}
      </TableColumnWrapper>

      {sm && (
        <TableColumnWrapper>
          <TextWithInfoWrapper
            className="TableHeaderWrapper__title"
            text={intl.formatMessage(messages.avgAPY)}
            tooltipName="averageAPY"
          />
        </TableColumnWrapper>
      )}
      <TableColumnWrapper minWidth={100}>
        {isLoading ? <SkeletonWrapper height={50} width={80} /> : <ValuePercent value={avgAPY} />}
      </TableColumnWrapper>

      <TableColumnWrapper className={'ButtonColumn'} minWidth={400}>
        {isLoading ? <SkeletonWrapper height={50} width={380} /> : renderButtons()}
      </TableColumnWrapper>
    </TableItemWrapper>
  );
}
