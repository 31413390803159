import React from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import {
  ComputedUserReserve,
  UserSummaryData,
  ComputedReserveData,
  BigNumber,
  valueToBigNumber,
  ComputedUserReserveDetails,
} from '@sturdyfi/sturdy-js';

import { API_ETH_MOCK_AAVE_ADDRESS } from 'src/config';
import { useDynamicPoolDataContext } from 'src/libs/pool-data-provider';
import { useWalletBalanceProviderContext } from 'src/libs/wallet-balance-provider/WalletBalanceProvider';
import Preloader from '../basic/Preloader';

import { useProtocolDataContext } from 'src/libs/protocol-data-provider';

export interface ValidationWrapperComponentProps {
  action: string;
  currencySymbol: string;
  walletBalance: BigNumber;
  walletEthBalance: BigNumber;
  user?: UserSummaryData;
  poolReserve: ComputedReserveData;
  userReserve?: ComputedUserReserve & ComputedUserReserveDetails;
}

interface RouteParamValidationWrapperProps {
  Component: React.ElementType<ValidationWrapperComponentProps>;
  withUserReserve?: boolean;
  withWalletBalance?: boolean;
}

enum Routes {
  lend = 'lend',
  borrow = 'borrow',
  provide = 'provide',
  withdraw = 'withdraw',
}

type DefaultActionType = {
  availableActions: string[];
  defaultAction: string;
};

const routeActionMap: { [key in keyof typeof Routes]: DefaultActionType } = {
  lend: {
    availableActions: ['deposit', 'withdraw'],
    defaultAction: 'deposit',
  },
  borrow: {
    availableActions: ['borrow', 'repay'],
    defaultAction: 'borrow',
  },
  provide: {
    availableActions: ['deposit', 'leverage'],
    defaultAction: 'deposit',
  },
  withdraw: {
    availableActions: ['withdraw', 'deleverage'],
    defaultAction: 'withdraw',
  },
};

const defaultAsset = (path: keyof typeof Routes, reserves: ComputedReserveData[]) => {
  const isStable = path === 'lend' || path === 'borrow';
  if (isStable) {
    return reserves.filter((res) => res.isActive && res.isBorrowingEnabled)[0];
  } else {
    return reserves.filter((res) => res.isActive && res.isCollateralEnabled)[0];
  }
};

const validateAction = (path: keyof typeof Routes, action?: string) => {
  const routeAction = routeActionMap[path];
  return action && routeAction.availableActions.includes(action)
    ? action
    : routeAction.defaultAction;
};

export default function routeParamValidationHOC({
  Component,
  withUserReserve,
  withWalletBalance,
}: RouteParamValidationWrapperProps) {
  function ComponentWithRouterProp() {
    const location = useLocation();
    const params = useParams();
    const { user, reserves } = useDynamicPoolDataContext();
    const { networkConfig } = useProtocolDataContext();

    const currentPath = location.pathname.split('/')[1];
    const action = validateAction(currentPath as keyof typeof Routes, params.action);
    const inUnderlyingAsset = params.underlyingAsset?.toUpperCase();

    // if (reserves.length === 0) {
    //   return <Preloader withText={true} />;
    // }

    const poolReserve = inUnderlyingAsset
      ? reserves.find(
          (res) => res.underlyingAsset.toLowerCase() === inUnderlyingAsset.toLowerCase()
        )
      : defaultAsset(currentPath as keyof typeof Routes, reserves);

    if (!poolReserve) {
      // return <Navigate to={`/${currentPath}`} replace />;
      return <Navigate to={'/'} replace />;
    }

    const currencySymbol = poolReserve.symbol || '';
    const underlyingAsset = poolReserve.underlyingAsset || '';

    const userReserve = user
      ? user.reservesUserData.find(
          (userReserve) =>
            userReserve.underlyingAsset.toLowerCase() === underlyingAsset.toLowerCase()
        )
      : undefined;

    const { walletData } = useWalletBalanceProviderContext({
      skip: !withWalletBalance || !poolReserve || (withUserReserve && !userReserve),
    });
    // if (!walletData) {
    //   return <Preloader withText={true} />;
    // }

    if (!userReserve && withUserReserve) {
      return <Navigate to="/" replace />;
    }

    const underlyingAssetAddress =
      networkConfig.collateralAddresses?.[poolReserve.symbol] || poolReserve.underlyingAsset;
    const walletBalance = valueToBigNumber(
      walletData[underlyingAssetAddress as string] || '0'
    ).dividedBy(valueToBigNumber(10).pow(poolReserve.decimals));

    const walletEthBalance = valueToBigNumber(
      walletData[API_ETH_MOCK_AAVE_ADDRESS] || '0'
    ).dividedBy(valueToBigNumber(10).pow(poolReserve.decimals));

    const props = {
      action,
      currencySymbol,
      poolReserve,
      userReserve,
      user,
      walletBalance,
      walletEthBalance,
    };
    return <Component {...props} />;
  }

  return ComponentWithRouterProp;
}
