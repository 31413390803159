import NoData from '../../basic/NoData';
import SpinLoader from '../../basic/SpinLoader';
import Value from '../../basic/Value';
import InfoPanelWrapper from '../../Wrappers/InfoPanelWrapper';
import TextWithInfoWrapper from '../../Wrappers/TextWithInfoWrapper';

import { OverviewDataType } from '../types';
import staticStyles from './style';

interface BorrowableWithdrawOverviewPanelProps
  extends Pick<
    OverviewDataType,
    'assetAmount' | 'assetSymbol' | 'gasEstimation' | 'loadingGasEstimation'
  > {}

export default function BorrowableWithdrawOverviewPanel({
  assetAmount,
  assetSymbol,
  gasEstimation,
  loadingGasEstimation,
}: BorrowableWithdrawOverviewPanelProps) {
  return (
    <InfoPanelWrapper
      title={<span>Transaction overview</span>}
      className={'BorrowableWithdrawOverviewPanel'}
    >
      <div className={'BorrowableWithdrawOverviewPanel__item'}>
        <div className={'BorrowableWithdrawOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Remaining Balance'} tooltipName="remainingBalance" />
          <div className={'BorrowableWithdrawOverviewPanel__item__content__value'}>
            {assetAmount ? <Value value={assetAmount} maximumValueDecimals={2} /> : <NoData />}

            <span>&nbsp;{assetSymbol.toUpperCase()}</span>
          </div>
        </div>
      </div>

      <div className={'BorrowableWithdrawOverviewPanel__item'}>
        <div className={'BorrowableWithdrawOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Gas Fee'} tooltipName="gasFee" />
          <div className={'BorrowableWithdrawOverviewPanel__item__content__value'}>
            {loadingGasEstimation ? (
              <SpinLoader color="black" />
            ) : gasEstimation ? (
              <Value
                value={gasEstimation}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>{' '}
        </div>
      </div>

      <style jsx={true}>{staticStyles}</style>
    </InfoPanelWrapper>
  );
}
