import React from 'react';
import { IntlShape } from 'react-intl/src/types';
import { SocialIcon, SocialType } from '@sturdyfi/sturdy-ui-kit';

import { UnlockWalletExtraText as UnlockWalletExtraTextUI } from './UnlockWalletExtraText';

export const socialIcons: SocialIcon[] = [
  {
    url: 'https://twitter.com/SturdyFinance',
    type: SocialType.Twitter,
  },
  {
    url: 'https://github.com/sturdyfi',
    type: SocialType.Github,
  },
  {
    url: 'https://discord.gg/tRVHp6Vx5N',
    type: SocialType.Discord,
  },
  {
    url: 'https://docs.sturdy.finance',
    type: SocialType.Gitbook,
  },
];

export const UnlockWalletExtraText: React.FC<{ intl: IntlShape }> = UnlockWalletExtraTextUI;
