import classNames from 'classnames';
import React from 'react';

import staticStyles from './style';

interface QuestionLinkWrapperProps {
  text: string;
  className?: string;
  onClick?: () => void;
}

export default function QuestionLinkWrapper({
  text,
  className,
  onClick,
}: QuestionLinkWrapperProps) {
  return (
    <p className={classNames('QuestionLinkWrapper', className)} onClick={onClick}>
      {text}
      <style jsx={true}>{staticStyles}</style>
    </p>
  );
}
