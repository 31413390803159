import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .APYChartModal {
    box-shadow: var(--panel-box-shadow) !important;
    background: var(--panel-background-color) !important;
    &.ReactModal__Content {
      max-width: 100vw !important;
      // @include respond-to(xl) {
      //   max-width: 750px;
      // }
    }

    &__inner {
      max-height: 80vh;
      overflow: hidden scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .InfoPanelWrapper {
        margin-top: 20px;

        &__title {
          text-align: left;
        }
      }
    }

    &__inner::-webkit-scrollbar {
      display: none;
    }

    &__caption-inner {
      margin-bottom: 50px;
      @include respond-to(xl) {
        margin-bottom: 30px;
      }
      h2 {
        font-size: $large;
        @include respond-to(xl) {
          font-size: $regular;
        }
      }
    }
  }
`;

export default staticStyles;
