import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import LendingHelpModal from 'src/components/HelpModal/LendingHelpModal';

import messages from '../messages';
import MoreInformationButton from 'src/components/basic/MoreInformationButton';

export default function WhereIsYieldFrom() {
  const intl = useIntl();
  const [visible, setVisible] = useState(false);
  const { isCurrentThemeDark } = useThemeContext();

  const renderAdditionalDescription = () => {
    const clsName = isCurrentThemeDark ? 'dark' : 'light';
    return (
      <div className="Tooltip__additional-description">
        <span className={clsName}>&#9432;</span>
        <span className={clsName}>{intl.formatMessage(messages.whereIsYieldFromDesc2)}</span>
      </div>
    );
  };

  const renderContent = () => {
    const description = intl.formatMessage(messages.whereIsYieldFromDesc);
    const smallParts = description.split('\n');

    return (
      <div className="Tooltip__content">
        {smallParts.map((str, id) => (
          <p key={id}>{str}</p>
        ))}
        {renderAdditionalDescription()}
        <MoreInformationButton onClick={() => setVisible(true)} />
        <LendingHelpModal isVisible={visible} onBackdropPress={() => setVisible(false)} />
      </div>
    );
  };

  return (
    <>
      <div className="Tooltip__title">{intl.formatMessage(messages.whereIsYieldFrom)}</div>
      {renderContent()}
    </>
  );
}
