import { Carousel } from 'react-responsive-carousel';
import {
  REWARD_TYPE,
  valueToBigNumber,
  normalize,
  CollateralReserveMarketData,
} from '@sturdyfi/sturdy-js';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import NoDataPanel from 'src/components/NoDataPanel';
import Caption from 'src/components/basic/Caption';
import ScreenWrapper from 'src/components/Wrappers/ScreenWrapper';
import ValueBoxWrapper from 'src/components/Wrappers/ValueBoxWrapper ';

import { useDynamicPoolDataContext } from 'src/libs/pool-data-provider';
import { useUserWalletDataContext } from 'src/libs/web3-data-provider';

import { DepositAssetProps } from '../components/DepositAssetTable/types';
import DepositAssetTable from '../components/DepositAssetTable';
import LendCurrencyTable from '../components/LendCurrencyTable';
import { LendCurrencyProps } from '../components/LendCurrencyTable/types';
import BorrowCurrencyTable from '../components/BorrowCurrencyTable';
import { BorrowCurrencyProps } from '../components/BorrowCurrencyTable/types';
import { DashboardSummaryDataType } from '../components/DashboardCard/type';
import DashboardCard from '../components/DashboardCard';

import staticStyles from './style';
import { useIncentivesDataContext } from 'src/libs/pool-data-provider/hooks/use-incentives-data-context';
import { useMemo } from 'react';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';

export default function Dashboard() {
  const { sm } = useThemeContext();
  const { reserves, user, isLoading } = useDynamicPoolDataContext();
  const { showSelectWalletModal } = useUserWalletDataContext();
  const { userReward } = useIncentivesDataContext();

  if (!isLoading && !user) {
    return (
      <NoDataPanel
        title="Connect your wallet"
        description="Please connect your wallet in order to see the dashboard page"
        iconName="wallet"
        onClick={() => showSelectWalletModal()}
      />
    );
  }

  const claimableReward = useMemo(
    () =>
      userReward
        ?.filter(
          (entry) =>
            entry.totalUnclaimedRewards > 0 &&
            entry.rewardUserDatas[0].rewardType === REWARD_TYPE.VARIABLE
        )
        .reduce((accumulator, reward) => {
          return valueToBigNumber(accumulator).plus(reward.totalUnclaimedRewards).toString();
        }, '0'),
    [userReward]
  );

  const formattedClaimableReward = normalize(claimableReward || '0', 18);

  let totalLentInUSD = valueToBigNumber('0');
  let totalCollateralInUSD = valueToBigNumber('0');
  let totalBorrowedInUSD = valueToBigNumber('0');

  const lendAssetsData: LendCurrencyProps[] = [];
  const depositAssetsData: DepositAssetProps[] = [];
  const borrowAssetsData: BorrowCurrencyProps[] = [];

  if (reserves && reserves.length > 0) {
    // lent & borrow
    reserves
      .filter((reserve) => reserve.isActive && reserve.isBorrowingEnabled)
      .forEach((reserve) => {
        const userReserve = user?.reservesUserData.find(
          (res) => res.underlyingAsset === reserve.underlyingAsset
        );

        if (userReserve && Number(userReserve.underlyingBalanceUSD) > 0) {
          totalLentInUSD = totalLentInUSD.plus(userReserve.underlyingBalanceUSD);

          lendAssetsData.push({
            symbol: userReserve.symbol,
            underlyingAsset: userReserve.underlyingAsset,
            underlyingBalance: userReserve.underlyingBalance,
            depositAPY: userReserve.depositAPY,
            avgAPY: userReserve.avgAPY,
          });
        }
        if (userReserve && Number(userReserve.totalBorrowsUSD) > 0) {
          totalBorrowedInUSD = totalBorrowedInUSD.plus(userReserve.totalBorrowsUSD);

          borrowAssetsData.push({
            symbol: userReserve.symbol,
            borrowAPY: userReserve.borrowAPY,
            debt: userReserve.totalBorrows,
            underlyingAsset: userReserve.underlyingAsset,
          });
        }
      });

    // provided collateral
    reserves
      .filter((reserve) => reserve.isActive && reserve.isCollateralEnabled)
      .forEach((reserve) => {
        const userReserve = user?.reservesUserData.find(
          (res) => res.underlyingAsset === reserve.underlyingAsset
        );
        if (userReserve && Number(userReserve.underlyingBalanceUSD) > 0) {
          totalCollateralInUSD = totalCollateralInUSD.plus(userReserve.underlyingBalanceUSD);

          depositAssetsData.push({
            symbol: userReserve.symbol,
            underlyingAsset: userReserve.underlyingAsset,
            underlyingBalance: userReserve.underlyingBalance,
            depositAPY: userReserve.depositAPY,
            aprDetails: (reserve as CollateralReserveMarketData).aprDetails,
            avgAPY: userReserve.avgAPY,
          });
        }
      });
  }

  const summaryData: DashboardSummaryDataType = {
    totalLent: totalLentInUSD,
    totalCollateral: totalCollateralInUSD,
    totalBorrowed: totalBorrowedInUSD,
    borrowPowerUsed: user?.totalBorrowPowerUsed || '0',
    currentLTV: user?.currentLTV || '0',
  };

  const renderCards = () => {
    if (sm) {
      return (
        <Carousel
          showArrows={false}
          showThumbs={false}
          infiniteLoop={false}
          autoPlay={false}
          stopOnHover={true}
        >
          <DashboardCard type={'avgAPY'} value={user?.totalAPY} isLoading={isLoading} />
          <DashboardCard type={'healthFactor'} value={user?.healthFactor} isLoading={isLoading} />
          <DashboardCard type={'borrowAPY'} value={user?.totalBorrowAPY} isLoading={isLoading} />
          <DashboardCard type={'rewards'} value={formattedClaimableReward} isLoading={isLoading} />
        </Carousel>
      );
    }

    return (
      <>
        <div className="Dashboard__positions-item">
          <DashboardCard type={'avgAPY'} value={user?.totalAPY} isLoading={isLoading} />
          <DashboardCard type={'healthFactor'} value={user?.healthFactor} isLoading={isLoading} />
        </div>
        <div className="Dashboard__positions-item">
          <DashboardCard type={'borrowAPY'} value={user?.totalBorrowAPY} isLoading={isLoading} />
          <DashboardCard type={'rewards'} value={formattedClaimableReward} isLoading={isLoading} />
        </div>
      </>
    );
  };

  return (
    <ScreenWrapper>
      <div className="Dashboard">
        <div className="Dashboard__section">
          <Caption title="Your Positions" />
          <div className="Dashboard__positions">
            <div className="Dashboard__positions-main">
              <DashboardCard type={'summary'} summaryData={summaryData} isLoading={isLoading} />
            </div>
            <div className="Dashboard__positions-secondary">{renderCards()}</div>
          </div>
        </div>
        {isLoading ? (
          <>
            <div className="Dashboard__section">
              <Caption title="Lending Positions" />
              <div className="Dashboard__section-summary">
                <SkeletonWrapper width={300} height={30} />
              </div>
              <LendCurrencyTable listData={lendAssetsData} isLoading={isLoading} />
            </div>
            <div className="Dashboard__section">
              <Caption title="Deposits" />
              <div className="Dashboard__section-summary">
                <SkeletonWrapper width={300} height={30} />
              </div>
              <DepositAssetTable listData={depositAssetsData} isLoading={isLoading} />
            </div>
            <div className="Dashboard__section">
              <Caption title="Borrows" />
              <div className="Dashboard__section-summary">
                <SkeletonWrapper width={500} height={30} />
              </div>
              <BorrowCurrencyTable listData={borrowAssetsData} isLoading={isLoading} />
            </div>
          </>
        ) : (
          <>
            {lendAssetsData.length > 0 && (
              <div className="Dashboard__section">
                <Caption title="Lending Positions" />
                <div className="Dashboard__section-summary">
                  <ValueBoxWrapper
                    type={'price'}
                    title={'Balance'}
                    value={user?.totalSupplyBalanceUSD}
                  />
                  <ValueBoxWrapper
                    type={'percent'}
                    title={'Total APY'}
                    value={user?.totalSupplyAPY}
                  />
                </div>
                <LendCurrencyTable listData={lendAssetsData} isLoading={isLoading} />
              </div>
            )}
            {depositAssetsData.length > 0 && (
              <div className="Dashboard__section">
                <Caption title="Deposits" />
                <div className="Dashboard__section-summary">
                  <ValueBoxWrapper
                    type={'price'}
                    title={'Balance'}
                    value={user?.totalCollateralUSD}
                  />
                  <ValueBoxWrapper
                    type={'percent'}
                    title={'Total APY'}
                    value={user?.totalCollateralAPY}
                  />
                </div>
                <DepositAssetTable listData={depositAssetsData} isLoading={isLoading} />
              </div>
            )}
            {borrowAssetsData.length > 0 && (
              <div className="Dashboard__section">
                <Caption title="Borrows" />
                <div className="Dashboard__section-summary">
                  <ValueBoxWrapper type={'price'} title={'Balance'} value={user?.totalBorrowsUSD} />
                  <ValueBoxWrapper
                    type={'percent'}
                    title={'Total APY'}
                    value={user?.totalBorrowAPY}
                  />
                  <ValueBoxWrapper
                    type={'percent'}
                    title={'Borrow Power Used'}
                    value={user?.totalBorrowPowerUsed}
                  />
                </div>
                <BorrowCurrencyTable listData={borrowAssetsData} isLoading={isLoading} />
              </div>
            )}
          </>
        )}
        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </div>
    </ScreenWrapper>
  );
}
