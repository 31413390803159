import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ZapModal {
    &.ReactModal__Content {
      max-width: 950px !important;
      @include respond-to(xl) {
        max-width: 750px;
      }
    }

    &__inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &__caption-inner {
      margin-bottom: 50px;
      @include respond-to(xl) {
        margin-bottom: 30px;
      }
      h2 {
        font-size: $large;
        @include respond-to(xl) {
          font-size: $regular;
        }
      }
    }
  }
`;

export default staticStyles;
