import React from 'react';
import { useIntl } from 'react-intl';
import { TooltipName } from 'src/components/Tooltip';
import BasicTableWrapper from '../../../../components/Wrappers/Table/BasicTableWrapper';
import TableColumnWrapper from '../../../../components/Wrappers/Table/TableColumnWrapper';
import TableHeaderWrapper from '../../../../components/Wrappers/Table/TableHeaderWrapper';
import TextWithInfoWrapper from '../../../../components/Wrappers/TextWithInfoWrapper';
import DepositAssetItem from './LendCurrencyItem';
import messages from './messages';

import staticStyles from './style';
import { LendCurrencyProps } from './types';

interface LendCurrencyTableProps {
  listData: LendCurrencyProps[];
  isLoading: boolean;
}

export default function LendCurrencyTable({ listData, isLoading }: LendCurrencyTableProps) {
  const intl = useIntl();
  const columns: {
    title: string;
    minWidth?: number;
    tooltipName?: TooltipName;
  }[] = [
    {
      title: intl.formatMessage(messages.asset),
      minWidth: 315,
    },
    {
      title: intl.formatMessage(messages.balance),
      tooltipName: 'balance',
    },
    {
      title: intl.formatMessage(messages.apy),
      tooltipName: 'depositAPY',
    },
    {
      title: intl.formatMessage(messages.avgAPY),
      tooltipName: 'averageAPY',
    },
  ];

  return (
    <BasicTableWrapper
      className={'LendCurrencyTable'}
      headerColumns={
        <TableHeaderWrapper>
          {columns.map((column, index) => (
            <TableColumnWrapper key={index} minWidth={column.minWidth}>
              {column.tooltipName ? (
                <TextWithInfoWrapper
                  text={column.title}
                  tooltipName={column.tooltipName}
                  className={'TableHeaderWrapper__title'}
                />
              ) : (
                <p className={'TableHeaderWrapper__title'}>{column.title}</p>
              )}
            </TableColumnWrapper>
          ))}
        </TableHeaderWrapper>
      }
    >
      {isLoading
        ? Array(3)
            .fill({})
            .map((item) => <DepositAssetItem {...item} key={item.symbol} isLoading={isLoading} />)
        : listData.map((item) => <DepositAssetItem {...item} key={item.symbol} />)}

      <style jsx={true}>{staticStyles}</style>
    </BasicTableWrapper>
  );
}
