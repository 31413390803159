import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .MarketSwitcher {
    display: flex;

    .DropdownWrapper__content {
      background-color: var(--dropdown-background-color);
    }

    &__button {
      box-shadow: var(--box-shadow);
      border-radius: 8px;
      position: relative;
      background-color: var(--dropdown-background-color);
    }
    &__button-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      border: 1px solid transparent;
      border-radius: 1px;

      p {
        font-size: 9px;
        letter-spacing: 3px;
        position: relative;
        left: 2px;
        font-weight: 300;
        margin-top: 1px;
      }
    }

    &__button-text {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
    }

    &__buttonLogo-inner {
      @include body_16 display: flex;
      align-items: center;
      padding-right: 12px;
      color: var(--selected-color);

      img {
        width: 24px;
        max-height: 24px;
        padding: 8px;
        margin: 0 auto;
      }
    }
    &__button-outlinedLogo {
      padding-right: 8px;
      display: flex;

      img {
        width: 16px;
        height: 16px;
      }
    }

    &__content {
      width: 218px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 0px;
    }
    &__market:active {
      background-color: white;
    }
    &__market:hover {
      background: #f7f8ff;
    }
    &__title {
      padding: 8px 16px;
      @include body_16;
      color: var(--not-selected-color);
    }

    &__marketsWrapper {
      height: auto;
    }

    &__market {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      padding: 8px 16px;
    }

    &__market-content {
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%;

      @include body_16;
      color: var(--selected-color);
      &-inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
        padding-top: 4px;
      }
      img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }

    @keyframes animate {
      0% {
        background-position: 0 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }
  }
`;

export default staticStyles;
