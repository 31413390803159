import { defineMessages } from 'react-intl';

export default defineMessages({
  reserveSize: 'Reserve size',
  utilizationRate: 'Utilization rate',
  totalBorrowed: 'Total borrowed',
  availableLiquidity: 'Available liquidity',
  depositAPY: 'Deposit Apy',
  borrowAPY: 'Borrow Apy',
});
