import classNames from 'classnames';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import staticStyles from './style';

interface SkeletonWrapperProps {
  width?: number;
  height?: number;
  count?: number;
  className?: string;
}

export default function SkeletonWrapper({ width, height, count, className }: SkeletonWrapperProps) {
  return (
    <SkeletonTheme
      baseColor="var(--skeleton-base-color)"
      highlightColor="var(--skeleton-highlight-color)"
    >
      <div className={classNames('SkeletonWrapper', className)}>
        <Skeleton
          style={{
            width: width ? width + 'px' : '100%',
            height: height ? height + 'px' : '100%',
            margin: count ? '10px 0' : '0 0',
          }}
          count={count}
        />
        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </div>
    </SkeletonTheme>
  );
}
