import { useIntl } from 'react-intl';

import BasicTableWrapper from 'src/components/Wrappers/Table/BasicTableWrapper';
import TableColumnWrapper from 'src/components/Wrappers/Table/TableColumnWrapper';
import TableHeaderWrapper from 'src/components/Wrappers/Table/TableHeaderWrapper';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';
import { TooltipName } from 'src/components/Tooltip';
import TableItem from './tableItem';
import messages from './messages';

import staticStyles from './style';
import { LendBorrowTableItemProps } from './types';

interface LendBorrowAssetTableProps {
  listData: LendBorrowTableItemProps[];
  isLoading: boolean;
}

export default function LendBorrowAssetTable({ listData, isLoading }: LendBorrowAssetTableProps) {
  const intl = useIntl();

  const columns: {
    title: string;
    minWidth?: number;
    tooltipName?: TooltipName;
  }[] = [
    {
      title: intl.formatMessage(messages.asset),
    },
    {
      title: intl.formatMessage(messages.totalAndBorrowed),
      minWidth: 180,
      tooltipName: 'totalDepositAndBorrowed',
    },
    {
      title: intl.formatMessage(messages.utilRate),
      tooltipName: 'utilizationRate',
    },
    {
      title: intl.formatMessage(messages.walletBalance),
      tooltipName: 'walletBalance',
    },
    {
      title: intl.formatMessage(messages.depositAPY),
      tooltipName: 'depositAPY',
    },
    {
      title: intl.formatMessage(messages.borrowAPY),
      tooltipName: 'borrowAPYForAsset',
    },
  ];

  return (
    <BasicTableWrapper
      className={'LendBorrowAssetTable'}
      headerColumns={
        <TableHeaderWrapper>
          {columns.map((column, index) => (
            <TableColumnWrapper key={index} minWidth={column.minWidth}>
              {column.tooltipName ? (
                <TextWithInfoWrapper
                  className="TableHeaderWrapper__title"
                  text={column.title}
                  tooltipName={column.tooltipName}
                />
              ) : (
                <p className="TableHeaderWrapper__title">{column.title}</p>
              )}
            </TableColumnWrapper>
          ))}
        </TableHeaderWrapper>
      }
    >
      {isLoading
        ? Array(3)
            .fill({})
            .map((item, index) => <TableItem {...item} key={index} isLoading={isLoading} />)
        : listData.map((item, index) => <TableItem {...item} key={index} />)}

      <style jsx={true}>{staticStyles}</style>
    </BasicTableWrapper>
  );
}
