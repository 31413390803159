import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .BorrowPositionWrapper {
    min-width: 125px;
    display: flex;
    justify-content: flex-end;

    .DropdownWrapper {
      display: flex;
      align-items: center;
      justify-contents: center;
    }
    .DropdownWrapper__content {
      background-color: var(--dropdown-background-color);
    }
    .DropdownWrapper__button {
      box-shadow: none;
      background: none;
    }

    &__button-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      border: 1px solid transparent;
      border-radius: 1px;

      .TokenIcon__image {
        margin-right: 8px;
      }

      p,
      span {
        @include body_16;
        color: var(--selected-color);
        margin: 0;
      }
    }

    &__content {
      // width: 240px;
      padding: 8px 0px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      position: relative;
    }

    &__item {
      padding: 0 16px;
      &__content {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        @include body_16;
        color: var(--selected-color);
      }
    }

    &__item:active {
      background-color: white;
    }
    &__item:hover {
      background-color: #f7f8ff;
    }
  }
`;

export default staticStyles;
