import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ActionButton {
    box-sizing: border-box;
    flex: 1;
    height: 100%;
    border-radius: 41px;
    drop-shadow: var(--action-button-shadow);
    backdrop-filter: blur(4px);

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &__active {
      background: var(--action-button-background);
      cursor: default;
    }

    &__disabled {
      cursor: default;
    }

    &__title {
      @include body_14_sb;
      color: var(--selected-color);
    }

  }
`;

export default staticStyles;
