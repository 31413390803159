import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ErrorMsg {
    &__Wrapper {
      display: none;
      background: var(--error-background-color);

      padding: 8px 16px;

      p {
        @include body_14;
        color: var(--error-color);
        word-wrap: break-word;
      }
    }
    &__WrapperVisible {
      display: block;
    }
  }
`;

export default staticStyles;
