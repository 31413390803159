import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ChartWrapper {
    // pointer-events: none;
    width: fit-content;
    height: fit-content;
    overflow: clip;
    margin: 10px;

    &__header {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      &__title {
        display: flex;
        justify-content: space-between;

        &__content {
          @include body_16;
          display: flex;

          .TextWithInfoWrapper {
            color: var(--selected-color);
          }

          .TokenIcon__image {
            margin-right: 8px;
          }
        }
        &__apy {
          @include body_20_sb;
          color: var(--selected-color);
        }
      }

      &__period {
        display: flex;
        justify-content: space-between;

        &__apy {
          @include body_20_sb;
          color: var(--selected-color);
        }

        &__select {
          @include body_14_sb;
          color: var(--not-selected-color);
          display: flex;
          column-gap: 16px;

          span {
            cursor: pointer;
          }

          &__active {
            color: var(--selected-color);
          }
        }
      }

      &__diff {
        display: flex;
        justify-content: space-between;

        &__content {
          display: flex;
          align-items: center;
          column-gap: 8px;

          &__plusvalue {
            @include body_16_sb;
            color: var(--health-factor-color);
          }

          &__minusvalue {
            @include body_16_sb;
            color: var(--health-factor-color);
          }

          &__lastweek {
            @include body_14;
            color: var(--text2-color);
          }
        }

        &__select {
          @include body_14_sb;
          color: var(--not-selected-color);
          display: flex;
          column-gap: 16px;

          span {
            cursor: pointer;
          }

          &__active {
            color: var(--selected-color);
          }
        }

        &__expand {
          text-decoration: underline;
          cursor: pointer;
          @include respond-to(sm) {
            display: none;
          }
        }
      }
    }

    @include respond-to(sm) {
      width: 100%;
      margin: 0px;
    }
  }
`;

export default staticStyles;
