import classNames from 'classnames';
import React, { ReactNode } from 'react';

import staticStyles from './style';

interface InfoPanelWrapperProps {
  title: ReactNode;
  className?: string;
  children?: ReactNode;
  minWidth?: number;
}

export default function InfoPanelWrapper({
  title,
  className,
  children,
  minWidth,
}: InfoPanelWrapperProps) {
  return (
    <div className={classNames('InfoPanelWrapper', className)}>
      <div className={'InfoPanelWrapper__title'}>{title}</div>
      <div className={'InfoPanelWrapper__content'}>{children}</div>

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
