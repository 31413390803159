import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  calculateHealthFactorFromBalancesBigUnits,
  GasResponse,
  InterestRate,
  normalize,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';

import DefaultButton from 'src/components/basic/DefaultButton';
import { OverviewDataType } from 'src/components/TransactionOverviewPanel/types';
import TransactionOverviewPanel from 'src/components/TransactionOverviewPanel';
import AmountBoxWrapper from 'src/components/Wrappers/AmountBoxWrapper';

import staticStyles from './style';
import { useTxBuilderContext } from 'src/libs/tx-provider';
import PoolTxConfirmationView, { EmptyTransaction } from 'src/components/PoolTxConfirmationView';
import ConnectButton from 'src/components/ConnectButton';
import { useNavigate } from 'react-router-dom';
import { ValidationWrapperComponentProps } from 'src/components/RouteParamsValidationWrapper';
import useDebounce from 'src/libs/hooks/use-debounce';

interface BorrowMainProps
  extends Pick<ValidationWrapperComponentProps, 'currencySymbol' | 'poolReserve' | 'user'> {}

export default function BorrowMain({ currencySymbol, user, poolReserve }: BorrowMainProps) {
  const navigate = useNavigate();
  const { lendingPool } = useTxBuilderContext();
  const [amount, setAmount] = useState('0');
  const [maxAmount, setMaxAmount] = useState('0');
  const [fetchGasData, setFetchGasData] = useState<GasResponse>();
  const interestRateMode = InterestRate.Variable;
  const debouncedAmount = useDebounce<string>(amount || '0', 500);
  const isAmountAvailable = useMemo(
    () => valueToBigNumber(debouncedAmount).gt('0'),
    [debouncedAmount]
  );
  const isMaxAmountAvailable = useMemo(() => valueToBigNumber(maxAmount).gt('0'), [maxAmount]);

  useEffect(() => {
    if (user) {
      let userAvailableAmountToBorrow = valueToBigNumber(
        normalize(user.totalBorrowAvailableETH, -18)
      )
        .div(poolReserve.priceInEth)
        .toString();
      setMaxAmount(userAvailableAmountToBorrow);
    }
  }, []);

  const borrowOverviewData: OverviewDataType = useMemo(() => {
    let healthFactorAfter = user?.healthFactor;
    const amountInETH = valueToBigNumber(debouncedAmount).multipliedBy(poolReserve.priceInEth);

    if (user) {
      healthFactorAfter = calculateHealthFactorFromBalancesBigUnits(
        user.totalCollateralETH,
        valueToBigNumber(user.totalBorrowsETH).plus(normalize(amountInETH, 18)),
        user.currentLiquidationThreshold
      ).toString();
    }

    return {
      transactionType: 'borrow',
      assetType: 'borrowable',
      assetSymbol: currencySymbol,
      healthFactor: user?.healthFactor || '0',
      healthFactorAfter: healthFactorAfter,
      fetchGasData,
    };
  }, [debouncedAmount, fetchGasData]);

  const handleGetTransactions = useCallback(async () => {
    if (valueToBigNumber(maxAmount).gt(0) && user) {
      return await lendingPool.borrow({
        interestRateMode,
        referralCode: '0',
        user: user.id,
        amount: debouncedAmount.toString(),
        reserve: poolReserve.underlyingAsset,
        debtTokenAddress:
          interestRateMode === InterestRate.Variable ? poolReserve.debtTokenAddress : '',
      });
    }
    return EmptyTransaction;
  }, [maxAmount, debouncedAmount]);

  const handleMainTxCompleted = () => {
    navigate('/dashboard');
  };

  const handleGasPriceChanged = (gas: GasResponse) => {
    setFetchGasData(() => gas);
  };

  return (
    <div className="BorrowMain">
      <AmountBoxWrapper
        symbols={[`${currencySymbol}`]}
        value={amount}
        title={'Amount'}
        hasMax={true}
        maxValue={maxAmount}
        onChange={setAmount}
      />
      <TransactionOverviewPanel overviewData={borrowOverviewData} />
      {!user && <ConnectButton type="button" />}
      {user && isMaxAmountAvailable && !isAmountAvailable && (
        <DefaultButton title="Enter amount" type="primary" color="blue" disabled={true} />
      )}
      {user && isMaxAmountAvailable && isAmountAvailable && (
        <PoolTxConfirmationView
          mainTxName={'borrow'}
          currencySymbol={currencySymbol}
          getTransactionsData={handleGetTransactions}
          hideActionWrapper={!isAmountAvailable}
          onActiveTxChanged={handleGasPriceChanged}
          onMainTxConfirmed={handleMainTxCompleted}
        />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
