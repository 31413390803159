import ScreenWrapper from '../../../components/Wrappers/ScreenWrapper';
import LendCurrencyWrapper from '../components/LendCurrencyWrapper';
import { StatsDataType } from '../../../components/ReserveOverviewPanel/StatsPanel/types';
import { ReserveOverviewDataType } from '../../../components/ReserveOverviewPanel/types';
import ReserveOverviewPanel from '../../../components/ReserveOverviewPanel';

import staticStyles from './style';
import {
  BorrowableReserveMarketData,
  CollateralReserveMarketData,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from 'src/components/RouteParamsValidationWrapper';

function Lend(props: ValidationWrapperComponentProps) {
  const { action, currencySymbol, poolReserve } = props;

  const transactionType = action === 'deposit' || action === 'withdraw' ? action : 'deposit';

  const availableLiquidityInUsd = (poolReserve as BorrowableReserveMarketData)
    .availableLiquidityInUSD;

  const totalBorrowsInUSD = valueToBigNumber(poolReserve.totalLiquidityInUSD).minus(
    availableLiquidityInUsd
  );
  const statsData: StatsDataType = {
    transactionType: transactionType,
    assetType: 'borrowable',
    utilizationRate: (poolReserve as BorrowableReserveMarketData).utilizationRatio,
    totalBorrowed: totalBorrowsInUSD.toString(),
    availableLiquidity: availableLiquidityInUsd,
    APY: poolReserve.depositAPY,
  };

  const reserveOverviewData: ReserveOverviewDataType = {
    assetSymbol: currencySymbol,
    assetType: 'borrowable',
    statsData: statsData,
    chartData: (poolReserve as CollateralReserveMarketData).ratesData,
  };

  return (
    <ScreenWrapper>
      <div className="Lend">
        <LendCurrencyWrapper className="Lend__left-content" {...props} />
        <ReserveOverviewPanel
          data={reserveOverviewData}
          className="Lend__right-content"
        ></ReserveOverviewPanel>
        <style jsx={true} global={true}>
          {staticStyles}
        </style>
      </div>
    </ScreenWrapper>
  );
}

export default routeParamValidationHOC({
  Component: Lend,
  withWalletBalance: true,
});
