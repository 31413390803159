import classNames from 'classnames';
import React, { useState } from 'react';
import ReactSlider from 'react-slider';

import staticStyles from './style';

interface SliderWrapperProps {
  marks?: number;
  min?: number;
  max?: number;
  value: number;
  defaultValue?: number;
  className?: string;
  onClick?: (value: number) => void;
}

export default function SliderWrapper({
  marks,
  min,
  max,
  value,
  defaultValue,
  className,
  onClick,
}: SliderWrapperProps) {
  // const [currentValue, setCurrentValue] = useState(defaultValue || 0);

  const onSlider = (value: number) => {
    // setCurrentValue(value);
    onClick && onClick(value);
  };

  return (
    <>
      <ReactSlider
        className={classNames('SliderWrapper', className)}
        thumbClassName="SliderWrapper__thumb"
        trackClassName="SliderWrapper__track"
        marks={marks || 20}
        min={min || 0}
        max={max || 100}
        defaultValue={defaultValue || 0}
        value={value}
        onChange={onSlider}
      />
      <style jsx={true}>{staticStyles}</style>
    </>
  );
}
