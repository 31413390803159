import classNames from 'classnames';
import { ReactNode } from 'react';
import staticStyles from './style';
import Tooltip, {
  TooltipName,
  TooltipHorizontalPosition,
  TooltipVerticalPosition,
} from 'src/components/Tooltip';

interface TextWithInfoWrapperProps {
  text: string | ReactNode;
  infoPosition?: 'left' | 'right';
  tooltipName?: TooltipName;
  tooltipVertical?: TooltipVerticalPosition;
  tooltipHorizontal?: TooltipHorizontalPosition;
  tooltipData?: string;
  className?: string;
}

export default function TextWithInfoWrapper({
  text,
  infoPosition = 'right',
  tooltipName,
  tooltipVertical,
  tooltipHorizontal,
  tooltipData,
  className,
}: TextWithInfoWrapperProps) {
  return (
    <div
      className={classNames(
        'TextWithInfoWrapper',
        className,
        `TextWithInfoWrapper__${infoPosition}`
      )}
    >
      {infoPosition === 'left' ? (
        <>
          <Tooltip
            tooltipName={tooltipName}
            verticalPosition={tooltipVertical}
            horizontalPosition={tooltipHorizontal}
            tooltipData={tooltipData}
          />
          &nbsp;{text}
        </>
      ) : (
        <>
          {text}
          <Tooltip
            tooltipName={tooltipName}
            verticalPosition={tooltipVertical}
            horizontalPosition={tooltipHorizontal}
            tooltipData={tooltipData}
          />
        </>
      )}
      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
