import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .SliderWrapper {
    margin: auto;
    min-height: 16px;

    &__track {
      top: 6px;
      height: 4px;
    }

    &__track-0 {
      background: var(--slider-color);
    }

    &__track-1 {
      background: $leverage_color;
    }

    &__thumb {
      cursor: pointer;
      background: var(--dropdown-background-color);
      border: 4px solid var(--leverage-color);
      width: 16px;
      height: 16px;
      border-radius: 100%;
      outline: none;

      :hover {
        box-shadow: 0 0 0 2px var(--leverage-color);
      }
    }
  }
`;

export default staticStyles;
