/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer } from 'ethers';
import { Provider } from '@ethersproject/providers';

import type { ICurvePoolInfo } from './ICurvePoolInfo';

export class ICurvePoolInfoFactory {
  static connect(address: string, signerOrProvider: Signer | Provider): ICurvePoolInfo {
    return new Contract(address, _abi, signerOrProvider) as ICurvePoolInfo;
  }
}

const _abi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: '_pool',
        type: 'address',
      },
    ],
    name: 'get_pool_info',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256[8]',
            name: 'balances',
            type: 'uint256[8]',
          },
          {
            internalType: 'uint256[8]',
            name: 'underlying_balances',
            type: 'uint256[8]',
          },
          {
            internalType: 'uint256[8]',
            name: 'decimals',
            type: 'uint256[8]',
          },
          {
            internalType: 'uint256[8]',
            name: 'underlying_decimals',
            type: 'uint256[8]',
          },
          {
            internalType: 'uint256[8]',
            name: 'rates',
            type: 'uint256[8]',
          },
          {
            internalType: 'address',
            name: 'lp_token',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint256',
                name: 'A',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'future_A',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'fee',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'admin_fee',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'future_fee',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'future_admin_fee',
                type: 'uint256',
              },
              {
                internalType: 'address',
                name: 'future_owner',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'initial_A',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'initial_A_time',
                type: 'uint256',
              },
              {
                internalType: 'uint256',
                name: 'future_A_time',
                type: 'uint256',
              },
            ],
            internalType: 'struct ICurvePoolInfo.PoolParams',
            name: 'params',
            type: 'tuple',
          },
        ],
        internalType: 'struct ICurvePoolInfo.PoolInfo',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];
