import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .WalletCard {
    position: relative;
    margin: 5px;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      filter: blur(4px);
      border-radius: $borderRadius;
      transition: $transition;
    }
    &:disabled {
      box-shadow: none !important;
      &:after {
        display: none;
      }
      &:active {
        .WalletCard__image-inner {
          img {
            transform: scale(1);
          }
        }
      }
    }
    &:active {
      .WalletCard__image-inner {
        img {
          transform: scale(0.95);
        }
      }
    }

    &__error {
      font-size: $extraSmall;
      font-weight: 400;
      position: absolute;
      bottom: calc(100% + 5px);
      left: 0;
    }

    &__inner {
      width: 232px;
      height: 52px;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 4px;

      padding: 16px 14px;
    }

    &__image-inner {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 24px;
        max-height: 24px;
      }
    }

    &__text-inner {
      text-align: center;
      flex: 1;
      p {
        @include body_14;
        color: var(--selected-color);
      }
    }
  }
`;

// @ts-ignore
export default staticStyles;
