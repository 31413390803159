import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .WithdrawCollateralWrapper {
    box-shadow: var(--panel-box-shadow) !important;
    background: var(--panel-background-color) !important;

    &__title {
      @include body_20_sb;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      min-height: 70px;
      position: relative;
    }
    &__separate-line {
      position: absolute;
      height: 1px;
      left: 1px;
      right: 1px;
      top: 70px;
      background: var(--tabs-color);
    }
    &__content {
      padding: 0 24px;

      & > * {
        margin: 24px 0;
      }
    }
    @include respond-to(sm) {
      box-shadow: none !important;
      background: none !important;

      &__title {
        align-items: flex-start;
        padding-left: 45px;
        min-height: initial;
      }
      &__separate-line {
        display: none;
      }

      &__content {
        padding: 0;
      }
    }
  }
`;

export default staticStyles;
