import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .DropdownWrapper {
    position: relative;
    z-index: 2;

    &__button {
      box-shadow: var(--box-shadow);
      border-radius: 8px;
      position: relative;
      background-color: var(--dropdown-background-color);

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &__button-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      border: 1px solid transparent;
      border-radius: 1px;
    }

    &__button-outlinedLogo {
      padding-right: 8px;
      display: flex;

      img {
        width: 16px;
        height: 16px;
      }
    }

    &__bottom {
      .DropdownWrapper__content {
        transform-origin: 100% 0;
        top: calc(100% + 10px);
      }
    }

    &__top {
      .DropdownWrapper__content {
        transform-origin: 0 100%;
        bottom: calc(100% + 10px);
      }
    }

    &__left {
      .DropdownWrapper__content {
        left: 0;
      }
    }

    &__right {
      .DropdownWrapper__content {
        right: 0;
      }
    }

    &__center {
      .DropdownWrapper__content {
        left: 50%;
        transform: translateX(-50%) scaleY(0);
      }
      .DropdownWrapper__contentVisible {
        transform: translateX(-50%) scaleY(1);
      }
    }

    &__content {
      background-color: var(--dropdown-background-color);
      position: absolute;
      height: 0;
      // overflow: hidden;
      opacity: 0;
      transform: scaleY(0);
      transition-property: transform, opacity;
      transition-duration: 0.3s;
      transition-timing-function: ease;

      box-sizing: border-box;

      /* Auto layout */
      background: white;
      border: 1px solid $tabs_color;
      box-shadow: 0px 0px 22px rgba(203, 207, 211, 0.1);
      backdrop-filter: blur(4px);
      border-radius: 16px;
    }
    &__contentVisible {
      height: auto;
      opacity: 1;
      transform: scaleY(1);
    }
  }
`;

export default staticStyles;
