import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  API_ETH_MOCK_ADDRESS,
  BigNumber,
  calculateHealthFactorFromBalancesBigUnits,
  CollateralReserveMarketData,
  GasResponse,
  Network,
  normalize,
  pow10,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';
import GeneralVaultInterface from '@sturdyfi/sturdy-js/dist/tx-builder/interfaces/v1/GeneralVault';

import DefaultButton from 'src/components/basic/DefaultButton';
import ConnectButton from 'src/components/ConnectButton';
import PoolTxConfirmationView, { EmptyTransaction } from 'src/components/PoolTxConfirmationView';
import { ValidationWrapperComponentProps } from 'src/components/RouteParamsValidationWrapper';
import TransactionOverviewPanel from 'src/components/TransactionOverviewPanel';
import { OverviewDataType } from 'src/components/TransactionOverviewPanel/types';
import AmountBoxWrapper from 'src/components/Wrappers/AmountBoxWrapper';
import { useProvideCollateralContext } from 'src/components/Wrappers/ScreensWrapper';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { useTxBuilderContext } from 'src/libs/tx-provider';

import staticStyles from './style';
import useDebounce from 'src/libs/hooks/use-debounce';

interface WithdrawMainProps
  extends Pick<
    ValidationWrapperComponentProps,
    'currencySymbol' | 'poolReserve' | 'user' | 'userReserve'
  > {}

export default function WithdrawMain({
  currencySymbol,
  user,
  poolReserve,
  userReserve,
}: WithdrawMainProps) {
  const navigate = useNavigate();
  const { networkConfig, currentMarketData } = useProtocolDataContext();
  const [amount, setAmount] = useState('0');
  const debouncedAmount = useDebounce<string>(amount || '0', 500);
  const [fetchGasData, setFetchGasData] = useState<GasResponse>();

  const symbols = useMemo(
    () => networkConfig.collateralAssets?.[currencySymbol] || [],
    [currencySymbol]
  );

  const {
    lidoVault,
    convexFRAX3CRVVault,
    convexDAIUSDCUSDTSUSDVault,
    convexIronBankVault,
    convexFRAXUSDCVault,
    convexMIM3CRVVault,
    convexTUSDFRAXBPVault,
    convexETHSTETHVault,
    auraWSTETHWETHVault,
    yearnVault,
    yearnWETHVault,
    yearnWBTCVault,
    yearnBOOVault,
    yearnFBeetsVault,
    yearnLINKVault,
    yearnSPELLVault,
    yearnCRVVault,
    tombFtmBeefyVault,
    tombMiMaticBeefyVault,
    basedMiMaticBeefyVault,
  } = useTxBuilderContext();
  const symbolToVault: { [key: string]: { [key: string]: GeneralVaultInterface } } = {
    [Network.ftm]: {
      WFTM: yearnVault,
      WETH: yearnWETHVault,
      WBTC: yearnWBTCVault,
      BOO: yearnBOOVault,
      fBEETS: yearnFBeetsVault,
      LINK: yearnLINKVault,
      SPELL: yearnSPELLVault,
      CRV: yearnCRVVault,
      TOMB_FTM_LP: tombFtmBeefyVault,
      TOMB_MIMATIC_LP: tombMiMaticBeefyVault,
      BASED_MIMATIC_LP: basedMiMaticBeefyVault,
    },
    [Network.ftm_test]: {
      WFTM: yearnVault,
      WETH: yearnWETHVault,
      WBTC: yearnWBTCVault,
      BOO: yearnBOOVault,
      fBEETS: yearnFBeetsVault,
      LINK: yearnLINKVault,
      SPELL: yearnSPELLVault,
      CRV: yearnCRVVault,
      TOMB_FTM_LP: tombFtmBeefyVault,
      TOMB_MIMATIC_LP: tombMiMaticBeefyVault,
      BASED_MIMATIC_LP: basedMiMaticBeefyVault,
    },
    [Network.mainnet]: {
      stETH: lidoVault,
      FRAX_3CRV_LP: convexFRAX3CRVVault,
      DAI_USDC_USDT_SUSD_LP: convexDAIUSDCUSDTSUSDVault,
      IRON_BANK_LP: convexIronBankVault,
      FRAX_USDC_LP: convexFRAXUSDCVault,
      MIM_3CRV_LP: convexMIM3CRVVault,
      TUSD_FRAXBP_LP: convexTUSDFRAXBPVault,
    },
    [Network.eth]: {
      ETH_STETH_LP: convexETHSTETHVault,
      BAL_WSTETH_WETH_LP: auraWSTETHWETHVault,
    },
  };

  const { tokenSymbol, setTokenSymbol } = useProvideCollateralContext();
  if (!networkConfig.collateralAssets?.[currencySymbol]?.includes(tokenSymbol))
    setTokenSymbol(currencySymbol);

  const handleTokenSwitcher = (token: string) => {
    setTokenSymbol(token);
  };

  const maxAmountToWithdraw = useMemo(() => {
    let maxAmountToWithdraw = valueToBigNumber(0);
    let maxCollateralToWithdrawInETH = valueToBigNumber(0);
    if (!userReserve || !user) return maxAmountToWithdraw;

    maxAmountToWithdraw = BigNumber.min(userReserve.underlyingBalance, poolReserve.totalLiquidity);
    if (valueToBigNumber(user.totalBorrowsETH).gt('0')) {
      const excessHF = valueToBigNumber(user.healthFactor).minus('1');
      const collateralReserve = poolReserve as CollateralReserveMarketData;
      if (excessHF.gt('0')) {
        maxCollateralToWithdrawInETH = excessHF
          .multipliedBy(user.totalBorrowsETH)
          // because of the rounding issue on the contracts side this value still can be incorrect
          .div(Number(user.currentLiquidationThreshold) + 0.01)
          .multipliedBy('0.99');

        maxAmountToWithdraw = BigNumber.min(
          maxAmountToWithdraw,
          maxCollateralToWithdrawInETH
            .multipliedBy(pow10(18))
            .dividedBy(collateralReserve.priceInEth)
        );
      }
    }
    return maxAmountToWithdraw;
  }, []);

  const handleGetTransactions = useCallback(async () => {
    if (valueToBigNumber(debouncedAmount).gt('0') && user) {
      let vault = lidoVault;
      for (const [key, value] of Object.entries(symbolToVault?.[currentMarketData.network])) {
        if (networkConfig.collateralAssets?.[key]?.includes(tokenSymbol)) {
          vault = value;
          break;
        }
      }

      return await vault.withdrawCollateral({
        _user: user.id,
        _asset:
          tokenSymbol === networkConfig.baseAsset
            ? API_ETH_MOCK_ADDRESS
            : networkConfig.collateralAddresses?.[tokenSymbol] || poolReserve.underlyingAsset,
        _amount: debouncedAmount,
        _to: user.id,
      });
    }
    return EmptyTransaction;
  }, [debouncedAmount, tokenSymbol]);

  const handleMainTxCompleted = () => {
    navigate('/dashboard');
  };

  const txOverviewData: OverviewDataType = useMemo(() => {
    const remainedAmount = valueToBigNumber(userReserve?.underlyingBalance || '0')
      .minus(debouncedAmount)
      .toString();
    let healthFactorAfter = user?.healthFactor;
    const amountInETH = valueToBigNumber(debouncedAmount).multipliedBy(poolReserve.priceInEth);

    if (user) {
      const totalCollateralETHAfter = valueToBigNumber(user.totalCollateralETH).minus(
        normalize(amountInETH, 18)
      );
      const liquidationThresholdAfter = valueToBigNumber(user.totalCollateralETH)
        .multipliedBy(user.currentLiquidationThreshold)
        .minus(
          normalize(
            amountInETH.multipliedBy(
              (poolReserve as CollateralReserveMarketData).liquidationThreshold
            ),
            18
          )
        )
        .dividedBy(totalCollateralETHAfter);

      healthFactorAfter = calculateHealthFactorFromBalancesBigUnits(
        totalCollateralETHAfter,
        user.totalBorrowsETH,
        liquidationThresholdAfter
      ).toString();
      if (valueToBigNumber(healthFactorAfter).lt(0)) healthFactorAfter = Infinity.toString();
    }

    return {
      transactionType: 'withdraw',
      assetType: 'collateral',
      assetSymbol: currencySymbol,
      assetAmount: remainedAmount,
      APY: (poolReserve as CollateralReserveMarketData).depositAPY,
      avgAPY: userReserve?.avgAPY,
      healthFactor: healthFactorAfter,
      fetchGasData,
    };
  }, [debouncedAmount, fetchGasData]);

  const handleGasPriceChanged = (gas: GasResponse) => {
    setFetchGasData(() => gas);
  };

  const isAmountAvailable = useMemo(() => valueToBigNumber(amount).gt('0'), [debouncedAmount]);

  return (
    <div className="WithdrawMain">
      <AmountBoxWrapper
        symbols={symbols.map((symbol) => symbol)}
        value={amount}
        title={'Amount'}
        hasMax={true}
        maxValue={maxAmountToWithdraw.toString()}
        onChange={setAmount}
        selectedSymbol={tokenSymbol}
        onChangeSymbol={handleTokenSwitcher}
      />
      <TransactionOverviewPanel overviewData={txOverviewData} />
      {!user && <ConnectButton type="button" />}
      {user && !isAmountAvailable && (
        <DefaultButton title="Enter amount" type="primary" color="blue" disabled={true} />
      )}
      {user && isAmountAvailable && (
        <PoolTxConfirmationView
          mainTxName={'withdraw'}
          currencySymbol={tokenSymbol}
          getTransactionsData={handleGetTransactions}
          hideActionWrapper={valueToBigNumber(amount).eq('0')}
          onActiveTxChanged={handleGasPriceChanged}
          onMainTxConfirmed={handleMainTxCompleted}
        />
      )}
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
