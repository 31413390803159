import { useIntl } from 'react-intl';
import messages from '../messages';

export default function HowLeverageWorking() {
  const intl = useIntl();

  const renderAdditionalDescription = () => {
    return (
      <div className="Tooltip__additional-description">
        <span>&#9432;</span>
        <span>{intl.formatMessage(messages.howLeverageWorkingDesc3)}</span>
      </div>
    );
  };

  const renderContent = () => {
    const description1 = intl.formatMessage(messages.howLeverageWorkingDesc1);
    const description2 = intl.formatMessage(messages.howLeverageWorkingDesc2);
    const smallParts = description2.split('\n');

    return (
      <div className="Tooltip__content">
        <p>{description1}</p>
        <p>
          {smallParts.map((str, id) => (
            <>
              <span key={id}>{str}</span>
              <br />
            </>
          ))}
        </p>
        {renderAdditionalDescription()}
      </div>
    );
  };

  return (
    <>
      <div className="Tooltip__title">{intl.formatMessage(messages.howLeverageWorking)}</div>
      {renderContent()}
    </>
  );
}
