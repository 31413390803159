import classNames from 'classnames';
import PanelWrapper from 'src/components/Wrappers/PanelWrapper';
import staticStyles from './style';

interface TxErrorProps {
  failed?: string;
  className?: string;
}

export default function TxError({ failed, className }: TxErrorProps) {
  return (
    <PanelWrapper className={classNames('TxError', className)}>
      <div
        className={classNames('TxError__Wrapper', {
          TxError__WrapperVisible: failed,
        })}
      >
        <p>{failed}</p>
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </PanelWrapper>
  );
}
