import { Network } from '@sturdyfi/sturdy-js';
import { MarketDataType } from '../../helpers/markets/types';

import * as logos from './images';

export enum CustomMarket {
  proto_mainnet = 'proto_mainnet',
  proto_eth = 'proto_eth',
  proto_fork = 'proto_fork',
  proto_ftm = 'proto_ftm',
  proto_ftm_test = 'proto_ftm_test',
}

export const marketsData: { [key in keyof typeof CustomMarket]: MarketDataType } = {
  [CustomMarket.proto_mainnet]: {
    network: Network.mainnet,
    logo: logos.eth_stable,
    activeLogo: logos.eth_stable,
    aTokenPrefix: 's',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
    },
    flashloanFee: 0,
    flashloanSlippage: 0.02,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0xb7499a92fc36e9053a4324aFfae59d333635D9c3',
      LENDING_POOL: '0xA422CA380bd70EeF876292839222159E41AAEe17',
      LIDO_VAULT: '0x01c05337354aae5345d27d2A4A70B56a17aF2b4a',
      CONVEX_FRAX_3CRV_VAULT: '0xA77D72A6C3fA97F091E9aa9F0C87e9efCB552628',
      CONVEX_DAI_USDC_USDT_SUSD_VAULT: '0x9700E0B9d22fE10fF00170462c98aBfFa2505DE2',
      CONVEX_IRON_BANK_VAULT: '0xCD1B342cd04A29D7ae86aE54FfCCB8b4580a7192',
      CONVEX_FRAX_USDC_VAULT: '0xf8C91E5BfFDD79Ef7D1d6F9ae3391E021fFCBD73',
      CONVEX_MIM_3CRV_VAULT: '0xe2Cac13bbff65d30BA31C1709B73c60573cFbDA0',
      CONVEX_TUSD_FRAXBP_VAULT: '0x994A8C1AD6C6A65E8D8c6b37A9c8F964A6C985A8',
      CONVEX_FRAX_3CRV_LEVSWAPPER: '0x812F220f7e79B3Df1EF7bF9Df0d5AbeAb58f95E3',
      CONVEX_DAI_USDC_USDT_SUSD_LEVSWAPPER: '0xB0E64f8E2F735857CE9441356623452E72Fb4d5f',
      CONVEX_FRAX_USDC_LEVSWAPPER: '0xcEA4FB7D31856F1Ec25Cad53a519039753B01fd1',
      CONVEX_IRON_BANK_LEVSWAPPER: '0x01Eac83b91158e09EaE57a86eE4538134ff47e26',
      CONVEX_MIM_3CRV_LEVSWAPPER: '0x4b00AbCA8276Aef29adA38f8b4fC386203F71bc4',
      CONVEX_TUSD_FRAXBP_LEVSWAPPER: '0xaaCb05194c7864519116493C5d42353D2b658ecF',
    },
  },
  [CustomMarket.proto_eth]: {
    network: Network.eth,
    logo: logos.eth,
    activeLogo: logos.eth,
    aTokenPrefix: 's',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
    },
    flashloanFee: 0,
    flashloanSlippage: 0.02,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x40ea6e14800F6040D25A9a5a1E9cC0F5Cb6D1066',
      LENDING_POOL: '0x9f72DC67ceC672bB99e3d02CbEA0a21536a2b657',
      WETH_GATEWAY: '0xE27F1a136C0114ADed15b24b576aD7F71483dCCa',
      CONVEX_ETH_STETH_VAULT: '0xa36BE47700C079BD94adC09f35B0FA93A55297bc',
      AURA_WSTETH_WETH_VAULT: '0x6AE5Fd07c0Bb2264B1F60b33F65920A2b912151C',
      CONVEX_ETH_STETH_LEVSWAPPER: '0x638B129Ab6d241Ec2F890Aa3af197bF84b5FF1bB',
      AURA_WSTETH_WETH_LEVSWAPPER: '0xf1859145906b08C66fB99E167a1406aC00a2079E',
    },
  },
  [CustomMarket.proto_fork]: {
    network: Network.fork,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 'a',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
    },
    flashloanFee: 0,
    flashloanSlippage: 0.02,
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x3a5e7db2E0EA9e69fB53Cd8582e64D4001746E8c'.toLowerCase(),
      LENDING_POOL: '0x2A4d822BFB34d377c978F28a6C332Caa2fF87530',
      LIDO_VAULT: '0x01c05337354aae5345d27d2A4A70B56a17aF2b4a',
      CONVEX_FRAX_3CRV_VAULT: '0xb2E308Ef09697ed2b2d740c769CD3b0246DD1e6c',
      CONVEX_DAI_USDC_USDT_SUSD_VAULT: '0xF44275a19A24D016364ddD9541FC9B17735De2f2',
      CONVEX_IRON_BANK_VAULT: '0x8c30CE2bCE4523681DA9644cA63e1E3f5f41c3d6',
      CONVEX_FRAX_USDC_VAULT: '0xAd7D921B94aDA77A38AbE4c70049574b639BBEBA',
      CONVEX_MIM_3CRV_VAULT: '0xfDEba3d5Fd0819864FD652f5d6b0beC7a3DE5BF8',
      CONVEX_TUSD_FRAXBP_VAULT: '0x994A8C1AD6C6A65E8D8c6b37A9c8F964A6C985A8',
      CONVEX_FRAX_3CRV_LEVSWAPPER: '0x7bCeDa3f6554c5fC7AD043840a8546DbE8b90402',
      CONVEX_DAI_USDC_USDT_SUSD_LEVSWAPPER: '0xFa73E18353C9a44b677090708E46e7d1bCed2515',
      CONVEX_FRAX_USDC_LEVSWAPPER: '0x882A796B6f1f1679BBa1dd127EcE41Fd4f7aEacc',
      CONVEX_IRON_BANK_LEVSWAPPER: '0xD436e756Cf41318ADeC62E8dCbEF2608753Ae068',
      CONVEX_MIM_3CRV_LEVSWAPPER: '0xed60B470AD09D99aeF8363F52d75910Bd0079bBa',
      CONVEX_TUSD_FRAXBP_LEVSWAPPER: '0x72352d5Db60027a902a0AE6CF6815A11eB639Edb',
    },
  },
  [CustomMarket.proto_ftm]: {
    network: Network.ftm,
    logo: logos.ftm,
    activeLogo: logos.ftm,
    aTokenPrefix: 's',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x3B8569df88A70ECAE31a6bCA1fc3d51BD426189d'.toLowerCase(),
      LENDING_POOL: '0x7FF2520Cd7b76e8C49B5DB51505b842d665f3e9A',
      YEARN_VAULT: '0xc7506f6e48Aa399e571BDc0e15FC608F05375127',
      YEARN_WETH_VAULT: '0x51Dd37AD6BC71629b348F77ab5C978b7CeD931f3',
      YEARN_WBTC_VAULT: '0x94c5Cf77E26615bae9978a06dD8DE0e0a8F7A4EC',
      YEARN_BOO_VAULT: '0xc54cB7d967D139e4FcD414b4Eed2b0Fc19a1461E',
      TOMB_MIMATIC_BEEFY_VAULT: '0x3C0238B16dBA2D11Af549954abA9bfd75A074236',
      BASED_MIMATIC_BEEFY_VAULT: '0xbF9Cc228AEC3D5A80cA44E295E8f029E2D84d0AE',
      YEARN_FBEETS_VAULT: '0xEDb658f0Cf6FF9e25922500B9EC0c97a681B2559',
      YEARN_LINK_VAULT: '0x860544A91A432819C3DCF4B6BB7B6eFF8D2701c2',
      YEARN_SPELL_VAULT: '0x015DE603a68F2EC3A783bc609E1d20906D8Ec675',
      YEARN_CRV_VAULT: '0x5A427C7dE1E27133D292D23a00EBF365c672c87E',
    },
  },
  [CustomMarket.proto_ftm_test]: {
    network: Network.ftm_test,
    logo: logos.aavev2Logo,
    activeLogo: logos.aavev2ActiveLogo,
    aTokenPrefix: 's',
    enabledFeatures: {
      governance: false,
      staking: false,
      liquiditySwap: false,
      collateralRepay: false,
      incentives: false,
      faucet: true,
    },
    addresses: {
      LENDING_POOL_ADDRESS_PROVIDER: '0x808Ab7D913c2C6db826C7C83B9D2C1316E43E1cA'.toLowerCase(),
      LENDING_POOL: '0x7b07169cCB3CcEe717498e1Cefe6049De02f588D',
      YEARN_VAULT: '0xaAb1BCFF466ABa124327C3135D1F96C0fFefaE93',
      YEARN_WETH_VAULT: '0x6b9ba59c56f096d16D755DF3395b6b66d35d13e1',
      YEARN_WBTC_VAULT: '0x2dd4be604EC805E045bf7d014eBdAdD558cd248e',
      YEARN_BOO_VAULT: '0xDc202B24147910484e2D1b6f385FEf886cA87B6F',
      YEARN_FBEETS_VAULT: '',
      YEARN_LINK_VAULT: '',
      YEARN_SPELL_VAULT: '',
      YEARN_CRV_VAULT: '',
      TOMB_FTM_BEEFY_VAULT: '0x0196D782c84bB31B50C5349e9498D7975D127e4c',
      TOMB_MIMATIC_BEEFY_VAULT: '0x28Ceee5212aA73C4Ac4d635542fE97B4128A5dEf',
      BASED_MIMATIC_BEEFY_VAULT: '0x8706C35bDD49c6029b8113cf02C5544AA5E2375d',
      FAUCET: '0xaDeF6a09F84037ab69Ea88A9b5aa5CA689E58B82',
    },
  },
} as const;
