import Value from '../../basic/Value';
import NoData from '../../basic/NoData';
import ValuePercent from '../../basic/ValuePercent';
import SpinLoader from '../../basic/SpinLoader';
import InfoPanelWrapper from '../../Wrappers/InfoPanelWrapper';
import TextWithInfoWrapper from '../../Wrappers/TextWithInfoWrapper';

import { OverviewDataType } from '../types';

import staticStyles from './style';

interface LeverageOverviewPanelProps
  extends Pick<
    OverviewDataType,
    | 'borrowPositionComponent'
    | 'healthFactor'
    | 'APY'
    | 'price'
    | 'liquidationPrice'
    | 'gasEstimation'
    | 'loadingGasEstimation'
    | 'slippage'
  > {}

export default function LeverageOverviewPanel({
  healthFactor,
  APY,
  price,
  liquidationPrice,
  gasEstimation,
  loadingGasEstimation,
  slippage,
  borrowPositionComponent,
}: LeverageOverviewPanelProps) {
  return (
    <InfoPanelWrapper title={<span>Transaction overview</span>} className={'LeverageOverviewPanel'}>
      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Borrow Position'} tooltipName="borrowPosition" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {borrowPositionComponent ? borrowPositionComponent : <NoData />}
          </div>
        </div>
      </div>

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Health Factor'} tooltipName="healthFactor" />
          <div className={'LeverageOverviewPanel__item__content__value__healthFactor'}>
            {healthFactor ? <Value value={healthFactor} maximumValueDecimals={2} /> : <NoData />}
          </div>
        </div>
        <div className={'LeverageOverviewPanel__item__sub'}>{'Liquidation at < 1.0'}</div>
      </div>

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'APY'} tooltipName="depositAPYForCollateral" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {APY ? <ValuePercent value={APY} maximumDecimals={2} /> : <NoData />}
          </div>
        </div>
      </div>

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Current Price'} tooltipName="currentPrice" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {price ? (
              <Value
                value={price}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Liquidation Price'} tooltipName="liquidationPrice" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {liquidationPrice ? (
              <Value
                value={liquidationPrice}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'LeverageOverviewPanel__split'} />

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Gas Fee'} tooltipName="gasFee" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {loadingGasEstimation ? (
              <SpinLoader color="black" />
            ) : gasEstimation ? (
              <Value
                value={gasEstimation}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'LeverageOverviewPanel__item'}>
        <div className={'LeverageOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Price Slippage'} tooltipName="priceSlippage" />
          <div className={'LeverageOverviewPanel__item__content__value'}>
            {slippage ? (
              <u>
                <ValuePercent value={slippage} maximumDecimals={1} />
              </u>
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <style jsx={true}>{staticStyles}</style>
    </InfoPanelWrapper>
  );
}
