import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .LinkWrapper {
    display: flex;
    color: var(--selected-color);

    &__bold {
      font-weight: 600;
    }

    &__disabled {
      cursor: default;
      opacity: 0.5 !important;
      transform: scale(1) !important;
      &.ButtonLink {
        opacity: 1 !important;
      }
    }
  }
`;

export default staticStyles;
