import React, { ReactNode } from 'react';
import classNames from 'classnames';

import DropdownWrapper from '../../Wrappers/DropdownWrapper';
import staticStyles from './style';

interface SelectFieldProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  children: ReactNode;
  disabled?: boolean;
  value: ReactNode;
  placeholder?: string;
  className?: string;
}

export default function SelectField({
  visible,
  setVisible,
  children,
  disabled,
  value,
  placeholder,
  className,
}: SelectFieldProps) {
  return (
    <DropdownWrapper
      className={classNames('SelectField', { SelectField__active: visible }, className)}
      verticalPosition="bottom"
      horizontalPosition="left"
      buttonComponent={
        <span
          className={classNames('SelectField__select-value', {
            SelectField__selectValueActive: !!value,
          })}
        >
          {placeholder && !value ? placeholder : value}
        </span>
      }
    >
      <div className="SelectField__items">{children}</div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </DropdownWrapper>
  );
}
