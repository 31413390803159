import messages from './messages';
import staticStyles from './style';
import { useIntl } from 'react-intl';
import NoData from 'src/components/basic/NoData';
import Value from 'src/components/basic/Value';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';

interface HealthFactorCardProps {
  value?: number | string;
  isLoading: boolean;
}

export default function HealthFactorCard({ value, isLoading }: HealthFactorCardProps) {
  const intl = useIntl();

  return (
    <>
      <div className="DashboardCard__title">
        <TextWithInfoWrapper text={intl.formatMessage(messages.title)} tooltipName="healthFactor" />
      </div>
      <div className="DashboardCard__content">
        <div className="HealthFactor__value">
          {isLoading ? (
            <SkeletonWrapper width={100} height={30} />
          ) : value ? (
            <Value value={value} maximumValueDecimals={2} />
          ) : (
            <NoData />
          )}
        </div>
      </div>
      <style jsx={true}>{staticStyles}</style>
    </>
  );
}
