import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Markets {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__section {
      margin-bottom: 40px;
    }
    &__size {
      display: flex;
      justify-content: space-between;
      column-gap: 24px;
      margin-top: 24px;

      @include respond-to(sm) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
      }
    }
    &__table-description {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 24px;
      @include body_20;

      @include respond-to(sm) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 8px;
      }
    }
  }
`;

export default staticStyles;
