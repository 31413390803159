import classNames from 'classnames';

import { TooltipName } from 'src/components/Tooltip';
import Value from 'src/components/basic/Value';
import PanelWrapper from 'src/components/Wrappers/PanelWrapper';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';

import logo from 'src/images/logo.svg';

import staticStyles from './style';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';

interface MarketCardProps {
  title: string;
  value: number;
  primary?: boolean;
  tooltipName?: TooltipName;
  isLoading: boolean;
}

export default function MarketCard({
  title,
  value,
  primary,
  tooltipName,
  isLoading,
}: MarketCardProps) {
  return (
    <PanelWrapper className={classNames('MarketCard', { MarketCard__primary: primary })}>
      <div className="MarketCard__inner">
        <div className="MarketCard__title">
          <TextWithInfoWrapper text={title} tooltipName={tooltipName} />
        </div>
        <div className="MarketCard__value">
          {isLoading ? (
            <SkeletonWrapper width={150} height={30} />
          ) : (
            <>
              <span>$</span>
              <Value value={value.toString()} minimumValueDecimals={2} maximumValueDecimals={2} />
            </>
          )}
        </div>
      </div>
      {primary && (
        <div className="MarketCard__logo">
          <img src={logo} alt="logo" />
        </div>
      )}

      <style jsx={true}>{staticStyles}</style>
    </PanelWrapper>
  );
}
