import { useIntl } from 'react-intl';
import ValuePercent from 'src/components/basic/ValuePercent';
import NoData from 'src/components/basic/NoData';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';
import messages from './messages';
import staticStyles from './style';
import SkeletonWrapper from 'src/components/Wrappers/SkeletonWrapper';

interface AverageAPYCardProps {
  value?: number | string;
  isLoading: boolean;
}

export default function AverageAPYCard({ value, isLoading }: AverageAPYCardProps) {
  const intl = useIntl();

  return (
    <>
      <div className="DashboardCard__title">
        <TextWithInfoWrapper text={intl.formatMessage(messages.title)} tooltipName="averageAPY" />
      </div>
      <div className="DashboardCard__content">
        {isLoading ? (
          <SkeletonWrapper width={100} height={30} />
        ) : (
          <div className="AvgAPY__value">{value ? <ValuePercent value={value} /> : <NoData />}</div>
        )}
      </div>
      <style jsx={true}>{staticStyles}</style>
    </>
  );
}
