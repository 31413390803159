import classNames from 'classnames';

import staticStyles from './style';

interface ActionButtonProps {
  title: string;
  active: boolean;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
}

export default function ActionButton({
  title,
  active,
  disabled,
  onClick,
  className,
}: ActionButtonProps) {
  return (
    <div
      className={classNames(
        'ActionButton',
        { ActionButton__active: active, ActionButton__disabled: disabled },
        className
      )}
      onClick={disabled ? () => {} : onClick}
    >
      <span className="ActionButton__title">{title}</span>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
