import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .BorrowableStatsPanel {
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @include respond-to(sm) {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;
        }

        &__value {
          color: var(--selected-color);
          display: flex;
        }
      }
    }
  }
`;

export default staticStyles;
