import classNames from 'classnames';
import React, { ReactNode } from 'react';

import staticStyles from './style';

interface PanelWrapperProps {
  className?: string;
  children?: ReactNode;
  minWidth?: number;
}

export default function PanelWrapper({ className, children, minWidth }: PanelWrapperProps) {
  return (
    <section className={classNames('PanelWrapper', className)}>
      <div className={'PanelWrapper__content'}>{children}</div>

      <style jsx={true}>{staticStyles}</style>
    </section>
  );
}
