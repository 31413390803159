import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .AlertTemplate {
    @include body_16;
    color: var(--selected-color);
    min-width: 300px;
    display: flex;
    justify-content: space-between;
    padding: 15px;
    background: white;
    border: 1px solid var(--tabs-color);
    box-shadow: var(--box-shadow);
    backdrop-filter: blur(4px);
    border-radius: 16px;

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 4px;
      margin-right: 9px;
      min-width: 200px;

      &__detail {
        color: var(--text-color);
        cursor: pointer;
      }

      &__error {
        color: var(--error-color);
      }
    }

    &__close {
      cursor: pointer;
    }
  }
`;

export default staticStyles;
