import classNames from 'classnames';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useWeb3React } from '@web3-react/core';
import { providers } from 'ethers';

import { CustomMarket, marketsData } from 'src/ui-config';
import { availableMarkets, formattedNetwork } from 'src/config';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import {
  mapChainIdToName,
  mapNameToChainID,
  useUserWalletDataContext,
} from 'src/libs/web3-data-provider';
import DropdownWrapper from '../Wrappers/DropdownWrapper';

import messages from './messages';
import staticStyles from './style';

interface MarketSwitcherProps {
  className?: string;
}

export default function MarketSwitcher({ className }: MarketSwitcherProps) {
  const intl = useIntl();
  const { currentMarket, setCurrentMarket, currentMarketData } = useProtocolDataContext();
  const { handleNetworkChange } = useUserWalletDataContext();
  const { chainId } = useWeb3React<providers.Web3Provider>();

  const handleSetCurrentMarket = (market: CustomMarket) => {
    setCurrentMarket(market);
    handleNetworkChange(marketsData[market].network);
  };

  useEffect(() => {
    if (mapNameToChainID(currentMarketData.network) === chainId) return;
    const newMarket = Object.keys(marketsData).filter(
      (key) =>
        marketsData[key as keyof typeof CustomMarket].network ===
        mapChainIdToName(chainId as number)
    ) as CustomMarket[];
    if (newMarket.length && currentMarket !== newMarket[0]) {
      setCurrentMarket(newMarket[0]);
    }
  }, [chainId]);

  return (
    <DropdownWrapper
      className={classNames('MarketSwitcher', className)}
      horizontalPosition={'left'}
      verticalPosition={'bottom'}
      buttonComponent={
        <div className="MarketSwitcher__button-content">
          <div className="MarketSwitcher__button-text">
            <div className={'MarketSwitcher__buttonLogo-inner'}>
              <img
                src={
                  !!currentMarketData.activeLogo
                    ? currentMarketData.activeLogo
                    : currentMarketData.logo
                }
                alt="Sturdy"
              />
              {formattedNetwork(currentMarketData.network, true)}
            </div>
          </div>
        </div>
      }
    >
      <div className="MarketSwitcher__content">
        <p className="MarketSwitcher__title">{intl.formatMessage(messages.selectMarket)}</p>
        {availableMarkets.map((market) => {
          const marketData = marketsData[market];
          return (
            <button
              onClick={() => handleSetCurrentMarket(market)}
              className="MarketSwitcher__market"
              type="button"
              key={market}
            >
              <div className="MarketSwitcher__market-content">
                <div className="MarketSwitcher__market-content-inner">
                  <img src={marketData.logo} alt={market} />
                  {formattedNetwork(marketData.network)}
                </div>
              </div>
            </button>
          );
        })}
      </div>
      <style jsx={true}>{staticStyles}</style>
    </DropdownWrapper>
  );
}
