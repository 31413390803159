import React, { ReactNode, ReactNodeArray } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import staticStyles from './style';

import closeIcon from '../../../../images/closeIcon.svg';

import BasicModal from '../../../basic/BasicModal';

interface ConnectWalletWrapperProps {
  children: ReactNode | ReactNodeArray;
  className?: string;
  isVisible: boolean;
  onBackdropPress: () => void;
}

export default function ConnectWalletWrapper({
  children,
  className,
  isVisible,
  onBackdropPress,
}: ConnectWalletWrapperProps) {
  const intl = useIntl();

  return (
    <BasicModal
      onBackdropPress={onBackdropPress}
      isVisible={isVisible}
      title={intl.formatMessage(messages.caption)}
      withCloseButton={true}
      className={classNames('ConnectWalletWrapper', className)}
      closeIcon={closeIcon}
    >
      <div className="ConnectWalletWrapper__inner">{children}</div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicModal>
  );
}
