import { useIntl } from 'react-intl';
import { TooltipName } from 'src/components/Tooltip';

import BasicTableWrapper from 'src/components/Wrappers/Table/BasicTableWrapper';
import TableColumnWrapper from 'src/components/Wrappers/Table/TableColumnWrapper';
import TableHeaderWrapper from 'src/components/Wrappers/Table/TableHeaderWrapper';
import TextWithInfoWrapper from 'src/components/Wrappers/TextWithInfoWrapper';
import ZapAssetItem from './ZapAssetItem';
import messages from './messages';

import staticStyles from './style';
import { ZapAssetProps } from './types';

interface ZapAssetTableProps {
  listData: ZapAssetProps[];
  onSelectZapAsset: (symbol: string) => void;
}

export default function ZapAssetTable({ listData, onSelectZapAsset }: ZapAssetTableProps) {
  const intl = useIntl();
  const columns: {
    title: string;
    minWidth?: number;
    tooltipName?: TooltipName;
  }[] = [
    {
      title: intl.formatMessage(messages.asset),
      minWidth: 215,
    },
    {
      title: intl.formatMessage(messages.balance),
      tooltipName: 'walletBalance',
    },
  ];

  return (
    <BasicTableWrapper
      className={'ZapAssetTable'}
      headerColumns={
        <TableHeaderWrapper>
          {columns.map((column, index) => (
            <TableColumnWrapper key={index} minWidth={column.minWidth}>
              {column.tooltipName ? (
                <TextWithInfoWrapper
                  className="TableHeaderWrapper__title"
                  text={column.title}
                  tooltipName={column.tooltipName}
                />
              ) : (
                <p className="TableHeaderWrapper__title">{column.title}</p>
              )}
            </TableColumnWrapper>
          ))}
        </TableHeaderWrapper>
      }
    >
      {listData.map((item) => (
        <ZapAssetItem {...item} onSelectZapAsset={onSelectZapAsset} key={item.symbol} />
      ))}

      <style jsx={true}>{staticStyles}</style>
    </BasicTableWrapper>
  );
}
