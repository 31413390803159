import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .RiskBar {
    width: 274px;
    height: 77px;
    position: relative;
    &__Rect {
      position: absolute;
      width: 274px;
      height: 10px;
      left: 0;
      top: 0;

      background: linear-gradient(90.01deg, #fa2e2e 10.91%, #f99a2d 52.82%, #0cd988 87.82%);
      border-radius: 47px;

      &-indicator {
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        left: calc(20px);
        top: 1px;
        background: #ffffff;
      }
    }
    &__BaseLine {
      position: absolute;
      width: 0px;
      height: 28.5px;
      left: 22px;
      top: 4px;

      border: 2px dotted var(--selected-color);
    }
    &__Text {
      position: absolute;
      height: 39px;
      left: 10px;
      top: 40px;

      @include body_14;

      color: #fb2f2f;
      .description {
        color: var(--selected-color);
      }
    }
  }
`;

export default staticStyles;
