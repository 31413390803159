import { useIntl } from 'react-intl';
import messages from '../../messages';
import staticStyles from './style';

export default function RiskBar() {
  const intl = useIntl();
  return (
    <div className="RiskBar">
      <div className="RiskBar__Rect">
        <div className="RiskBar__Rect-indicator" />
      </div>
      <div className="RiskBar__BaseLine" />
      <div className="RiskBar__Text">
        <p>1.00</p>
        <p className="description">{intl.formatMessage(messages.liquidationEvent)}</p>
      </div>
      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
