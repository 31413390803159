import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .InfoPanelWrapper {
    border: 1px solid var(--tabs-color);
    border-radius: 16px;
    background: white;
    background: var(--dropdown-background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    width: 100%;
    height: fit-content;

    &__title {
      @include body_18;
      color: var(--selected-color);
      width: 100%;
    }

    &__content {
      @include body_16;
      color: var(--text2-color);
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 100%;
    }
  }
`;

export default staticStyles;
