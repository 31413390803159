import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .ValuePercent {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__value {
    }
  }
`;

export default staticStyles;
