import { useIntl } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import staticStyles from './style';

import closeIcon from 'src/images/closeIcon.svg';
import BasicModal from '../basic/BasicModal';
import ZapAssetTable from './components/ZapAssetTable';

interface ZapModalProps {
  className?: string;
  isVisible: boolean;
  onBackdropPress: () => void;
  zapAssets: any[];
  onSelectZapAsset: (symbol: string) => void;
}

export default function ZapModal({
  className,
  isVisible,
  onBackdropPress,
  zapAssets,
  onSelectZapAsset,
}: ZapModalProps) {
  const intl = useIntl();

  return (
    <BasicModal
      onBackdropPress={onBackdropPress}
      isVisible={isVisible}
      title={intl.formatMessage(messages.caption)}
      withCloseButton={true}
      className={classNames('ZapModal', className)}
      closeIcon={closeIcon}
    >
      <div className="ZapModal__inner">
        <ZapAssetTable listData={zapAssets} onSelectZapAsset={onSelectZapAsset} />
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicModal>
  );
}
