import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .AssetSwitcherWrapper {
    min-width: 125px;
    display: flex;
    justify-content: flex-end;

    .DropdownWrapper {
      display: flex;
      align-items: center;
      justify-contents: center;
    }
    .DropdownWrapper__content {
      background-color: var(--dropdown-background-color);
    }
    .DropdownWrapper__button {
      box-shadow: none;
      background: none;
    }

    &__button-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      z-index: 2;
      border: 1px solid transparent;
      border-radius: 1px;

      .TokenIcon__image {
        margin-right: 8px;
      }

      p {
        @include body_20;
        color: var(--selected-color);
        margin: 0;
      }
    }

    &__content {
      width: 140px;
      padding: 8px 0px;
    }
    &__title {
      font-size: 12px;
      text-align: center;
      font-weight: 300;
      width: 100%;
    }

    &__asset {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 40px;
      width: 100%;
      padding: 0 8px;

      &:last-child {
        border: none;
      }

      .TokenIcon__image {
        margin-right: 8px;
      }

      p {
        @include body_20;
        color: var(--selected-color);
        margin: 0;
      }
    }

    &__asset:active {
      background-color: white;
    }
    &__asset:hover {
      background-color: #f7f8ff;
    }

    @keyframes animate {
      0% {
        background-position: 0 50%;
      }
      50% {
        background-position: 100% 50%;
      }
      100% {
        background-position: 0 50%;
      }
    }
  }
`;

export default staticStyles;
