import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/screen-size';
  @import 'src/_mixins/vars';

  .Caption {
    text-align: left;
    @include respond-to(xl) {
      margin-bottom: 30px;
    }
    @include respond-to(sm) {
      margin-bottom: 25px;
    }
    h2 {
      width: 100%;
      font-size: $extraLarge;
      font-weight: 500;
      color: var(--selected-color);
    }
  }
`;

export default staticStyles;
