import { useIntl } from 'react-intl';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import BasicModal from 'src/components/basic/BasicModal';
import { figure1LightFigure, figure1DarkFigure } from 'src/images';
import staticStyles from '../style';
import messages from '../messages';

interface LendingHelpModalProps {
  isVisible: boolean;
  onBackdropPress: () => void;
}

export default function LendingHelpModal({ isVisible, onBackdropPress }: LendingHelpModalProps) {
  const intl = useIntl();
  const { isCurrentThemeDark } = useThemeContext();

  const figure = isCurrentThemeDark ? figure1DarkFigure : figure1LightFigure;
  return (
    <BasicModal
      isVisible={isVisible}
      onBackdropPress={onBackdropPress}
      title={intl.formatMessage(messages.lending)}
      withCloseButton={true}
      className="HelpModal"
    >
      <div className="HelpModal__content">
        <h2>{intl.formatMessage(messages.lendingCaption)}</h2>
        <p>
          {intl.formatMessage(messages.lendingDesc1)}
          <br />
          {intl.formatMessage(messages.lendingDesc2)}
        </p>
        <div className="breakline" />
        <img src={figure} alt="Sturdy" />
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicModal>
  );
}
