import React, { ReactNode } from 'react';

import staticStyles from './style';
import { AlertTemplateProps } from 'react-alert';
import pendingIcon from '../../images/pending.svg';
import successIcon from '../../images/success.svg';
import errorIcon from '../../images/error.svg';
import closeIcon from '../../images/close.svg';

export default function AlertTemplate({ style, options, message, close }: AlertTemplateProps) {
  const successTemplate = (message: ReactNode) => (
    <>
      <img src={successIcon} width={24} height={24} alt="" />
      <div className={'AlertTemplate__content'}>
        <div>{message}</div>
        <div className={'AlertTemplate__content__detail'}>View in Dashboard</div>
      </div>
      <img
        className={'AlertTemplate__close'}
        src={closeIcon}
        width={24}
        height={24}
        alt=""
        onClick={close}
      />
    </>
  );

  const pendingTemplate = (message: ReactNode) => (
    <>
      <img src={pendingIcon} width={24} height={24} alt="" />
      <div className={'AlertTemplate__content'}>
        <div>{message}</div>
        <div className={'AlertTemplate__content__detail'}>View Transaction</div>
      </div>
      <img
        className={'AlertTemplate__close'}
        src={closeIcon}
        width={24}
        height={24}
        alt=""
        onClick={close}
      />
    </>
  );

  const errorTemplate = (message: ReactNode) => (
    <>
      <img src={errorIcon} width={24} height={24} alt="" />
      <div className={'AlertTemplate__content'}>
        <div>{message}</div>
        <div className={'AlertTemplate__content__error'}>Error!</div>
      </div>
      <img
        className={'AlertTemplate__close'}
        src={closeIcon}
        width={24}
        height={24}
        alt=""
        onClick={close}
      />
    </>
  );

  return (
    <div className={'AlertTemplate'} style={style}>
      {options.type === 'info' && pendingTemplate(message)}
      {options.type === 'success' && successTemplate(message)}
      {options.type === 'error' && errorTemplate(message)}

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
