import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .MarketCard {
    position: relative;
    flex: 1;
    box-sizing: border-box;
    box-shadow: var(--box-shadow) !important;
    padding: 24px;

    &__inner {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
    &__title {
      @include body_16;
      color: var(--text2-color);
    }
    &__value {
      display: flex;
      justify-content: flex-start;
      white-space: nowrap;
      @include body_24_sb;
    }
    &__logo {
      position: absolute;
      left: 75.31%;
      right: 10.86%;
      top: -6.78%;
      bottom: 17.22%;
      img {
      }
    }
    @include respond-to(sm) {
      width: 100% !important;
    }
  }

  .MarketCard.PanelWrapper {
    border: 1px solid var(--tabs-color);
    background: var(--marketcad-background-color);
    box-shadow: var(--table-box-shadow);
  }

  .MarketCard.MarketCard__primary {
    .MarketCard__title,
    .MarketCard__value {
      color: white;
    }
  }

  .MarketCard__primary.PanelWrapper {
    background: radial-gradient(391.23% 4173.53% at 414.81% -286.69%, #141725 0%, #34427b 100%)
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border: 2px solid var(--tabs-color);

    box-shadow: var(--box-shadow);
    backdrop-filter: blur(4px);
  }
`;

export default staticStyles;
