import { ChainId, Network } from '@sturdyfi/sturdy-js';

type ExtendedSupportedNetworks = Network | 'unsupported';

console.log(
  'Set forkNetworkId, forkRPCUrl, forkWsRPCUrl, fork_enabled to an appropriate value to run against your fork'
);
const FORK_NETWORK_ID = Number(localStorage.getItem('forkNetworkId') || ChainId.fork);

export function mapChainIdToName(id: number): ExtendedSupportedNetworks {
  switch (id) {
    case ChainId.mainnet:
      return Network.mainnet;
    case FORK_NETWORK_ID:
      return Network.fork;
    case ChainId.ftm:
      return Network.ftm;
    case ChainId.ftm_test:
      return Network.ftm_test;
    default:
      return 'unsupported';
  }
}

export function mapNameToChainID(chain?: ExtendedSupportedNetworks): number {
  switch (chain) {
    case Network.mainnet:
      return ChainId.mainnet;
    case Network.eth:
      return ChainId.mainnet;
    case Network.fork:
      return FORK_NETWORK_ID;
    case Network.ftm:
      return ChainId.ftm;
    case Network.ftm_test:
      return ChainId.ftm_test;
    default:
      return 0;
  }
}
