import {
  assetsList as assetsListFromKit,
  Asset,
  assetsOrder as assetsOrderFromKit,
  STABLE_ASSETS as stableAssetsFromKit,
  CUSTOM_STABLE_ASSETS as customStableAssetsFromKit,
} from '@sturdyfi/sturdy-ui-kit';

export const assetsList: Asset[] = assetsListFromKit;

export const assetsOrder: string[] = assetsOrderFromKit;
export const stableAssets: string[] = stableAssetsFromKit;
export const customStableAssets: string[] = customStableAssetsFromKit;
