import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  API_ETH_MOCK_ADDRESS,
  BigNumber,
  BorrowableReserveMarketData,
  GasResponse,
  Network,
  valueToBigNumber,
} from '@sturdyfi/sturdy-js';

import DefaultButton from 'src/components/basic/DefaultButton';
import { OverviewDataType } from 'src/components/TransactionOverviewPanel/types';
import TransactionOverviewPanel from 'src/components/TransactionOverviewPanel';
import AmountBoxWrapper from 'src/components/Wrappers/AmountBoxWrapper';

import ConnectButton from 'src/components/ConnectButton';
import staticStyles from './style';
import { useTxBuilderContext } from 'src/libs/tx-provider';
import PoolTxConfirmationView, { EmptyTransaction } from 'src/components/PoolTxConfirmationView';
import useDebounce from 'src/libs/hooks/use-debounce';
import { ValidationWrapperComponentProps } from 'src/components/RouteParamsValidationWrapper';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { useProvideCollateralContext } from 'src/components/Wrappers/ScreensWrapper';

interface WithdrawMainProps
  extends Pick<
    ValidationWrapperComponentProps,
    'currencySymbol' | 'poolReserve' | 'user' | 'userReserve'
  > {}

export default function WithdrawMain({
  currencySymbol,
  user,
  poolReserve,
  userReserve,
}: WithdrawMainProps) {
  const { lendingPool } = useTxBuilderContext();
  const { network, networkConfig } = useProtocolDataContext();
  const [amount, setAmount] = useState('0');
  const [fetchGasData, setFetchGasData] = useState<GasResponse>();
  const debouncedAmount = useDebounce<string>(amount || '0', 500);
  const [maxAmount, setMaxAmount] = useState('0');
  const isAmountAvailable = valueToBigNumber(debouncedAmount || '0').gt(0);
  const symbols = useMemo(() => {
    if (network === Network.eth) return [currencySymbol, networkConfig.baseAsset];
    return [currencySymbol];
  }, [currencySymbol]);
  const { tokenSymbol, setTokenSymbol } = useProvideCollateralContext();
  if (!symbols.includes(tokenSymbol)) setTokenSymbol(currencySymbol);

  useEffect(() => {
    if (userReserve) {
      let maxUserAmountToWithdraw = BigNumber.min(
        valueToBigNumber(userReserve.underlyingBalance),
        valueToBigNumber((poolReserve as BorrowableReserveMarketData).availableLiquidity)
      ).toString(10);
      setMaxAmount(maxUserAmountToWithdraw);
    }
  }, []);

  const handleTokenSwitcher = (token: string) => {
    setTokenSymbol(token);
  };

  const txOverviewData: OverviewDataType = useMemo(() => {
    const remainedAmount = valueToBigNumber(userReserve?.underlyingBalance || '0')
      .minus(debouncedAmount)
      .toString();

    return {
      transactionType: 'withdraw',
      assetType: 'borrowable',
      assetSymbol: currencySymbol,
      assetAmount: remainedAmount,
      APY: poolReserve.depositAPY,
      avgAPY: userReserve?.avgAPY,
      fetchGasData,
    };
  }, [debouncedAmount, fetchGasData]);

  const handleGetTransactions = useCallback(async () => {
    if (user && isAmountAvailable) {
      return await lendingPool.withdraw({
        user: user.id,
        reserve:
          tokenSymbol === networkConfig.baseAsset
            ? API_ETH_MOCK_ADDRESS
            : poolReserve.underlyingAsset,
        amount: debouncedAmount,
        aTokenAddress: poolReserve.aTokenAddress,
      });
    }
    return EmptyTransaction;
  }, [debouncedAmount, tokenSymbol]);

  const handleGasPriceChanged = (gas: GasResponse) => {
    setFetchGasData(() => gas);
  };

  return (
    <div className="WithdrawMain">
      <AmountBoxWrapper
        symbols={symbols}
        value={amount}
        title={'Amount'}
        hasMax={true}
        maxValue={maxAmount}
        onChange={setAmount}
        selectedSymbol={tokenSymbol}
        onChangeSymbol={handleTokenSwitcher}
      />
      <TransactionOverviewPanel overviewData={txOverviewData} />
      {!user && <ConnectButton type="button" />}
      {user && !isAmountAvailable && (
        <DefaultButton title="Enter amount" type="primary" color="blue" disabled={true} />
      )}
      {user && isAmountAvailable && (
        <PoolTxConfirmationView
          mainTxName={'withdraw'}
          currencySymbol={currencySymbol}
          getTransactionsData={handleGetTransactions}
          onActiveTxChanged={handleGasPriceChanged}
        />
      )}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}
