import { defineMessages } from 'react-intl';

export default defineMessages({
  menu: 'Menu',
  markets: 'Markets',
  dashboard: 'Dashboard',
  settings: 'Settings',
  darkMode: 'Dark mode',
  links: 'Links',
  discord: 'Discord',
  twitter: 'Twitter',
  github: 'Github',
});
