import classNames from 'classnames';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { CollateralReserveMarketData } from '@sturdyfi/sturdy-js';
import { getAssetInfo } from '@sturdyfi/sturdy-ui-kit';

import ActionSwitcher from 'src/components/ActionSwitcher';
import BackButton from 'src/components/basic/BackButton';
import { ValidationWrapperComponentProps } from 'src/components/RouteParamsValidationWrapper';
import { ActionButtonDataType } from 'src/components/ActionSwitcher/types';
import PanelWrapper from 'src/components/Wrappers/PanelWrapper';

import DepositMain from '../DepositMain';
import LeverageMain from '../LeverageMain';
import staticStyles from './style';

interface PropvideCollateralWrapperProps extends ValidationWrapperComponentProps {
  className?: string;
}

export default function ProvideCollateralWrapper(props: PropvideCollateralWrapperProps) {
  const { action, currencySymbol, poolReserve, className } = props;

  const actionButtons: ActionButtonDataType[] = useMemo(() => {
    const reserve = poolReserve as CollateralReserveMarketData;
    return [
      { action: 'deposit', title: 'Deposit' },
      { action: 'leverage', title: 'Leverage', disabled: reserve.leverageEnabled !== true },
    ];
  }, [currencySymbol]);

  const defaultAction = 'deposit';
  const [selectedAction, setSelectedAction] = useState(defaultAction);
  const navigate = useNavigate();

  useEffect(() => {
    let newAction = action;
    if (!newAction) {
      newAction = defaultAction;
    }
    setSelectedAction(newAction);
  }, [action]);

  const handleActionChange = (newAction: string) => {
    setSelectedAction(newAction);
  };

  const asset = getAssetInfo(currencySymbol);

  const isDeposit = selectedAction === defaultAction;
  const isLeverage = selectedAction === 'leverage';

  return (
    <PanelWrapper className={classNames('ProvideCollateralWrapper', className)}>
      <div className="ProvideCollateralWrapper__title">
        <BackButton onClick={() => navigate(-1)} />
        <p className="ProvideCollateralWrapper__title-caption">{asset.name}</p>
      </div>
      <div className="ProvideCollateralWrapper__separate-line"></div>
      <div className="ProvideCollateralWrapper__content">
        <ActionSwitcher
          buttons={actionButtons}
          selectedAction={selectedAction}
          onActionChange={handleActionChange}
        />
        {isDeposit && <DepositMain {...props} />}
        {isLeverage && <LeverageMain {...props} />}
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </PanelWrapper>
  );
}
