import React, { ReactNodeArray, ReactNode } from 'react';
import classNames from 'classnames';

import staticStyles from './style';
import connectWallet from 'src/images/connectWallet.svg';

type Icons = 'wallet' | 'deposit' | undefined;

export interface NoDataPanelProps {
  title: string;
  description?: string | ReactNodeArray;
  iconName?: Icons;
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
}

const getIconFromName = (iconName: Icons) => {
  switch (iconName) {
    case 'wallet':
      return connectWallet;
  }

  return '';
};

export default function NoDataPanel({
  title,
  description,
  iconName,
  className,
  children,
  onClick,
}: NoDataPanelProps) {
  const icon = getIconFromName(iconName);

  return (
    <div className={classNames('NoDataPanel', className)}>
      <div onClick={onClick}>{iconName && <img src={icon} className="App-logo" alt="logo" />}</div>
      <h2>{title}</h2>
      {description && <div className="NoDataPanel__description">{description}</div>}
      {children}

      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
