import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';

import {
  auditC4DarkIcon,
  auditC4LightIcon,
  auditCertikDarkIcon,
  auditCertikLightIcon,
  auditQuantstampDarkIcon,
  auditQuantstampLightIcon,
} from 'src/images';
import staticStyles from './style';

export default function Audit() {
  const { isCurrentThemeDark } = useThemeContext();
  return (
    <div className="AuditTooltip">
      <div>
        <img src={isCurrentThemeDark ? auditQuantstampDarkIcon : auditQuantstampLightIcon} alt="Sturdy Audit" />
      </div>
      <div>
        <img src={isCurrentThemeDark ? auditC4DarkIcon : auditC4LightIcon} alt="Sturdy Audit" />
      </div>
      <div>
        <img
          src={isCurrentThemeDark ? auditCertikDarkIcon : auditCertikLightIcon}
          alt="Sturdy Audit"
        />
      </div>
      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
