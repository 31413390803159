import { useIntl } from 'react-intl';
import { useThemeContext } from '@sturdyfi/sturdy-ui-kit';
import BasicModal from 'src/components/basic/BasicModal';
import { figure1DarkFigure, figure1LightFigure } from 'src/images';
import messages from '../messages';
import staticStyles from '../style';

interface BorrowHelpModalProps {
  isVisible: boolean;
  onBackdropPress: () => void;
}

export default function BorrowHelpModal({ isVisible, onBackdropPress }: BorrowHelpModalProps) {
  const intl = useIntl();
  const { isCurrentThemeDark } = useThemeContext();

  const figure = isCurrentThemeDark ? figure1DarkFigure : figure1LightFigure;
  return (
    <BasicModal
      isVisible={isVisible}
      onBackdropPress={onBackdropPress}
      title={intl.formatMessage(messages.borrowing)}
      withCloseButton={true}
      className="HelpModal"
    >
      <div className="HelpModal__content">
        <h2>{intl.formatMessage(messages.borrowingCaption)}</h2>
        <p>{intl.formatMessage(messages.borrowingDesc)}</p>
        <div className="breakline" />
        <img src={figure} alt="Sturdy" />
      </div>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicModal>
  );
}
