import { useIntl } from 'react-intl';
import { getAssetInfo } from '@sturdyfi/sturdy-ui-kit';

import Value from '../../basic/Value';
import NoData from '../../basic/NoData';
import ValuePercent from '../../basic/ValuePercent';
import SpinLoader from '../../basic/SpinLoader';
import tooltipMessages from '../../Tooltip/messages';
import InfoPanelWrapper from '../../Wrappers/InfoPanelWrapper';
import TextWithInfoWrapper from '../../Wrappers/TextWithInfoWrapper';

import { OverviewDataType } from '../types';

import staticStyles from './style';

interface CollateralDepositOverviewPanelProps
  extends Pick<
    OverviewDataType,
    | 'assetAmount'
    | 'assetSymbol'
    | 'healthFactor'
    | 'APY'
    | 'avgAPY'
    | 'gasEstimation'
    | 'loadingGasEstimation'
    | 'slippage'
  > {}

export default function CollateralDepositOverviewPanel({
  assetAmount,
  assetSymbol,
  healthFactor,
  APY,
  avgAPY,
  gasEstimation,
  loadingGasEstimation,
  slippage,
}: CollateralDepositOverviewPanelProps) {
  const intl = useIntl();
  const asset = getAssetInfo(assetSymbol);
  const tokenName = asset.shortSymbol ? asset.shortSymbol : asset.symbol;

  return (
    <InfoPanelWrapper
      title={<span>Transaction overview</span>}
      className={'CollateralDepositOverviewPanel'}
    >
      <div className={'CollateralDepositOverviewPanel__item'}>
        <div className={'CollateralDepositOverviewPanel__item__content'}>
          <TextWithInfoWrapper
            text={intl.formatMessage(tooltipMessages.yourPosition)}
            tooltipName="yourPosition"
          />
          <div className={'CollateralDepositOverviewPanel__item__content__value'}>
            {assetAmount ? <Value value={assetAmount} maximumValueDecimals={2} /> : <NoData />}
            <span>&nbsp;{tokenName.toUpperCase()}</span>
          </div>
        </div>
      </div>

      <div className={'CollateralDepositOverviewPanel__item'}>
        <div className={'CollateralDepositOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Health Factor'} tooltipName="healthFactor" />
          <div className={'LeverageOverviewPanel__item__content__value__healthFactor'}>
            {healthFactor ? <Value value={healthFactor} maximumValueDecimals={2} /> : <NoData />}
          </div>
        </div>
        <div className={'CollateralDepositOverviewPanel__item__sub'}>{'Liquidation at < 1.0'}</div>
      </div>

      <div className={'CollateralDepositOverviewPanel__item'}>
        <div className={'CollateralDepositOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Deposit APY'} tooltipName="depositAPYForCollateral" />
          <div className={'CollateralDepositOverviewPanel__item__content__value'}>
            {APY ? <ValuePercent value={APY} maximumDecimals={2} /> : <NoData />}
          </div>
        </div>
      </div>

      <div className={'CollateralDepositOverviewPanel__item'}>
        <div className={'CollateralDepositOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Average APY'} tooltipName="averageAPY" />
          <div className={'CollateralDepositOverviewPanel__item__content__value'}>
            {avgAPY ? <ValuePercent value={avgAPY} maximumDecimals={2} /> : <NoData />}
          </div>
        </div>
      </div>

      <div className={'CollateralDepositOverviewPanel__split'} />

      <div className={'CollateralDepositOverviewPanel__item'}>
        <div className={'CollateralDepositOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Gas Fee'} tooltipName="gasFee" />
          <div className={'CollateralDepositOverviewPanel__item__content__value'}>
            {loadingGasEstimation ? (
              <SpinLoader color="black" />
            ) : gasEstimation ? (
              <Value
                value={gasEstimation}
                symbol="USD"
                withoutSymbol={true}
                tokenIcon={true}
                minimumValueDecimals={2}
                maximumValueDecimals={2}
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <div className={'CollateralDepositOverviewPanel__item'}>
        <div className={'CollateralDepositOverviewPanel__item__content'}>
          <TextWithInfoWrapper text={'Price Slippage'} tooltipName="priceSlippage" />
          <div className={'CollateralDepositOverviewPanel__item__content__value'}>
            {slippage ? (
              <u>
                <ValuePercent value={slippage} maximumDecimals={1} />
              </u>
            ) : (
              <NoData />
            )}
          </div>
        </div>
      </div>

      <style jsx={true}>{staticStyles}</style>
    </InfoPanelWrapper>
  );
}
