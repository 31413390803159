import classNames from 'classnames';
import {useThemeContext} from '@sturdyfi/sturdy-ui-kit';
import { useProtocolDataContext } from 'src/libs/protocol-data-provider';
import { useUserWalletDataContext } from 'src/libs/web3-data-provider';
import { availableMarkets, formattedNetwork } from 'src/config';
import { CustomMarket, marketsData } from 'src/ui-config';
import { getNetworkConfig } from 'src/helpers/markets/markets-data';
import staticStyles from './style';


export default function EtherMarketSwitcher() {
  const { sm } = useThemeContext();
  const { setCurrentMarket, currentMarketData } = useProtocolDataContext();
  const { handleNetworkChange } = useUserWalletDataContext();

  const handleSetCurrentMarket = (market: CustomMarket) => {
    setCurrentMarket(market);
    handleNetworkChange(marketsData[market].network);
  };

  const ethereumMarkets = availableMarkets.filter((market) => {
    const marketData = marketsData[market];
    const config = getNetworkConfig(marketData.network);
    return config.chainName === 'Ethereum';
  });

  const ethereumNetworks = ethereumMarkets.map((market) => {
    const marketData = marketsData[market];
    return marketData.network;
  });

  const network = currentMarketData.network;

  if (!ethereumNetworks.includes(network)) {
    return null;
  }

  return (
    <div className="EtherMarketSwitcher">
      {ethereumMarkets.map((market) => {
        const marketData = marketsData[market];
        return (
          <button
            key={market}
            onClick={() => handleSetCurrentMarket(market)}
            disabled={network === marketData.network}
            type="button"
          >
            <div
              className={classNames('EtherMarketSwitcher__market', {
                EtherMarketSwitcher__marketActivated: network === marketData.network,
              })}
            >
              <img src={marketData.logo} alt={market} />
              <span>{formattedNetwork(marketData.network, sm)}</span>
            </div>
          </button>
        );
      })}
      <style jsx={true}>{staticStyles}</style>
    </div>
  );
}
