import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .DashboardCard {
    position: relative;
    flex: 1;
    box-sizing: border-box;
    padding: 24px;

    &__title {
      @include body_16;
      color: var(--text2-color);
      margin-bottom: 16px;
    }
    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
    }
    h2 {
      white-space: nowrap;
      @include body_24_sb;
    }

    @include respond-to(sm) {
      width: 320px !important;
    }
  }

  .DashboardCard__summary {
    .DashboardCard__title {
      @include body_18;
      color: var(--selected-color);
      margin-bottom: 32px;
    }
  }

  .Dashboard__positions {
    .DashboardCard__summary,
    .PanelWraper {
      width: 100%;
      height: 100%;
    }
  }
`;

export default staticStyles;
